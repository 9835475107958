.tracker-wrapper.tracker-wrapper--search {

  //   max-height: calc(100vh - 9rem);
  .page-desc-content {
    padding: 1rem 1.5rem 0.75rem;
  }

  @media (max-width: 1450px) {
    max-height: calc(100vh - 7.75rem);


    @media (max-height: 760px) {
      height: calc(100vh - 6rem);
      max-height: calc(100vh - 6rem);
    }
  }

  @media (max-width: 991.98px) {
    margin-bottom: 1rem;
    border: 2px solid white !important;
    background-color: rgba($color: #fff, $alpha: 0.6) !important;
  }

  @media (max-width: 767.98px) {
    .tracker-bg {
      border-radius: 5px !important;
    }
  }

  @media (max-width: 567.98px) {
    max-height: 65vh;
    padding: 0.75rem;
  }
}

.tracking-status {
  &:has(.tracking-status__search) {
    position: relative;
    max-height: calc(100vh - 9.35rem);
    overflow-y: hidden;

    @media (max-width: 1450px) {
      max-height: calc(100vh - 7.75rem);

      .tracker-user-card-wrapper {
        padding: 0.8rem 0.65rem;

        .user-tracking-pic {
          gap: 0.8rem;

          .page-header__user-info.round_pf_pic .user-img {
            width: 4rem;
            height: 4rem;

            @media (max-width: 767.98px) {
              width: 3.6rem;
              height: 3.6rem;

            }
          }
        }

        .trusted-user {
          margin-bottom: 0.5rem;
        }
      }

      @media (max-height: 760px) {
        max-height: unset;
        height: 100%;

        .tracker-user-card-wrapper {
          margin-top: 5px;
          padding: 0.4rem 0.65rem;

          .user-tracking-pic {
            gap: 0.7rem;

            .page-header__user-info.round_pf_pic .user-img {
              width: 3.5rem;
              height: 3.5rem;
            }
          }

          .trusted-user {
            margin-bottom: 0.2rem;
          }

          .tracking-date-tag {
            margin-top: -20px;

            span {
              font-size: 0.75rem;
            }
          }
        }
      }

      .counterfeitBtn {
        position: relative;
        z-index: 1;
        background-color: #FFDADA;
        letter-spacing: -0.0125rem;
        border-radius: 0.625rem;
        height: 3.25rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 0;

        @media (max-width: 1450px),
        (max-height: 760px) {
          height: 2.85rem;
          border-radius: 0.575rem;
        }

        .icon {
          height: 100%;
          width: 3.125rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.5rem;
          background-color: var(--text-dark);

          @media (max-width: 1450px),
          (max-height: 760px) {
            width: 2.8rem;

          }
        }

        strong {
          margin-left: 0.35rem;
        }
      }
    }

    @media (max-width: 991.98px) {
      margin-bottom: 1.5rem;
    }

    @media (max-width: 567.98px) {
      max-height: unset;
      padding: 0.75rem;
      height: 100%;

      .tracker-info-wrapper {
        padding-left: 30px;

        &::before {
          left: 9.5px;
        }

        &::after {
          left: 12px;
        }
      }

      .trusted-user {
        margin-block: 0.25rem 0.5rem;

        img {
          width: 1rem;
        }

        span {
          font-size: 10px;
        }
      }

      .page-header__user-info.round_pf_pic {
        margin-top: -6px;

        .user-img {
          width: 3.0625rem;
          height: 3.0625rem;
          padding: 0.175rem;
        }
      }

      .date_btn {
        font-size: 12px;
        min-width: 6.5rem;
        border-radius: 0.2125rem;
      }

      .user-tracking-pic {
        gap: 10px;
      }

      .tracker-user-card-wrapper::before {
        left: -42px;
        width: 40px;
      }

      .tracker-info {
        padding-left: 18px;
      }

      .tagBefore {
        height: 19px;
        width: 22px;
        left: -58px;
        border-radius: 3px;
        top: calc(50% - 0.025rem);

        span {
          font-size: 11px;
        }
      }
    }

    @media (max-width: 449.98px) {
      margin-bottom: 1rem;
    }
  }

  .login-search-block {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100%;
    z-index: 9;
    backdrop-filter: blur(2px);
    padding: 1rem 1.5rem 1.5rem;
    background-color: rgba($color: #fff, $alpha: 0.6);
    box-shadow: 0 -2px 30px rgba($color: #fff, $alpha: 1);
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    @media (max-width: 1450px) {
      padding: 0.8rem 1rem;

      @media (max-height: 760px) {
        gap: 0.35rem;
        padding: 0.3rem 0.5rem 0.5rem;
      }
    }

    @media (max-width: 767.98px) {
      padding: 1rem 1rem 1.125rem;
    }

    @media (max-width: 567.98px) {
      padding: 0.6rem 0 0.8rem;
      margin-block: -0.35rem -1.5rem;
      position: static;
      transform: none;
    }

    button {
      margin-inline: auto;
      width: 88.5%;

      @media (max-width: 767.98px) {
        width: 98.5%;
      }
    }

    .block-wavy-dark {
      display: flex;
      flex-flow: column nowrap;
      background-image: url(./../../assets/images/waves-login-dd-gradient.svg);
      gap: 0.45rem;

      .title {
        max-width: 80%;

        h2 {
          text-align: left;
        }
      }

      @media (max-width: 1450px) {
        padding-block: 0.8rem 0.88rem;

        h2 {
          span {
            font-size: 20px;
          }
        }

        p {
          font-size: 0.688rem;
        }

        @media (max-height: 760px) {
          .title {
            img {
              max-height: 1.35rem;
            }
          }

          h2 {
            span {
              font-size: 18px;
            }
          }
        }
      }

      @media (max-width: 767.98px) {
        padding-right: 5rem;

        .title {
          max-width: 100%;
        }
      }

      @media (max-width: 567.98px) {
        padding: 1rem 4rem 1rem 1rem;
        background-size: 42.5%;
        border-radius: 5px;

        h2 {
          span {
            font-size: 1.08rem;
          }
        }

        p {
          font-size: 10px;
        }
      }

      @media (max-width: 399.98px) {
        background-size: 40%;
      }

      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        @media (max-width: 399.98px) {
          align-items: flex-start;

          img {
            margin-top: 0.125rem;
          }
        }
      }
    }
  }

  &__search {
    .block-wavy-dark {
      height: 2.75rem;
      padding: 0.25rem 1rem;
      background-image: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.375rem;
      margin-bottom: 0.8rem;

      @media (max-width: 1450px) {
        height: 2.125rem;
        margin-bottom: 0.65rem;
        border-radius: 0.275rem;

        @media (max-height: 760px) {
          margin-bottom: 0.5rem;
        }

        span {
          font-size: 12px;
        }
      }

      @media (max-width: 767.98px) {
        height: 2.25rem;
        margin-bottom: 0.85rem;
      }

      span {
        letter-spacing: -0.03rem;
      }
    }
  }

  .search-images {
    display: flex;
    gap: 0.81rem;
    height: 12rem;
    margin-bottom: 1rem;

    @media (max-width: 1450px) {
      height: 10rem;
      margin-bottom: 0.75rem;

      @media (max-height: 760px) {
        margin-bottom: 0.5rem;
        height: 6.8rem;
      }
    }

    @media (max-width: 767.98px) {
      height: 9rem;
      margin-bottom: 1rem;
    }


    &__holographic {
      flex: 1;
      height: 100%;

      &>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__locked {
      flex-basis: 8.55rem;
      height: 100%;

      img {
        border-radius: 0.625rem;
      }

      @media (max-width: 1450px) {
        flex-basis: 7.1rem;

        img {
          border-radius: 0.425rem;
        }

        @media (max-height: 760px) {
          flex-basis: 4.85rem;
        }
      }

      @media (max-width: 767.98px) {
        flex-basis: 6.5rem;
      }
    }
  }

  .pairs {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.725rem;
    padding: 0.15rem 0 1.2rem 0.25rem;

    @media (max-width: 1450px) {
      gap: 0.68rem;
      padding-bottom: 1rem;

      @media (max-height: 760px) {
        gap: 0.35rem;
        padding-bottom: 0.5rem;
      }
    }

    @media (max-width: 767.98px) {
      gap: 0.85rem;
      padding: 0 0 1.2rem 0.25rem;
    }

    &__text {
      display: flex;
      align-items: center;

      @media (max-width: 1450px) {
        font-size: 0.813rem;
      }

      @media (max-width: 767.98px) {
        font-size: 0.813rem;
      }

      @media (max-width: 567.98px) {
        align-items: flex-start;
      }

      .label {
        flex-basis: 35%;
      }

      .value {
        flex: 1;
      }
    }
  }
}

.level-image-block {
  background: url(./../images/level-tracking-image.svg) no-repeat right bottom;
  background-color: var(--text-dark);
  padding: 1.8rem 2rem;
  height: 100%;
  background-size: 75% 136%;
  align-items: flex-end;

  @media (max-height: 760px) {
    padding: 1.2rem 1.6rem;
    background-size: 50% 125%;
  }

  @media (max-width: 767.98px) {
    background-size: 95% 120%;
    padding: 1.2rem;

    .level {
      span {
        font-size: 1.35rem;
      }
    }
  }

  .level {
    align-items: center;
    gap: 0.5rem;

    img {
      width: 2rem;

      @media (max-width: 1450px) {
        width: 1.5rem;
      }
    }
  }
}
.reports {
  margin-top: 1rem;

  &__bar {
    canvas {
      margin-left: -0.8rem;
    }

    @media (max-width: 767.98px) {
      width: 100%;
      flex: 0 0 100%;

      .btn-top-group {
        gap: 0.4rem;
      }
    }
  }

  &__distribution {
    @media (max-width: 567.98px) {
      @include flexBox(flex, column nowrap, center, center);
    }
  }

  .top-row {
    gap: 1rem;
    @include flexBox(flex, row wrap, center, space-between);

    @media (max-width: 767.98px) {
      width: 100%;
    }

    h3 {
      letter-spacing: -0.02625rem;
    }
  }

  .chart-row {
    justify-content: space-between;
  }
}

.distribution-data {
  @include flexBox(flex, column nowrap, null, null);
  gap: 0.8rem;
  max-width: 10rem;
  flex: 0 0 10rem;

  @media (max-width: 767.98px) {
    margin-bottom: 0;
  }

  @media (max-width: 567.98px) {
    max-width: unset;
    flex: unset;
    align-items: center;
  }

  &>li {
    @media (max-width: 567.98px) {
      min-width: 75vw;
    }

    @media (max-width: 449.98px) {
      min-width: 85vw;
      width: 95%;
    }

    .gradient-widget {
      background-image: url(./../images/wavy-spiral-dark-zono-distn.svg);
      background-position: top;
      background-size: 110%;
      padding: 0;

      .inner {
        z-index: 1;
        padding: 1.65rem 1rem 1rem 1.5rem;
        @include flexBox(flex, column nowrap, null, null);
        gap: min(0.825vh, 1rem);

        @media (max-width: 767.98px) {
          padding: 1rem 0.5rem 1rem 1.65rem;
        }
      }

      .title {
        @include flexBox(flex, null, null, null);
        gap: 0.3rem;

        .dot {
          margin-top: 2px;
          width: 0.69163rem;
          height: 0.69163rem;
          border-radius: 50%;


          &--green {
            background: linear-gradient(180deg, #cbeadb 0%, #378f69 100%);
          }

          &--magenta {
            background: linear-gradient(180deg, #cc2e78 0%, #55009d 100%);
          }
        }

        h3 {
          width: 100%;

          img {
            margin-top: 0.2rem;
            @include flexBox(flex, null, null, flex-end);
          }
        }
      }
    }
  }

  .direction-stat {
    @include flexBox(flex, column nowrap, null, null);
    gap: 0.85rem 5%;

    @media (max-width: 567.98px) {
      @include flexBox(flex, row wrap, null, null);
    }

    .pair {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.625rem;

      @media (max-width: 567.98px) {
        max-width: calc(50% - 0.625rem);
        flex: 0 0 calc(50% - 0.625rem);
      }

      .abbr-box {
        padding-top: 4px;
        width: 17px;
        height: 17px;
        flex-shrink: 0;
        @include flexBox(inline-flex, null, center, center);
        border-radius: 0.125rem;
        background-color: var(--text-dark);
      }

      .text {
        letter-spacing: 0.02rem;
      }
    }
  }
}

.chart_bar_reporting {
  min-height: 24rem;
  padding-bottom: 0.5rem;
  margin-top: auto;

  @media (max-width: 1450px),
  (max-height: 760px) {
    // min-height: 26rem;
  }

  canvas {
    max-height: unset;
  }
}

.concentric-circle-chart {
  max-height: 17rem;
  position: relative;

  .info-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0.25rem;
    min-width: 8rem;

    h5 {
      margin-right: -1.65rem;
    }

    p {
      font-size: 12.5px;

      @media (max-width: 1450px),
      (max-height: 760px) {
        font-size: 12px;
      }
    }
  }

  .labels-inner {
    bottom: -0.325rem;
    left: calc(50% + 4rem);
    transform: translateX(-50%);

    @include flexBox(flex, column nowrap, null, null);
    gap: 1px;


    @media (max-width: 1450px),
    (max-height: 760px) {
      gap: 0.1rem;
      bottom: -0.3rem;
    }

    .pair {
      @include flexBox(flex, null, center, null);

      span {
        width: 7.2rem;
        flex: 0 0 7.2rem;
        text-align: right;
        padding-right: 0.5rem;

        @media (max-width: 1450px),
        (max-height: 760px) {
          width: 6.6rem;
          flex: 0 0 6.6rem;
        }
      }

      strong {
        width: 1.5rem;
      }
    }

    &--inventory {
      left: calc(50% + 2.5rem);
      .pair {
        span {
          text-align: left;
          width: 3.12rem;
          flex: 0 0 3.12rem;
        }
      }
    }
  }
}
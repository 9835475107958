.layer-block {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.6);
}

.layer {
  gap: 0.8rem;
  align-items: center;
}

.batch-number {
  background: #efcfe4;
  padding: 3.6px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 219px;
  left: 59px;
}

.number p {
  font-size: 8px;
  font-weight: 700;
  line-height: 12px;
}

.layer-desc {
  margin-top: 10px;
}

.layer-desc p {
  line-height: 17px;
  letter-spacing: 0em;
  color: #787878;
}

.multiple-batch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.levels {
  line-height: 20px;
  letter-spacing: -0.03em;
}

.layer-arrow-down {
  position: absolute;
  transform: rotate(90deg);
  top: 105px;
  height: 12px;
  left: 160px;
}

.new-layer {
  line-height: 20px;
  letter-spacing: -0.03em;
}

.page-desc {
  margin-bottom: 10px;
  max-width: 100%;
  border-radius: 0.625rem;
  background: url(./../images/page-header-bg.svg) no-repeat right top;
  background-color: var(--text-dark);
  max-height: calc(100vh - 9.5rem);
  top: 1rem;
  display: flex;
  flex-flow: column nowrap;
}

.page-desc .dark-block-content h3 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.block-wavy-add-product {
  background: url(./../images/products-add-waveBg.svg) no-repeat left top;
  border-radius: 0.625rem;
  background-color: var(--text-dark);
  display: flex;
  flex-flow: row nowrap;
  min-height: 13.7rem;
  padding: 0 0 0 34px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.left-product {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 210px;
  gap: 0.625rem;
}

.right-product {
  position: absolute;
  right: 0;
  bottom: -0.25rem;
}

.product-screen-graphics {
  background: url(./../images/long-dark-black-waveBg.svg) no-repeat right top;
  background-color: rgba(255, 255, 255, 0.38);
  border-radius: 0.625rem;
}

.product-chart {
  border-radius: 0.625rem;
}

.category-desc-content {
  display: flex;
  flex-flow: row nowrap;
  gap: 6rem;
  justify-content: center;
  align-items: center;
}

.category-distribution-listing {
  display: flex;
  flex-flow: row wrap;
  gap: 0.48rem 0.56rem;
  align-items: center;
  margin-block: 1rem 0;

  &>li {
    flex: 0 0 100%;
    max-width: 100%;
  }

  &--inventory {
    &>li {
      flex: 0 0 calc(50% - 0.3rem);
      max-width: calc(50% - 0.3rem);
    }
  }
}

.category-group {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.38);
  border: 2px solid #fff;
  border-radius: 0.8rem;
  height: 3.25rem;
  gap: 0.63rem;
}

.category-group .icon-wrap {
  width: 3.25rem;
  height: 3.3rem;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.category-desc {
  flex: 1;
  padding-right: 0.7rem;
  display: inline-flex;
  align-items: center;
}

.description-box {
  height: 270px;
  overflow: auto;
}

.description-box::-webkit-scrollbar {
  width: 0.5em;
}

.description-box::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}

// addon
.tracker-wrapper {
  background-color: rgba(255, 255, 255, 0.38);
  padding: 1rem;
  border-radius: 0.625rem;
  height: calc(100vh - 9.35rem);
  top: 1rem;
  position: sticky;

  @media (max-width: 1450px),
  (max-height: 760px) {
    height: calc(100vh - 7.5rem);
  }
}

.tracker-bg {
  background: url(./../images/tracker-bg.svg) no-repeat right top !important;
  height: 100%;
  max-width: 100%;
  border-radius: 0.625rem;
  background-color: var(--text-dark) !important;
  display: flex;
  flex-flow: column nowrap;
}

.map {
  flex: 1;
  height: 100%;
  border-radius: 0 0 10px 10px;
  overflow: hidden;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.tracker-bg .dark-block-content h3 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.tracking-status {
  background-color: rgba(255, 255, 255, 0.38);
  padding: 1rem;
  border-radius: 0.625rem;
  height: 100%;
  max-height: calc(100vh - 7.399rem);
}

.tracker-product-details {
  border-radius: 10px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  margin-top: 13px;
  max-height: calc(100vh - 15rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.tracker-product-details::-webkit-scrollbar {
  width: 0.5em;
}

.tracking-btn {
  border: none;
  color: black;
  letter-spacing: -0.42px;
  padding: 0.625rem;
  height: 44px;
  border-radius: 0.375rem;
  background: #fff;
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
}

.tracking-btn-disabled {
  background: rgba(239, 207, 228, 0.17);
}

ul.preview-tags .download-btn-wrapper {
  height: 1.5rem;
  border-radius: 0.3125rem;
  background: var(--text-dark, #222);
  box-shadow: 3px 5px 12px 0px #c0c0c0;
}

.tracker-info {
  padding-left: 54px;
}

.tracker-user-card-wrapper {
  margin-top: 10px;
  padding: 0.65rem;
  border-radius: 0.625rem;
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
  background: rgba($color: #fff, $alpha: 0.38);
  @include ts(0.3s);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0.13;
    @include ts(0.3s);
    border-radius: 0.625rem;
    background: var(--holo-grad-radial);
  }
}

.user-tracking-pic {
  gap: 17px;
  align-items: center;

  .page-header__user-info.round_pf_pic .user-img {
    width: 4.0625rem;
    height: 4.0625rem;
    padding: 0.22rem;

    img {
      width: 100%;
      height: 100%;
    }

    span {
      padding: 0.2rem 0.2rem 0.25rem;
    }
  }
}

.trusted-user {
  color: #268d3f;
  font-size: 0.75rem;
  gap: 5px;
}

.tracker-info-footer {
  justify-content: end;
}

.agreement-wrapper {
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.download-content h3 {
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.download-content p {
  color: #787878;
  margin: 0;
  line-height: 17px;
  letter-spacing: 0em;
}

.Bulk-products {
  height: 45px;
}

.add-bulk-products {
  align-items: center;
}

.crown-wrapper {
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  left: -2px;
  top: 2px;
}

.crown-wrapper img {
  max-width: 45%;
}

.tracking-downline {
  width: 100%;
  justify-content: space-between;

  &>li {
    position: relative;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }

      .download-qr {
        .dot {
          display: none;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: -6px;
      width: 6px;
      height: 6px;
      top: 50%;
      transform: translateY(-50%);
      background: var(--text-dark);
      border-radius: 50%;
    }

    &::after {
      content: "";
      border-top: 1.5px dashed var(--text-dark);
      width: 36%;
      position: absolute;
      right: -36%;
      top: 50%;
      transform: translateY(-50%);
    }

    .download-qr {
      position: relative;
      z-index: 2;

      .dot {
        position: absolute;
        right: -6px;
        width: 6px;
        height: 6px;
        top: 50%;
        transform: translateY(-50%);
        background: var(--text-dark);
        border-radius: 50%;
      }
    }

    &.disabledLi {
      .download-qr {
        background: #dadada;

        .gradientText--radial {
          -webkit-text-fill-color: white;
        }

        .dot {
          -webkit-filter: invert(100%);
          /* Safari/Chrome */
          filter: invert(100%);
        }
      }

      &::before,
      &::after {
        -webkit-filter: invert(100%);
        /* Safari/Chrome */
        filter: invert(100%);
      }
    }
  }
}

.upload-qr {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;

  .page-header__other-options {
    flex: 1;
  }

  .btn {
    flex-shrink: 0;
  }
}

.search-wrapper {
  flex: 1;
}

.tagBefore {
  background-color: #222;
  border-radius: 5px;
  position: absolute;
  left: -99px;
  top: calc(50% - 1.1rem);
  transform: translateY(-50%);
  height: 24px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tagBefore span {
  align-items: center;
  justify-content: center;
  display: flex;
}

.tracker-user-card {
  z-index: 1;
}

.tracking-date-tag span {
  height: 1.375rem;
  width: auto;
  padding-inline: 0.35rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.demo-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 10px 10px 0 0;
}

.tracker-info-wrapper {
  position: relative;
  padding-left: 100px;
}

.tracker-info-wrapper::after {
  content: "";
  position: absolute;
  left: 60px;
  top: -0.3rem;
  border-left: 1px dashed black;
  height: 101%;
}

.tracker-info-wrapper::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  background: var(--text-dark);
  border-radius: 50%;
  left: 3.615rem;
  top: -0.25rem;
}

.tracker-user-card-wrapper::before {
  content: "";
  position: absolute;
  left: -93px;
  top: 50%;
  transform: translateY(-50%);
  border-top: 1px dashed black;
  width: 92px;
}

.user-card-dot {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  background: var(--text-dark);
  border-radius: 50%;
  top: 50%;
  left: -6.5px;
}

.tracker-info-wrapper-dot {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: var(--text-dark);
  border-radius: 50%;
  transform: translateY(-50%);
  left: 3.615rem;

  @media (max-width:567.98px) {
    left: 0.6rem;
    bottom: 0;
  }
}

.rfid {
  margin: 0.56rem 0.81rem 1.24rem 0.51rem;
}

.rfid-success {
  gap: 1rem !important;
}

.cards-holder {
  max-height: calc(100vh - 7.25rem);
  overflow-y: auto;
}

.cards-holder::-webkit-scrollbar {
  width: 0.5em;
}

.activities-right-image {
  padding: 1rem;
  max-height: 55vh;

  img {
    height: 100%;
  }

  @media (max-width: 1450px),
  (max-height: 760px) {
    padding: 0 1rem;
  }
}

.activities-screen-graphics {
  max-height: calc(100dvh - 19rem);

  @media (max-width: 1450px),
  (max-height: 760px) {
    max-height: calc(100dvh - 7.7rem);
  }
}

.activities-card-arrow {
  width: 3rem;
}

.tracking-activity {
  width: 9.625rem;
  height: 2.125rem;
  box-shadow: 3px 5px 12px 0px #c0c0c0;
}

.activities-content {
  .detail-cards .card__text .text-pair .label {

    @media (max-width: 1450px),
    (max-height: 760px) {
      max-width: 4.5rem;
      flex: 0 0 4.5rem;
    }
  }

  .td-status span {
    padding-inline: 0.4rem;
  }
}

.block-widget--activities {
  .inside-content {
    padding: 0.69rem;
  }

  .tracking-activity {

    @media (max-width: 1450px),
    (max-height: 760px) {
      .block-widget--activities .tracking-activity {
        max-width: 8.5rem;
        height: 2rem;
    }
      height: 2rem;
    }
  }
}

.activities-text {
  display: inline-flex;
  flex-flow: column nowrap;
  gap: 0.75rem;

  .activites-text-pair {
    display: inline-flex;
    align-items: center;
    line-height: 1.35;
    gap: 0.35rem;
  }

  .label {
    max-width: 5rem;
    flex: 0 0 5rem;
  }

  .value {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    gap: 0.6rem;

    .activities-card-btn {
      align-items: center !important;
    }
  }

  .activities-card-header {
    display: inline-flex;
    margin-inline: 0.5rem;
    flex-flow: row nowrap;
  }
}

.card-content-right {
  width: 154px;
}

.activities-card-active {
  margin: 0 -0.4rem -0.5rem !important;
}

.inside-content {
  position: relative;
  z-index: 1;
}

.btn-fab--peach {
  background-color: var(--stroke, #efecec);
}

.activities-content .detail-cards {
  margin: 0;
  padding: 0;
  overflow: inherit;

  .card-inner .card__text {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .text-pair {
    width: 154px;
  }
}

.cta_modal {
  min-width: 880px;
}

.modal_wrapper {
  width: 100%;
  max-width: 880px;
  position: relative;
  border-radius: 0.75rem;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 0.625rem;
    background: var(--holo-grad);
    z-index: 1;
    opacity: 0.38;
  }

  .main-block {
    height: 100%;
    max-width: 100%;
    padding: 3rem 1.2rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(./../images/modal-background.svg) no-repeat right top;
    position: relative;
    z-index: 2;
  }

  .content-inner {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
  }

  .inner-text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h3 {
    line-height: 57px;
    letter-spacing: -1.8px;
    margin-block: 1rem 0;
  }

  p {
    font-weight: 600;
    letter-spacing: -1.38px;
  }

  .btn-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-top: 31px;

    .btn {
      color: white;
      width: 148px;
      height: 45px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--text-dark);

      &.modal-confirm-btn {
        background-color: #fff;
        color: black;
      }
    }
  }
}

.add_product_modal {
  .modal-dialog {
    max-width: 600px;
  }

  .modal-header {
    border-bottom: 0;
  }
}

.add-product-modal-content {
  background: transparent;
  border: none;
}

.add-product-modal-content .modal-body {
  background-color: #fff;
  border-radius: 10px;
}

.add-product-modal-content .modal-footer {
  background-color: #222;
  border-radius: 10px;
}

.add-product-modal-content {
  .modal-header {
    margin-bottom: 0.5rem;

    h3 {
      padding: 1.12rem 1.5rem;
      border-radius: 0.625rem;
      background: url(./../images/modal-header-background.svg) no-repeat right top;
      background-color: var(--text-dark);
      width: 100%;
      @include flexBox(flex, null, center, null);
      gap: 0.4rem;
      min-height: 5.2rem;
    }
  }
}

.w-84 {
  width: 84px;
}

.w-59 {
  width: 59px;
}

.trusted-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
}

.inside-list {
  border-radius: 10px;
  padding: 42px 25px;
  @include flexBox(flex, row wrap, center, space-between);
  gap: 34px 0;
}

.content-inside {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  flex: 0 0 30%;
}

.bg-black-content {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #222;
  color: #fff;
  border-radius: 6px;
}

.no-content {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  color: black;
  border-radius: 6px;
}

.modal-divider {
  border-top: 1px dashed #222222;
}

.product-counter {
  padding: 15px;
}

.counter-wrapper {
  width: 40%;

}

.add-product-modal-content {
  .cta-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      height: 3.25rem;
      border-radius: 0.625rem;

      @media (max-height: 760px),
      (max-width: 1450px) {
        height: 3rem;
      }

      @media (max-width: 991.98px) {
        height: 2.8rem;
      }

      @media (max-width: 767.98px) {
        height: 2.6875rem;
      }
    }

    .btn-gradient-border>div {
      height: 100%;
    }

    .btn-gradient {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .counter-wrapper {
    @include flexBox(inline-flex, null, center, flex-end);
    gap: 0.5rem;
    width: max-content;

    .input-group {
      height: 3.125rem;

      @media (max-height: 760px),
      (max-width: 1450px) {
        height: 3rem;
      }

      @media (max-width: 991.98px) {
        height: 2.8rem;
      }

      @media (max-width: 767.98px) {
        height: 2.6875rem;
      }
    }

    .btn-fab {
      background: var(--text-dark);
      box-shadow: 0px 8px 16px rgba(50, 50, 71, 0.06),
        0px 8px 8px rgba(50, 50, 71, 0.08);
      padding: 0;
      width: 3.125rem;
      flex-shrink: 0;
      height: 3.125rem;
      border-radius: 0.625rem;
      @include flexBox(inline-flex, null, center, center);

      @media (max-height: 760px),
      (max-width: 1450px) {
        width: 3rem;
        height: 3rem;
        border-radius: 0.575rem;
      }

      @media (max-width: 991.98px) {
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 0.475rem;
      }

      @media (max-width: 767.98px) {
        width: 2.6875rem;
        height: 2.6875rem;
      }

      img {
        @include ts(0.3s);
        max-width: 48%;
        height: 1.125rem;
      }

      &:hover,
      &:focus,
      &:focus-within,
      &:active {
        background: var(--primary-dark);

        img {
          transform: scale(1.07);
        }
      }
    }
  }
}

.counter-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

#qty_input {
  text-align: center;
  color: #222;
}

.cta-btn-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 25px 23px;

  .cta-row {
    gap: 0.65rem;
    display: flex;
  }

  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 9rem;
    height: 2.8125rem;
  }

  .cancel-modal-btn {
    border: 1px solid var(--text-dark);
    background: transparent;
  }

  .next-modal-btn {
    background-color: var(--text-dark);
  }
}

.modal-footer-dark {
  background-color: #222;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal2_btns {
  background-color: #222;
}

.profile-tabs {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-bottom: 0;
}

.profile-tabs>li {
  overflow: hidden;
  border-radius: 10px;

  .profile-tabs-content {
    background: url(./../images/profile-bg.svg) no-repeat left bottom;
    padding: 2.29rem;
    height: 147px;
    background-color: rgba($color: #fff, $alpha: 0.6);
    border-radius: 0.625rem;
    position: relative;
    overflow: hidden;
    // @include flexBox(flex, column nowrap, null, center);

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: var(--holo-grad);
      z-index: -1;
      border-radius: 0.625rem;
    }

    h3 {
      color: var(--text-dark, #222);
      font-family: Jost;
      font-size: 18px;
      line-height: normal;
      margin: 0;
    }

    p {
      width: 652px;
      color: var(--grey, #656565);
      margin: 0;
    }

    .profile-tab-arrow {
      position: relative;
      left: 98%;
      height: 9px;
      bottom: 9%;
      transform: rotate(90deg);
    }
  }
}

.profile-tabs {
  &>li {
    .profile-tabs-content {
      .upload-click {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
      }
    }
  }
}

.profile-right-wrapper {
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 1.81rem;

  .user-img-upload {
    margin-inline: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;

    height: 14.25rem;

    @media (max-width: 1450px),
    (max-height: 760px) {
      height: 11.8rem;
    }

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      inset: 0.65rem 0 0;
      position: absolute;
    }

    .user-dp {
      border-radius: 50%;
      width: 12.4375rem;
      height: 12.4375rem;
      background: var(--holo-grad);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.67rem;

      @media (max-width: 1450px),
      (max-height: 760px) {
        width: 10.4375rem;
        height: 10.4375rem;
        padding: 0.4rem;
      }

      &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        img,
        .img__dp {
          object-fit: cover;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          border: 0.65rem solid var(--text-dark);

          @media (max-width: 1450px),
          (max-height: 760px) {
            border-width: 0.45rem;
          }
        }

        .img__dp {
          background: var(--text-dark);
          @include flexBox(inline-flex, null, center, center);
          padding-top: 0.65rem;
        }
      }
    }

    .btn {
      position: absolute;
      bottom: -0.15rem;
      left: 50%;
      pointer-events: none;
      transform: translateX(-50%);
    }

    /* Chrome/Safari and web-kit-based browsers (if it doesn't work, try maybe also on the parent/wrapper) */
    ::-webkit-file-upload-button {
      cursor: pointer;
    }

    /* IE11 (if it doesn't work, try maybe also on the parent/wrapper) */
    input[type="file"] {
      cursor: pointer;
    }
  }

  .user-name {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    h3 {
      letter-spacing: -0.05625rem;
    }
  }
}

// .profile-tabs-upload {
//   top: 7%;
//   left: 3%;
// }

.info-row {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  margin-bottom: 2rem;

  @media (max-width: 1450px),
  (max-height: 760px) {
    margin-bottom: 1.25rem;
  }
}

.profile-block-content .dark-block-content {
  height: 100%;
  gap: min(3vh, 3rem);
  padding: 4rem 2rem 1.2rem;
  overflow-y: auto;
  // Hide scrollbar
  // For mozilla firefox
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  @media (max-width: 1450px),
  (max-height: 760px) {
    padding: 1.65rem 1.5rem 0.5rem 1.65rem;
  }
}

.profile-wrapper {
  height: 100%;
  overflow-y: auto;
  height: calc(100% - 6.95rem);

  @media (max-width: 1450px),
  (max-height: 760px) {
    height: calc(100% - 7rem);
  }
}

.profile-info {
  margin-top: auto;
}

.inventory-success {
  padding: 2rem;
}

.inventory-success .sec {
  display: flex;
  flex-flow: row wrap;
  flex: auto;
  justify-content: space-between;

  div {
    min-width: 220px;
  }
}

.text-dark {
  color: #222;
}

.packaging-card-header {
  border-radius: 10px;
  border: 2px solid #fff;
  background: var(--whitw, #fff);
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
  padding: 0.5rem 0.9rem;
  display: flex;
  align-items: center;
}

.packaging-card-info-wrapper {
  padding: 1rem;

  @media (max-width: 767.98px) {
    padding: 0.65rem;
  }
}

.packaging-card-info {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  max-height: 11rem;

  .packaging-pair {
    display: inline-flex;
    flex-flow: column nowrap;
    min-width: 35%;
    min-height: 3.8rem;

    h3 {
      color: var(--text-light, #787878);
      font-style: italic;
    }

    span {
      letter-spacing: -0.42px;
    }

    .products-added {
      display: flex;
      gap: 1.5rem;
    }
  }

  .pair-full-width {
    min-width: 100%;
    padding: 1rem;
  }
}

.packaging-card-btn {
  width: 6.65rem;
  white-space: nowrap;
  margin-right: -0.85rem;
  height: 2.125rem !important;
}

.product-screen .packaging-screen {
  height: calc(100vh - 6rem);
  overflow: auto;
}

.product-screen .packaging-screen::-webkit-scrollbar {
  width: 0.5em;
}

.packing-inside-heading {
  font-style: italic;
}

//side stepper

.right-stepper .steps ul {
  display: flex;
  flex-flow: column;
  // padding-left: 2rem;
  gap: 2.0625rem;
  align-items: flex-start;

  @media (max-width: 1450px),
  (max-height: 760px) {
    gap: 1.5rem;
  }
}

.stepper-details {
  margin: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 250px;
}

.li-wrapper {
  display: flex;
  align-items: center;
}

.right-stepper .steps ul li {
  &:nth-child(2) {
    .vertical-line {
      height: 4rem;
    }
  }

  &:nth-child(3) {
    .vertical-line {
      height: 3.3rem;
    }
  }

  &:nth-child(4) {
    .vertical-line {
      height: 2.3rem;
    }

    .horizontal-line {
      visibility: hidden;
    }
  }
}

.location-btn {
  right: 0;
  top: 2rem;

  img {
    height: 1.125rem;
  }
}

.delete-icon {
  height: 16px;
}

.map-location-wrapper {
  height: 100%;
  border-radius: 0.625rem;
  background: url(./../images/icons/map-location.svg) no-repeat;
  background-size: cover;
}

.map-location-icon {
  right: 0.6rem;
  top: 0.6rem;
  background: var(--holo-grad-radial);
  padding: 15px;
  border-radius: 16px;
  @include ts(0.3s);

  @media (max-width: 767.98px) {
    background: white;
  }
}

.preview-text-wrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

// Media Queries

@media (max-width: 767.98px) {
  .product-screen {
    display: flex;
    flex-flow: column nowrap;
  }

  .product-screen-right {
    display: none;
  }

  .block-content {
    padding: 1.12rem 1.5rem;
  }

  .block-wavy-dark-mobile {
    padding: 0.5rem 0.5rem;
    border-radius: 0.625rem;
    background-color: var(--text-dark);
    padding: 1rem;
  }

  .header-search {
    display: none;
  }

  .nav-hidden {
    display: none;
  }

  .mobile-btn-nav {
    display: flex;
    flex-flow: row nowrap;
    background: #222;
    padding: 1rem;
    gap: 10px;
    border: none;
    border-radius: 0.625rem;
    position: fixed;
    width: 97%;
    z-index: 10;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .mobile-btn-white {
    background-color: #fff !important;
    border-radius: 0.5rem;
  }

  .mobile-btn-gradient {
    background: var(--holo-grad) !important;
  }

  .mobile-btn-white span {
    font-size: 12.4px;
    color: #222 !important;
  }

  .upload-mobile-row {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    margin-top: -7px !important;
  }

  .success-btn-hidden {
    display: none;
  }

  .content--successCSV {
    width: auto;
  }

  .outer-container {
    margin-top: 3rem;
  }

  .success-btn-display {
    width: 242px;
    margin-top: 1rem;
  }

  .header-block-mobile {
    display: none;
  }

  .upload-form-doc {
    margin-top: -37px !important;
  }

  .header-block .a-content {
    flex-flow: column;
  }

  .preview-image-block {
    display: flex;
    flex-flow: column nowrap;
  }

  .preview-image-block .img-wrapper {
    /* width: 20rem; */
    width: 100%;
  }

  .tracking__left {
    max-width: 100%;
    margin-top: 0;
    padding: 0;
  }

  .trac-right-sec {
    max-width: 100%;
  }

  .scroll-div-right {
    max-width: 100%;
  }

  .div-hidden {
    display: none;
  }

  .new_card {
    margin-top: 1px;
  }

  .cards {
    padding: 0.91rem;
  }

  .tracking-card-btn {
    max-width: 100%;
  }

  .tracking-card-left {
    display: flex;
    align-items: center !important;
    justify-content: center;
  }

  .tracking-card-btn-left {
    width: 100%;
    max-width: 100%;
  }

  .tracking-btn-arrow {
    top: 65%;
    transform: rotate(90deg);
    height: 0px;
    width: 50px;
    right: 0%;
  }

  .tracking-card-btn {
    background-image: url(./../images/tracking-mobile-wavesBg.svg) no-repeat top left !important;
  }

  .other-add-links__middle {
    max-width: 100%;
  }

  .plcards-wrapper-div {
    display: flex;
    flex-flow: column nowrap;
    max-width: 100%;
    width: 100%;

    .pl-01-cards {
      width: 100%;
      height: auto;
      position: relative;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      min-height: 12.5rem;

      .up-card {
        .card-details {
          margin-bottom: 0;
        }
      }

      .down-card1 img,
      .down-card2 img {
        width: auto;
        height: auto;
        max-width: 15rem;
      }

      .down-card2 img {
        bottom: -1.3rem;
        right: -1.5rem;
      }

      .up-card .card-tite {
        justify-content: flex-start;
        margin-right: -2rem;
        font-size: 18px;
      }

      .content-inner {
        margin-block: 0;
        text-align: left;
        margin-left: 1.5rem;
      }
    }
  }

  .btn-hidden {
    display: none !important;
  }

  .image-hidden {
    display: none;
  }

  .mobile-navigation-top img {
    width: 15px;
  }

  .steps ul .step .status img {
    display: block;
  }

  .fz10 {
    font-size: 10px;
  }

  .heading-top-div h6 {
    font-size: 12px;
  }

  .preview-topBar {
    padding: 0.8rem 0.85rem;
    background: url(./../images/preview-waveBg.svg) no-repeat bottom right;
  }

  .preview-wrapper {
    background-color: #222;
    border-radius: 10px;
  }

  .success-mobile-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .success-card.success-card-overidden {
    background-image: none;
    width: auto;
    height: auto;
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  .success-card .fir {
    padding: 0.5rem;
    margin: 0;
  }

  .dwnld_btn.dwnld_btn-modified {
    top: 16rem;
  }

  .mobile-desc-row {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 25px;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .mobile-content-success {
    display: flex;
    flex-flow: column nowrap;
    gap: -1px;

    span {
      color: white;
    }
  }

  .sc_title {
    font-size: 14px;
    font-weight: 400;
    color: white;
  }

  .sc_value {
    font-size: 14px;
    font-weight: 600;
  }

  .mobile-btn-modified {
    width: 43px;
    height: 43px;
    border-radius: 0.625rem;
  }

  .inventory-container {
    margin-bottom: 7rem;
  }

  .inventory-success-content-layout {
    overflow: visible;
  }

  .success-card {
    background-image: none;
    width: 15.5rem;
    height: auto;
    flex-flow: column nowrap;
    // align-items: flex-start;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .rfid {
    padding: 0.5rem;
    margin: 0;
  }

  .rfid-mobile-desc {
    padding: 0 14px;
  }

  .packaging-card-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    padding: 0 0.8rem;
    min-height: 2.45rem;
    margin-top: -2px;

    button {
      width: 8rem;
    }
  }

  .insideCard--Heading {
    background: #222;
    margin-bottom: 0.8rem;
  }

  .activities-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .cards-holder {
    max-height: 100%;
    overflow-y: visible;
  }

  .activities-main-content {
    overflow-y: visible;
  }

  .activities-content .detail-cards .card-inner .card__text {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    // gap: 4.5rem;
  }

  .activities-content .detail-cards .text-pair {
    width: 45%;
  }

  .activities-content .detail-cards .card__text .text-pair .label {
    max-width: 5rem;
    flex: 0 0 5rem;
  }

  .success-heading1 {
    font-size: 32px;
  }

  .success-heading2 {
    font-size: 40px;
  }
}

@media (max-width: 567.98px) {
  .block-content {
    padding: 1rem 0.75rem;
  }

  .plcards-wrapper-div {
    .pl-01-cards {

      .down-card1 img,
      .down-card2 img {
        max-width: 100%;
        min-height: 9.5rem;
      }

      .down-card2 img {
        bottom: -0.8rem;
        right: -0.7rem;
      }

      .content-inner {
        margin-left: 1rem;
      }
    }
  }

  .packaging-pair h3 {
    font-size: 12px;

    span {
      font-size: 12px;
    }
  }

  .activities-content .detail-cards .text-pair {
    width: 45%;
    flex-flow: row nowrap;
  }

  .activities-content .detail-cards .text-pair:has(.brnd_btn_black) {
    display: inline-flex;
  }
}

@media (min-width: 768px) {
  .block-wavy-dark-mobile {
    display: none;
  }

  .mobile-btn-nav {
    display: none;
  }

  .success-btn-display {
    display: none;
  }

  .header-block-hidden {
    display: none;
  }

  .product-show-hide {
    display: none;
  }

  .mobile-btn-nav {
    display: none;
  }

  .success-btn-display {
    display: none;
  }

  .header-block-hidden {
    display: none;
  }

  .product-show-hide {
    display: none;
  }

  .preview-wrapper {
    display: none;
  }

  .mobile-desc-row {
    display: none;
  }

  .desktop-image-hidden {
    display: none;
  }

  .packaging-card-header button {
    display: none !important;
  }

  .insideCard--Heading {
    display: none;
  }

  .activities-row {
    display: none;
  }

  .rfid-mobile-desc {
    display: none !important;
  }
}


// other things added in inventory

.btn-fab--black {
  background-color: #222;
}

.packaging-card-header h3 {
  flex: 0 0 60%;
}

.packaing-preview {
  flex-flow: column nowrap;
  gap: 30px;
}

.packaing-preview .preview-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
}

.preview-content h3 {
  flex: 0 0 60%;
  color: var(--new, #222);
}

.preview-content span {
  color: var(--new, #222);
}

.inventory-updated-success div {
  flex-direction: row !important;
}

.inventory-updated-successWrapper {
  background-image: url(../../assets/images/inventory-success-updated.svg) !important;
  background-repeat: no-repeat;
  background-position: top left;
  display: block;
  padding: 3.5rem 2.5rem;
  height: 14.75rem;

  .dwnld_btn {
    position: absolute;
    right: 0.63rem;
    background: var(--holo-grad);
    display: grid;
    place-items: center;
    top: 0.63rem;
  }
}

.success_title {
  color: white;
}

.success_value {
  color: white;
}

.inventory-updated-success {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
}

.success-card-btn {
  position: absolute;
  right: 0.7rem;
  bottom: 1rem;
}

// Added Extra 
.product-view-updated {
  padding-right: 0;
  height: 270px;

  input[type="file"] {
    position: absolute;
    inset: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
  }

  .other-add-links__left {
    max-width: 100%;

    .clickBtn {
      padding: 0 min(5vw, 4rem);
    }
  }
}

.description-box {
  padding: 0.5rem 0.5rem;
}

// Modal scss changes
.content-side-by-side {
  display: flex;
  gap: 13rem;
  align-items: center;
  justify-content: space-between;
}

.list-bottom {
  border-radius: 10px;
  padding: 42px 35px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: 34px 0;
}

.cf-modal-content {
  .counter-number {
    align-items: flex-start;
    flex-flow: column nowrap;
  }

  @media (max-width: 767.98px) {
    .modal-header {
      h3 {
        img {
          display: none;
        }
      }
    }

    .product-counter {
      padding: 0.5rem;
    }

    .btn-fab--green {
      min-width: 2.8125rem;
    }

    .form-control {
      height: 2.825rem;
    }

    .cta-row {
      .btn {
        flex: 1;
      }
    }
  }
}
.explorerDetails-info-wrapper {
    padding: 1.5rem 1rem 0;
    @include flexBox(flex, column nowrap, null, null);
    gap: 1.5rem;
}


.explorer-detailed-view {
    .api-doc-head {
        border: 0;
        border-radius: 0.5rem;
        padding: 0.85rem 1rem;
        border-bottom: 2px solid white;
        background: rgba(255, 255, 255, 0.7);
    }
}

.exp-head {
    min-width: 200px;

    img {
        height: 26px;
        width: 26px;
    }
}

.hashDetails-info .exp-head {
    min-width: 240px;
}

.previousHash-info {
    padding: 0rem 1rem;
    margin-block: 1.2rem;
}

.explorerDark-Footer {
    background-color: black;
    position: absolute;
    width: 100%;
    min-height: 38px;
    padding: 0rem 2.5rem;
    display: flex;
    align-items: center;

}

.multichain-transacrtion-wrapper {
    padding: 1rem;
}

.explorer-charts {
    background-color: black !important;
}


.explorer-detailed-btn {
    height: 34px !important;
}


.exp-illustration {
    height: 96%;
    border-radius: 0.625rem;
    background: url(./../images/explorer-illustration.svg) no-repeat right top;
    background-color: #FFFFFF;
    display: flex;
    flex-flow: column nowrap;
}

.exp-content {
    padding: 4rem 3.2rem 1.2rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;

    .custom-data-table {
        .form-control {
            background-color: rgba($color: #FFFFFF, $alpha: 0.75);

            &::placeholder {
                color: #575757;
                // font-weight: 400;
            }

            &:focus {
                background-color: rgba($color: #FFFFFF, $alpha: 0.95);
            }
        }
    }
}

.exp-content-image {
    padding: 1rem;

    img {
        max-width: min(32vh, 21rem);
        margin-bottom: 2.5rem;
    }
}

.transc-icon {
    background-color: black;
    padding: 1rem;
    border-radius: 0.625rem;
}

.cards-inner-content {
    align-items: center;
}


.cards-inner-numbers {
    display: flex;
    justify-content: end;
}

.exp-cards-wrapper {
    padding: 2.2rem 2rem 2rem;
    height: 100%;
    @include flexBox(flex, column nowrap, null, center);

    .exp-cards-inner {
        min-width: 13rem;
        flex: 0 0 14.75rem;
    }

    .vertical {
        width: 1px;
        opacity: 0.3;
        min-height: 7rem;
        margin-bottom: 0.5rem;
        border-left: 1px solid var(--neutral-100);
    }

    hr {
        border-color: var(--neutral-100);
    }
}

.search-explorer {
    margin-bottom: 0.8rem;
    padding: 2.75rem 1rem 2rem;

    background: url(./../images/page-header-bg.svg) no-repeat right top, url(./../images/wavy-spiral-gradient-explorer.svg) no-repeat left bottom;
    // background-size: 100% 100%;
    background-color: var(--text-dark);

    &__inner {
        margin-inline: auto;
        max-width: min(50%, 50rem);
        @include flexBox(flex, column nowrap, center, center);
        gap: 0.5rem;

        p {
            color: var(--neutral-light);
        }
    }

    .page-header__other-options {
        width: 100%;
        margin-bottom: 0.5rem;

        .btn-fab {
            right: 0;
            top: 0;
        }

        .icon-wrap {
            border-radius: 10px;
        }

        .form-group--search {
            width: 100%;

            .form-control {
                padding-inline: 3.65rem;
                background: rgba(255, 255, 255, 0.75);
            }
        }
    }

    &--nextScreen {
        padding: 2.5rem 1rem;
        background-image: url(./../images/explorer-wavy-grad-right-top.svg), url(./../images/explorer-wavy-grad-left-bottom.svg);

        .search-explorer__inner {
            gap: 2.5rem;
            max-width: min(68%, 62.5rem);
        }
    }
}

.explorer-data {
    .td-incentive {
        box-shadow: none;
        min-width: 7.85rem;
        height: 1.625rem;
        font-size: 0.625rem;
        padding-block: 6px 5px;
        border-radius: 0.25rem;
        text-transform: uppercase;
        @include flexBox(inline-flex, null, center, center);
    }

    .holograf-table {
        th {

            &:first-child,
            &:last-child {
                border-radius: 0;
            }
        }
    }
}

.explorer-charts {
    .chart-container {
        max-height: 18rem;
    }
}

.custom-data-table--explorer {
    .table-responsive {
        max-height: calc(100vh - 14.15rem);
        overflow-y: auto;
    }
}

.block-wavy-dark--explorer {
    position: sticky;
    top: 1.2rem;
}
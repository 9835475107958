@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Josefin+Sans:wght@300;400;700&family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,500&display=swap");

.font-primary {
  font-family: "Jost", sans-serif;
}

.font-secondary {
  font-family: "Bebas Neue", sans-serif;
}

.font-tertiary {
  font-family: "Josefin Sans", sans-serif;
}

// Font sizes
.fz {
  &10 {
    font-size: clamp(0.59375rem, 0.5313rem + 0.0781vw, 0.625rem);
  }

  &12 {
    font-size: clamp(0.6875rem, 0.5625rem + 0.1563vw, 0.75rem);
  }

  &14 {
    font-size: clamp(0.775rem, 0.5rem + 0.325vw, 0.875rem);
  }

  &16 {
    font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem);
  }

  &18 {
    font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem);
  }

  &20 {
    font-size: clamp(1rem, 0.5rem + 0.625vw, 1.25rem);
  }

  &22 {
    font-size: clamp(1.05rem, 1rem + 0.3vw, 1.375rem);
  }

  &24 {
    font-size: clamp(1rem, 0.68rem + 0.425vw, 1.5rem);
  }

  &26 {
    font-size: clamp(1.4375rem, 1.0625rem + 0.4688vw, 1.625rem);
  }

  &28 {
    font-size: clamp(1.5625rem, 1.1875rem + 0.4688vw, 1.75rem);
  }

  &30 {
    font-size: clamp(1.5rem, 0.75rem + 0.9375vw, 1.875rem);
  }

  &34 {
    font-size: clamp(1.65rem, 0.85rem + 1rem, 2.125rem);
  }

  &46 {
    font-size: clamp(2.375rem, 1.375rem + 1.25vw, 2.875rem);
  }

  &52 {
    font-size: clamp(3rem, 2.5rem + 0.625vw, 3.25rem);
  }

  &60 {
    font-size: clamp(2.65rem, 0.25rem + 3.125vw, 3.75rem);
  }

  &70 {
    font-size: clamp(3rem, 0.125rem + 3.7vw, 4.375rem);
  }
}

@media (max-width: 1199.98px) {
  .fz {
    &20 {
      font-size: clamp(0.625rem, 0.275rem + 1vw, 1.25rem);
    }

    &30 {
      font-size: clamp(0.875rem, 0.4632rem + 1.8824vw, 1.875rem);
    }
  }
}

@media (max-width: 767.98px) {
  .fz {
    &10 {
      font-size: 10px;
    }

    &12 {
      font-size: 12px;
    }

    &14 {
      // font-size: clamp(0.775rem, 0.5rem + 0.325vw, 0.875rem);
    }

    &16 {
      // font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem);
    }

    &18 {
      // font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem);
    }

    &20 {
      font-size: clamp(0.625rem,
          calc(0.3676rem + 1.1765vw) * 0.85,
          calc(1.25rem) * 0.85);
    }

    &22 {
      // font-size: clamp(1.05rem, 1rem + 0.3vw, 1.375rem);
    }

    &24 {
      // font-size: clamp(1.15rem, 0.8rem + 0.425vw, 1.5rem);
    }

    &26 {
      font-size: clamp(1.175rem,
          calc(1.25rem + 1vw) * 0.85,
          calc(1.625rem) * 0.85);
    }

    &28 {
      // font-size: clamp(1.5625rem, 1.1875rem + 0.4688vw, 1.75rem);
    }

    &30 {
      font-size: clamp(0.875rem,
          calc(0.4632rem + 1.8824vw) * 0.8,
          calc(1.875rem) * 0.8);
    }

    &34 {
      // font-size: clamp(1.65rem, 0.85rem + 1rem, 2.125rem);
    }

    &46 {
      // font-size: clamp(2.375rem, 1.375rem + 1.25vw, 2.875rem);
    }

    &52 {
      // font-size: clamp(3rem, 2.5rem + 0.625vw, 3.25rem);
    }

    &60 {
      // font-size: clamp(2.65rem, 0.25rem + 3.125vw, 3.75rem);
    }

    &70 {
      // font-size: clamp(3rem, 0.125rem + 3.7vw, 4.375rem);
    }
  }
}

// Font weights //
.fw {
  &300 {
    @include fw(300);
  }

  &400 {
    @include fw(400);
  }

  &500 {
    @include fw(500);
  }

  &600 {
    @include fw(600);
  }

  &700 {
    @include fw(700);
  }
}

// Line heights
.lh {
  &1 {
    line-height: 1;
  }

  &1-2 {
    line-height: 1.2;
  }

  &1-5 {
    line-height: 1.5;
  }

  &1-65 {
    line-height: 1.65;
  }
}
// Product screen styling
.product-screen {
  @include flexBox(flex, row nowrap, null, null);
  gap: 2.13rem;

  &.product-screen-s3 {
    gap: 1rem;

    .trac-right-sec {
      min-width: 42%;
    }
  }

  @media (max-width: 1450px),
  (max-height: 760px) {
    gap: 1.75rem;
  }

  @media (max-width: 1200px) {
    gap: 1.25rem;
  }

  &__left {
    @include flexBox(flex, column nowrap, null, null);
    gap: 0.8rem;
    flex: 1;
  }

  &__form {
    flex: 1;

    // max-width: calc(100% - 32.15rem);
    .upload-block {
      padding: 1rem;
      gap: 1.85rem;

      @media (max-width: 767.98px) {
        padding: 0.5rem;
        gap: 0.65rem;
      }

      .img-wrapper {
        padding: 1px;
        border-radius: 0.725rem;
        width: 19.0625rem;
        height: 9.0625rem;

        @media (max-width: 567.98px) {
          border-radius: 0.4rem;
          max-width: 8rem;
          min-height: 7rem;
        }

        img {
          border-radius: 0.6rem;
          -o-object-fit: cover;
          object-fit: cover;
          width: 100%;

          @media (max-width: 567.98px) {
            border-radius: 0.25rem;
          }
        }
      }

      .text {
        @include flexBox(flex, column nowrap, null, center);
        gap: 0.5rem;
        flex: 1;

        @media (max-width: 767.98px) {
          h6 {
            font-size: 12px;
            font-weight: 600;
          }
        }

        @media (max-width: 567.98px) {
          gap: 0.125rem;

          h6 {
            padding-bottom: 0.5rem;
          }
        }

        p {
          padding-right: 4rem;

          @media (max-width: 767.98px) {
            font-size: 11px;
            padding-right: 2.8rem;
          }

          @media (max-width: 567.98px) {
            font-size: 10px;
          }
        }
      }
    }
  }

  &__graphics {
    padding: 0;

    // max-width: 32.5rem;
    max-width: 32%;
    flex: 0 0 32%;
    @include flexBox(flex, column nowrap, null, null);
    max-height: calc(100vh - 9.5rem);
    position: sticky;
    top: 1rem;

    @media (min-width: 1921px) {
      min-height: 820px;
    }

    @media (max-width: 1600px) {
      max-width: 30rem;
      flex: 0 0 38%;
    }

    @media (max-width: 1450px),
    (max-height: 760px) {
      max-width: 25rem;
      flex: 0 0 32%;
      min-height: 600px;
      max-height: calc(100vh - 7.7rem);
    }

    @media (max-width: 1366px) {
      min-height: unset;
    }

    @media (max-width: 1200px) {
      min-height: 500px;
    }

    .dark-block-content {
      padding: 5.2rem 4rem 1.2rem;
      @include flexBox(flex, column nowrap, null, null);
      gap: 0.35rem;

      @media (max-width: 1600px) {
        padding: 4rem 3.2rem 1.2rem;
      }

      @media (max-width: 1450px),
      (max-height: 760px) {
        padding: 2rem 2rem 1rem;
      }

      @media (max-width: 1200px) {
        padding: 2.2rem 1.5rem 1rem;
      }

      h3 {
        @include flexBox(flex, null, center, null);
        gap: 0.25rem;

        span {
          letter-spacing: -0.045rem;
        }
      }

      p {
        margin-bottom: 1.2rem;

        img {
          display: inline-block;
        }
      }
    }

    .illustration {
      margin: 0 1.65rem 2.15rem;

      @media (max-width: 1600px) {
        display: flex;
        flex: 0 0 42%;
        max-height: 42%;
        margin-block: auto;
      }

      @media (max-width: 1450px),
      (max-height: 760px) {
        flex: 0 0 35%;
        max-height: 42%;
      }

      img {
        margin-block: -0.85rem -3rem;

        @media (min-width: 1921px) {
          margin-block: 0.5rem -2rem;
          min-height: 400px;
        }

        @media (max-width: 1800px) and (min-height: 1000px) {
          margin-block: 0 -1rem;
        }

        @media (max-width: 1600px) {
          margin-block: -0.85rem -1.75rem;
          max-width: 85%;
          max-height: 100%;
        }

        @media (max-width: 1450px),
        (max-height: 760px) {
          margin-block: -0.85rem -1.25rem;
          max-width: 100%;
          max-height: unset;
        }
      }

      &--csv {
        img {
          @media (max-width: 1600px) {
            max-height: 100%;
          }
        }
      }
    }

    .cta-row {
      margin: auto 1.75rem 2.2rem;
      @include flexBox(flex, null, center, flex-end);
      gap: 0.75rem;

      @media (min-width: 1921px) {
        margin-bottom: 4rem;
      }

      @media (max-width: 1800px) {
        margin-bottom: 3rem;
      }

      @media (max-width: 1600px) {
        margin-bottom: 2.5rem;
      }

      @media (max-width: 1450px),
      (max-height: 760px) {
        margin: auto 1.25rem 1.6rem;
      }
    }

    .accordion {
      background: transparent;
      margin-block: 1.2rem 0;
      @include flexBox(flex, column nowrap, null, null);
      gap: 0.938rem;

      &-item {
        border: 0;
        color: white;
        background: transparent;
      }

      &-button {
        color: white;
        border-radius: 6px;
        background: transparent;
        border: 1px solid #383838;
        @include flexBox(flex, null, center, null);

        &::after {
          display: none;
        }

        &:focus {
          box-shadow: none;
        }

        &__left {
          width: 100%;
          gap: 0.35rem;
          @include flexBox(flex, null, center, null);
        }
      }

      &-button:not(.collapsed) {
        background: #383838;
        box-shadow: none;
        padding-bottom: 0.5rem;
        border-radius: 6px 6px 0 0;

        .accordion-button__right {
          opacity: 0;
        }
      }

      &-body {
        color: white;
        padding-top: 0;
        background: #383838;
        border-radius: 0 0 6px 6px;

        ul {
          @include flexBox(flex, column nowrap, null, null);
          gap: 0.68rem;
          margin-top: 0.75rem;

          a {
            @include flexBox(flex, null, center, null);

            img {
              -webkit-filter: saturate(0) brightness(0.45);
              filter: saturate(0) brightness(0.45);
              filter: saturate(0) brightness(0.45);
              filter: saturate(0) brightness(0.45);
            }

            span {
              -webkit-text-fill-color: #dbdbdb;
            }

            &.active {
              font-weight: 600;

              span {
                -webkit-text-fill-color: transparent;
              }

              img {
                filter: saturate(1) brightness(1);
              }
            }
          }
        }
      }
    }
  }
}

.m-page-heading {
  @media (max-width: 767.98px) {

    h3,
    h6 {
      font-weight: 600;

      span {
        font-size: 12px;
      }
    }

    h3,
    h6,
    p {
      font-size: 0.75rem;
    }

    &>span {
      font-size: 10px;
    }
  }
}

.steps {
  ul {
    @include flexBox(flex, null, flex-start, space-between);
    gap: clamp(0.425rem, 1vw, 1.2rem);

    .step {
      color: white;
      filter: saturate(0) brightness(0.45);

      .status {
        @include flexBox(inline-flex, null, center, null);
        gap: 0.4rem;
        margin-bottom: 0.6rem;

        img {
          display: none;
        }
      }

      .progress-step {
        img {
          min-height: 0.75rem;

          @media (max-width: 1600px) {
            min-height: 0.45rem;
          }
        }
      }

      .text {
        margin-top: 0.45rem;

        @media (max-width: 449.98px) {
          font-size: 10px;
        }
      }

      &.active {
        filter: saturate(1) brightness(1);

        &.completed {
          .status {
            img {
              display: inline-flex;
            }
          }
        }
      }
    }
  }
}

.backBtn {
  padding: 0;
  width: 2.4375rem;
  height: 1.25rem;
  border-radius: 3px;
  margin-left: 3px;
  margin-bottom: -0.6rem;
  background-color: var(--text-dark);
  @include flexBox(inline-flex, null, center, center);

  img {
    transform: rotateY(180deg);
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    background-color: var(--primary-dark);
  }
}

.upload-cerfificate {
  position: relative;
  padding: 0;

  .outerText {
    padding: 1.75rem 0.7rem 1rem 1.75rem;
    z-index: 9;
    @include flexBox(flex, column nowrap, null, null);
    gap: 0.25rem;

    &.m-outerText {
      padding: 1.75rem 0.7rem 1rem;
    }

    @media (max-width: 767.98px) {
      &.m-outerText {
        .preview-text-wrapper {
          gap: 0.35rem;
        }

        img {
          margin-top: 4px;
        }

        h6 {
          font-size: 12px;
        }
      }

      h6 {
        font-size: 13px;
      }
    }

    @media (max-width: 567.98px) {
      &.m-outerText {
        .preview-text-wrapper {
          gap: 0.15rem;
        }

        h6 {
          margin-top: 0 !important;
        }
      }
    }
  }

  .para {
    padding-right: 3.5rem;

    @media (max-width: 767.98px) {
      font-size: 10px;
    }
  }

  .pair {
    @include flexBox(flex, null, center, null);
    padding-right: 3.5rem;
    gap: 0.5rem;

    @media (max-width: 767.98px) {
      p {
        font-size: 10px;
      }
    }
  }

  .upload-click {
    right: 0.8rem;
    bottom: 0.7rem;
  }
}

.location-fetch {
  background-image: url(./../images/block-top-waves-gradient.svg);
  padding: 1.8rem 0.8rem 1.44rem 1.75rem;

  h5 {
    @include flexBox(flex, null, center, null);
    gap: 0.4rem;

    span {
      letter-spacing: -0.03188rem;
    }

    @media (max-width: 767.98px) {
      font-size: 13px;

      span {
        letter-spacing: 0;
      }
    }
  }

  .para {
    padding-right: 3.5rem;

    @media (max-width: 767.98px) {
      font-size: 10px;
    }
  }

  .upload-click {
    right: 0.8rem;
    bottom: 0.78rem;
  }
}

// Dropdown content styling
.dd-menu-colors {
  @include flexBox(flex, row wrap, center, normal);
  gap: 0.75rem;
  padding: 1rem;

  li {
    width: 1.1875rem;
    height: 1.1875rem;
    @include flexBox(inline-flex, null, center, center);
  }
}

.color {
  display: inline-flex;
  width: 1.1875rem;
  height: 1.1875rem;
  position: relative;
  @include ts(0.3s);

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    height: auto;
    width: auto;
    @include ts(0.3s);
    border-radius: 50%;
    background: var(--holo-grad-radial);
  }

  span {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #efecec;
    box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.16);
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.active:hover {
    width: 1rem;
    height: 1rem;

    &::after {
      inset: -3px;
      background: var(--holo-grad-radial);
      box-shadow: 3px 5px 12px #c0c0c0;
    }

    span {
      border: 0;
    }
  }

  &:hover {
    &::after {
      box-shadow: none;
    }
  }

  &--01 {
    span {
      background: #fff;
    }
  }

  &--02 {
    span {
      background: #000;
    }
  }

  &--03 {
    span {
      background: #f00;
    }
  }

  &--04 {
    span {
      background: #c0c0c0;
    }
  }

  &--05 {
    span {
      background: #4f32ff;
    }
  }

  &--06 {
    span {
      background-color: green;
    }
  }

  &--07 {
    span {
      background-color: yellow;
    }
  }

  &--08 {
    span {
      background-color: orange;
    }
  }

  &--09 {
    span {
      background-color: purple;
    }
  }

  &--10 {
    span {
      background-color: pink;
    }
  }

  &--11 {
    span {
      background-color: cyan;
    }
  }

  &--12 {
    span {
      background-color: magenta;
    }
  }

  &--13 {
    span {
      background-color: lime;
    }
  }

  &--14 {
    span {
      background-color: teal;
    }
  }

  &--15 {
    span {
      background-color: indigo;
    }
  }

  &--16 {
    span {
      background-color: brown;
    }
  }

  &--17 {
    span {
      background-color: olive;
    }
  }

  &--18 {
    span {
      background-color: maroon;
    }
  }

  &--19 {
    span {
      background-color: turquoise;
    }
  }

  &--20 {
    span {
      background-color: silver;
    }
  }




}

.product-type-list {
  @include flexBox(flex, column nowrap, null, null);

  .type-pair {
    padding: 1rem 1.5rem;
    @include flexBox(flex, null, center, null);
    gap: 0.8rem;
    color: var(--text-dark);

    @media (max-width: 567.98px) {
      padding: 0.8rem;
    }

    span {
      letter-spacing: -0.42px;
    }

    &:hover,
    &.active {
      background-color: #faf4f4;
    }
  }
}

.category-list {
  padding: 0.75rem 1rem;
  @include flexBox(flex, row wrap, center, space-around);
  gap: 0.4rem;

  .check-select-cover {
    position: relative;
    width: 8.0625rem;

    .itemToCheck {
      width: 100%;
      cursor: pointer;
      @include flexBox(flex, column nowrap, center, center);
      gap: 0.3rem;
      height: 7.9375rem;
      padding: 2.25rem 0.2rem 1rem;
      background-image: url(./../images/small-block-top-waves-gradient.svg);
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &:checked {
        &~.itemToCheck {
          background-color: #000;
        }
      }
    }
  }
}

// ckEditor styling //
.ckeditorSection {
  @include flexBox(flex, column nowrap, null, null);
  margin-bottom: 0.35rem;
  max-width: 100%;

  .ck-rounded-corners {
    border-radius: 0.625rem;
    box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);

    .ck {
      &.ck-editor {
        &__top .ck-sticky-panel .ck-toolbar {
          border-width: 2px;
          border-bottom: 0;
          border-color: white;
          border-top-left-radius: 0.625rem;
          border-top-right-radius: 0.625rem;
          background: rgba($color: #fff, $alpha: 0.6);
        }

        &__main {
          border-top: 1px solid #e8e8e8;

          &>.ck-editor__editable {
            min-height: calc(100vh - 29.5rem);
            padding: 0rem 1rem 1rem;
            border: 2px solid #fff;
            border-top: 0;
            box-shadow: none;
            font-weight: 400;
            color: var(--text-dark);
            border-radius: 0 0 0.625rem 0.625rem;
            background: rgba($color: #fff, $alpha: 0.6);

            @media (min-width: 1921px) {
              min-height: 42rem;
            }

            @media (max-width: 1800px) {
              min-height: calc(100vh - 32rem);
            }

            @media (max-width: 1600px) {
              min-height: calc(100vh - 33rem);
            }

            @media (max-width: 1450px),
            (max-height: 760px) {
              min-height: calc(100vh - 24rem);
              // min-height: calc(100vh - 27rem);
            }

            &:focus {
              border-top-color: #dddddd;
              box-shadow: none;
            }
          }
        }

        &__editable.ck-focused:not(.ck-editor__nested-editable) {
          border-color: white;
        }
      }

      .ck-placeholder {
        font-style: italic;
        font-weight: 300;
      }
    }
  }
}

// Preview screen
.preview-image-block {
  padding-top: 0.5rem;
  @include flexBox(flex, null, null, null);
  gap: 2.2rem;

  @media (max-width: 1450px),
  (max-height: 760px) {
    gap: 1.65rem;
  }

  @media (max-width: 991.98px) {
    flex-flow: column nowrap;
  }

  @media (max-width: 567.98px) {
    padding-top: 0;
  }

  .img-wrapper {
    width: 23.6875rem;
    flex: 0 0 23.6875rem;
    border-radius: 0.725rem;
    max-height: 20rem;

    @media (max-width: 1450px) {
      max-height: 18rem;
    }

    @media (max-width: 1450px),
    (max-height: 760px) {
      width: 20rem;
      flex: 0 0 20rem;
    }

    @media (max-width: 991.98px) {
      margin-inline: auto;
    }

    @media (max-width: 767.98px) {
      max-height: 11rem;
    }

    @media (max-width: 567.98px) {
      max-height: 8rem;
    }

    &>img {
      object-fit: cover;
    }

    .btn {
      position: absolute;
      right: 0.45rem;
      top: 0.45rem;
    }
  }

  .preview-fields {
    margin-right: 1rem;
    flex: 1;

    @media (max-width: 1450px),
    (max-height: 760px) {
      margin-right: 0;
    }
  }
}

ul.view-info {
  // @include flexBox(flex, column wrap, null, space-around);
  @include flexBox(flex, column wrap, null, null);
  align-content: space-between;
  gap: 1rem;
  max-height: 21rem;

  @media (max-width: 1450px),
  (max-height: 760px) {
    gap: 0.5rem;
  }

  @media (max-width: 767.98px) {
    max-height: 17rem;
    gap: 0;
  }

  .view-pair {
    @include flexBox(inline-flex, column nowrap, null, null);
    gap: 0.4rem;
    min-width: min(16vw, 12rem);

    @media (max-width: 767.98px) {
      gap: 0.24rem;
    }

    .value {
      letter-spacing: -0.42px;
      max-width: 80%;
      min-height: 2.5rem;

      @media (max-width: 767.98px) {
        min-height: 2.15rem;
        font-size: 12px;
      }
    }
  }
}

ul.preview-tags {
  @include flexBox(flex, row wrap, center, null);
  margin-top: -0.5rem;
  gap: 0.6rem;

  .p-tag {
    background: var(--text-dark);
    color: var(--neutral-light);
    padding-inline: 1.5rem;
    height: 3.25rem;
    @include flexBox(inline-flex, null, center, center);

    position: relative;

    @media (max-height: 760px),
    (max-width: 1450px) {
      height: 3rem;
    }

    span {
      padding-top: 1px;
    }

    &>a {
      position: absolute;
      top: 0.265rem;
      right: 0.265rem;
    }
  }
}

.level-row {
  @include flexBox(flex, row wrap, center, null);
  gap: 1rem;
}

.add-layer-block {
  position: relative;
  z-index: 3;

  &>div {
    border: 0;
    background-image: url(./../images/long-dark-right-waveBg.svg) no-repeat right top;
  }

  .clickBtn {
    @include flexBox(flex, null, center, center);
    position: relative;
    z-index: 1;
    gap: 0.7rem;
    color: var(--text-dark);

    span {
      letter-spacing: -0.02625rem;
    }

    .layer-arrow-long {
      position: absolute;
      right: -0.85rem;
      bottom: 1.75rem;
      transform: rotate(90deg);
    }

    &:hover,
    &:focus,
    &:active {
      .icon-wrap {
        img {
          transform: scale(1.07);
        }
      }
    }
  }
}

.layer-description {
  // border-top-left-radius: 0;
  // border-top-right-radius: 0;
  background-image: none;
  z-index: 2;
  width: 100%;
  top: 102%;

  // &::after {
  //   border-top-left-radius: 0;
  //   border-top-right-radius: 0;
  // }
  ul {
    @include flexBox(flex, column nowrap, null, null);
    position: relative;
    z-index: 2;
    padding: 0.5rem 0;

    .layer-pair {
      @include flexBox(flex, null, center, null);
      padding: 0.75rem 1.5rem 0.5rem;
      color: var(--text-dark);
      @include ts(0.3s);

      @media (max-width: 1450px),
      (max-height: 760px) {
        padding: 0.5rem 1.25rem 0.25rem;
      }

      span {
        letter-spacing: -0.03rem;
      }

      .img-wrap {
        height: 2.5rem;
        width: 2.5rem;
        @include flexBox(inline-flex, null, center, center);

        .num {
          top: -0.15rem;
          width: 1rem;
          height: 1rem;
          font-size: 8px;
          padding-top: 2px;
          letter-spacing: -0.015rem;
          background: var(--pastel-pink);
          @include flexBox(inline-flex, null, center, center);
        }
      }

      &:hover,
      &:focus,
      &:active {
        background-color: white;
      }
    }
  }
}

.icon-wrap {
  width: 3.125rem;
  height: 3.25rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  @include flexBox(inline-flex, null, center, center);

  @media (max-height: 760px),
  (max-width: 1450px) {
    width: 3rem;
    height: 3rem;
  }

  img {
    @include ts(0.3s);
  }
}

.page-desc-content {
  padding: 1.5rem 1.65rem;

  @media (max-width: 767.98px) {
    padding: 1.2rem 1.2rem 1rem;
  }

  @media (max-width: 567.98px) {
    padding: 1rem 1rem 0.8rem;
  }

  &>p,
  &>div>p {
    @include flexBox(flex, row wrap, center, null);
    gap: 0.35rem;

    @media (max-width: 567.98px) {
      max-width: 85%;
    }
  }

  .left-arrow-long {
    transform: rotateY(180deg);
  }
}

// Reports styling //
.page-desc-content-noImage {
  background-image: none;
  @include flexBox(flex, null, center, space-between);
}

// Chart section area
.chart-area {
  margin-top: 1.2rem;
  @include flexBox(flex, null, null, null);
  gap: 0.69rem;

  @media (max-width: 767.98px) {
    flex-flow: row wrap;
  }

  &__bar {
    flex: 1;

    canvas {
      margin-left: -0.8rem;
    }

    @media (max-width: 767.98px) {
      width: 100%;
      flex: 0 0 100%;

      .btn-top-group {
        gap: 0.4rem;
      }
    }
  }

  &__distribution {
    max-width: 34.8125rem;
    flex: 0 0 34.8125rem;

    @media (max-width: 1450px),
    (max-height: 760px) {
      max-width: 32rem;
      flex: 0 0 32rem;
    }

    @media (max-width: 1280px) {
      max-width: 29rem;
      flex: 0 0 29rem;
    }

    @media (max-width: 767.98px) {
      max-width: 100%;
      flex: 0 0 100%;
    }

    @media (max-width: 567.98px) {
      @include flexBox(flex, column nowrap, center, center);
    }
  }

  .top-row {
    gap: 1rem;
    @include flexBox(flex, row wrap, center, space-between);

    @media (max-width: 767.98px) {
      width: 100%;
    }

    h3 {
      letter-spacing: -0.02625rem;
    }
  }
}

.product-stats {
  @include flexBox(flex, null, center, space-between);
  gap: 1rem;
  padding-inline: min(2.5vw, 2.25rem);

  @media (max-width: 767.98px) {
    padding-inline: 0;
  }

  li {
    min-width: 15%;

    &:first-child {
      .stat {
        .num {
          //  padding-left: 0;
        }
      }
    }
  }

  .stat {
    @include flexBox(inline-flex, column nowrap, null, null);

    .line {
      position: absolute;
      width: 0.1875rem;
      height: 87%;
      top: 0.02rem;
      left: 0rem;
    }

    .num {
      line-height: 0.85;
      padding-left: 0.5rem;

      @media (max-width: 767.98px) {
        font-size: 30px;
      }
    }

    .text {
      letter-spacing: -0.0225rem;

      @media (max-width: 767.98px) {
        font-size: 0.75rem;
      }
    }

    &--noLine {
      .line {
        display: none;
      }
    }

    &--purpleLine {
      .line {
        background: #5a4898;
      }
    }

    &--peachLine {
      .line {
        background: #a1aeff;
      }
    }

    &--blueLine {
      .line {
        background: #6E99DA;
      }
    }

    &--blueLinePriority {
      .line {
        background: #1C97F5;
      }
    }

    &--orangeLine {
      .line {
        background: #FEC7A0;
      }
    }

    &--dOrangeLine {
      .line {
        background: #FFA146;
      }
    }

    &--yellowLine {
      .line {
        background: #F4BE37;
      }
    }

    &--greyLine {
      .line {
        background: #0D2535;
      }
    }

    &--greenLine {
      .line {
        background: #A2EDCB;
      }
    }

    &--greenLinePriority {
      .line {
        background: #55A55E;
      }
    }

    &--redLine {
      .line {
        background: #DA1006;
      }
    }
  }
}

.chart-row {
  @include flexBox(flex, row nowrap, center, null);

  canvas {
    margin-left: -0.8rem;

    @media (max-width: 567.98px) {
      margin-left: 0rem;
    }
  }

  @media (max-width: 767.98px) {
    justify-content: space-between;
  }

  @media (max-width: 567.98px) {
    @include flexBox(flex, column nowrap, null, center);
    gap: 1rem;
  }

  .distribution-data {
    @include flexBox(flex, column nowrap, null, null);
    gap: 0.8rem;
    max-width: 12rem;
    flex: 0 0 12rem;

    @media (max-width: 767.98px) {
      margin-bottom: 0;
      max-width: 11rem;
      flex: 0 0 11rem;
    }

    @media (max-width: 567.98px) {
      max-width: unset;
      flex: unset;
      align-items: center;
    }

    &>li {
      @media (max-width: 567.98px) {
        min-width: 75vw;
      }

      @media (max-width: 449.98px) {
        min-width: 85vw;
        width: 95%;
      }

      .gradient-widget {
        background-image: url(./../images/distribution-block-waves-dark.svg);
        background-position: top right;
        padding: 0;

        .inner {
          z-index: 1;
          padding: 1.25rem 0.5rem 1.4rem 1.95rem;
          @include flexBox(flex, column nowrap, null, null);
          gap: min(2vh, 2rem);

          @media (max-width: 767.98px) {
            padding: 1rem 0.5rem 1rem 1.65rem;
          }
        }

        .title {
          .dot {
            width: 0.69163rem;
            height: 0.70313rem;
            border-radius: 50%;
            left: -0.9rem;
            top: 0.125rem;

            &--green {
              background: linear-gradient(180deg, #cbeadb 0%, #378f69 100%);
            }

            &--magenta {
              background: linear-gradient(180deg, #cc2e78 0%, #55009d 100%);
            }
          }

          h3 {
            img {
              display: inline-block;
            }
          }
        }
      }
    }

    .direction-stat {
      @include flexBox(flex, column nowrap, null, null);
      gap: 1.69rem 5%;

      @media (max-width: 567.98px) {
        @include flexBox(flex, row wrap, null, null);
        gap: 1rem 5%;
      }

      .pair {
        @include flexBox(inline-flex, null, center, null);
        gap: 0.625rem;

        @media (max-width: 567.98px) {
          max-width: calc(50% - 0.625rem);
          flex: 0 0 calc(50% - 0.625rem);
        }

        .abbr-box {
          padding-top: 3px;
          width: 1.5625rem;
          height: 1.5625rem;
          flex-shrink: 0;
          @include flexBox(inline-flex, null, center, center);
          border-radius: 0.25rem;
          background-color: var(--text-dark);
        }

        .text {
          letter-spacing: 0.02rem;
        }
      }
    }
  }
}

.listing-cards {

  .top-row,
  .middle-row {
    @include flexBox(flex, row wrap, center, space-between);

    h3 {
      letter-spacing: -0.02625rem;
    }

    .list-pair {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.4rem;
      color: var(--text-dark);

      .bar {
        width: 0.5rem;
        height: 100%;
        margin-top: -1px;
        background: url(./../images/wave-in-bar.svg) no-repeat;
        background-color: var(--text-dark);
        height: 2.8125rem;
        background-position: 0% 10%;
        opacity: 0;
        transform: scale(0);
        transform-origin: 50% 50%;
        @include ts(0.3s);
      }

      .num {
        line-height: 0.85;

        @media (max-width: 767.98px) {
          font-size: 30px;
        }
      }

      span {
        letter-spacing: -0.0225rem;
      }

      &.line-active {
        .bar {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  .block-widget {

    &--left,
    &--right {
      padding: 1rem;
    }
  }

  &.m-listing-cards {
    @media (max-width: 567.98px) {
      .top-row {
        position: relative;
        padding-top: 3.85rem;

        h3 {
          position: absolute;
          left: 0;
          top: 0.9rem;
        }

        .btn-top-group {
          position: absolute;
          right: 0;
          top: 0;
        }

        .list-pair {
          min-width: 4.5rem;
        }
      }
    }
  }
}

.m-page-desc,
.page-desc.m-page-desc {
  @media (max-width: 991.98px) {
    background-image: url(./../images/m-waves01-tracking-dashboard.svg);
    background-position: bottom right;
  }
}

// Dashboard styling //
.dashboard-screen {
  .page-desc-content {
    @include flexBox(flex, column nowrap, null, null);
    gap: 0.25rem;
    margin-bottom: 1rem;

    .title {
      gap: 0.5rem;
      @include flexBox(inline-flex, null, center, null);

      @media (max-width: 567.98px) {
        font-size: 1rem;
      }
    }
  }
}

.dashboard-top-area {
  @include flexBox(flex, null, null, null);
  gap: 0.69rem;
  padding-bottom: 1.5rem;

  @media (min-width: 1600px) {
    gap: 1.2rem;
  }

  @media (max-width: 767.98px) {
    flex-flow: column nowrap;
  }

  @media (max-width: 567.98px) {
    padding-bottom: 1.125rem;
  }

  &__left {
    flex: 1;
    @include flexBox(flex, column nowrap, null, null);

    canvas {
      margin-left: -0.8rem;
    }

    .page-desc-content {
      background-image: url(./../images/wavy-spiral-dark.svg);

      @media (max-width: 1199.98px) {
        background-image: url(./../images/m-waves01-tracking-dashboard.svg);
        background-position: bottom right;
        padding: 0.75rem 0.8rem !important;
      }
    }

    @media (max-width: 567.98px) {
      .btn-top-group {
        gap: 0.5rem;
      }
    }
  }

  &__right {
    max-width: 34.8125rem;
    flex: 0 0 34.8125rem;

    @media (min-width: 1600px) {
      max-width: min(40%, 38rem);
      flex: 0 0 min(40%, 38rem);
    }

    @media (max-width: 1450px),
    (max-height: 760px) {
      max-width: 32rem;
      flex: 0 0 32rem;
    }

    @media (max-width: 1280px) {
      max-width: 29rem;
      flex: 0 0 29rem;
    }

    @media (max-width: 767.98px) {
      max-width: 100%;
      flex: 1;
    }

    @media (max-width: 567.98px) {
      padding: 0.75rem;

      .btn-top-group {
        gap: 0.5rem;
      }
    }
  }

  .top-row {
    gap: 1rem;
    @include flexBox(flex, row wrap, center, space-between);

    h3 {
      letter-spacing: -0.02625rem;
    }
  }

  .middle-row {
    margin-top: 0.25rem;
    padding-right: 7rem;

    @media (max-width: 567.98px) {
      padding-right: 5rem;
    }
  }

  .detail-cards {
    max-height: min(100vh, 44rem);

    @media (max-height: 760px),
    (max-width: 1450px) {
      max-height: min(110vh, 44rem);
    }

    @media (max-width: 767.98px) {
      max-height: 100%;
    }

    .card__barcode {
      .bar_code {
        padding: 0.6rem 0.8rem 0.6rem;
        margin-top: 7px;

        @media (max-width: 567.98px) {
          padding: 0.5rem 0.45rem 0.65rem;

          .font-secondary {
            font-size: 12.5px;
          }
        }
      }
    }
  }

  .noData-detail-cards {
    height: min(100vh, 44rem);
  }

  .noData-chart_bar {
    img {
      margin: -0.5rem -2rem 1rem;
      max-width: unset;
      width: 104%;
    }
  }
}

.detail-cards {
  &--warranty {
    max-height: 400px;
    overflow-y: auto;

    li {
      background-color: #fff;
      border-radius: 0.625rem;

      // &::after{
      //   position: absolute;
      //   content: '';
      //   width: 7px;
      //   height: 12px;
      //   background:url('../images/six-dots.svg');
      //   top: 20px;
      //   right: 20px;
      // }

      .card__text .text-pair .label {
        max-width: 7.2rem;
        flex: 0 0 7.2rem;
      }

      .card-inner {
        position: relative;

        .btn-fab {
          position: absolute;
          right: 0;
          top: 0;

          &--transparent {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }

  &--support {
    .card__text .text-pair .label {
      max-width: 5.2rem;
      flex: 0 0 5.2rem;
    }

    .card-inner {
      .btn-fab--dark {
        position: absolute;
        right: 0;
        top: 0;

        img {
          width: 100%;
          max-width: unset;
        }

        &:hover,
        &:focus,
        &:active {
          img {
            transform: scale(1);
          }
        }
      }

      .card__text {
        p {
          margin-top: -0.5rem;
        }
      }
    }
  }
}

.dashboard-evolution {
  width: 100%;
  // max-width: 1450px;
  max-width: 1600px;
  margin-inline: auto;
  position: relative;
  padding-block: 1rem 8.5rem;

  @media (max-width: 1700px) {
    max-width: 95%;
  }

  @media (max-width: 1450px),
  (max-height: 760px) {
    padding-bottom: 6.65rem;
  }

  @media (max-width: 767.98px) {
    max-width: 97%;
    padding-top: 0;
  }

  @media (max-width: 567.98px) {
    padding-bottom: 6rem;
  }

  &>div {
    padding: 3.8rem 1rem 2.5rem;
    background-color: rgba($color: #fff, $alpha: 0.38);
    @include flexBox(flex, column nowrap, null, center);
    gap: min(1.2dvh, 1rem);
    min-height: 80dvh;

    @media (min-width: 2200px) {
      min-height: 70dvh;
    }

    @media (max-width: 1700px) {
      gap: 0;
    }

    @media (max-width: 767.98px) {
      padding: 2rem 1rem;
      position: relative;
    }

    &>h2 {
      line-height: 1.35;
      letter-spacing: -1.8px;
      margin-bottom: min(3vh, 1.8rem);

      @media (max-width: 767.98px) {
        font-size: 2rem;
        letter-spacing: -0.65px;
        text-transform: capitalize;
      }

      @media (max-width: 567.98px) {
        letter-spacing: -0.0375rem;
        font-size: 1.5rem;
      }

      @media (max-width: 449.98px) {
        font-size: 1.25rem;
      }
    }

    &>p {
      margin-inline: auto;
      margin-top: min(2.2vh, 1.25rem);
      max-width: min(48%, 48rem);

      @media (max-width: 767.98px) {
        font-size: 12px;
        min-width: 60%;
        max-width: 80%;
        position: absolute;
        top: 21.2rem;
        margin: auto;
        left: 50%;
        transform: translateX(-50%);
      }

      @media (max-width: 567.98px) {
        min-width: 75%;
      }

      @media (max-width: 449.98px) {
        min-width: 88%;
        max-width: 100%;
        top: 18.35rem;
      }
    }
  }

  .ev-svg-cover {
    // margin-top: -5.15rem;

    @media (max-height: 760px),
    (max-width: 1450px) {
      margin-top: -3rem;
    }

    @media (max-width: 767.98px) {
      margin-top: 0;
    }

    svg,
    img {
      margin-inline: auto;
      max-width: min(95%, 70rem);
      width: 100%;

      @media (max-width: 1700px) {
        max-width: min(85%, 60rem);
      }

      @media (max-height: 760px),
      (max-width: 1450px) {
        max-width: min(60%, 60rem);
      }

      @media (max-width: 767.98px) {
        max-width: 70%;
      }

      @media (max-width: 567.98px) {
        max-width: 90%;
      }

      @media (max-width: 449.98px) {
        max-width: 100%;
      }
    }
  }
}

.full-width-wrapper {
  margin-top: 0.8rem;
  // height: 100vh;
  // overflow-y: auto;
  background-color: var(--text-dark);
  position: relative;
  z-index: 1;

  .wave-top {
    // position: absolute;
    // top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: url(./../images/page-header-bg.svg) no-repeat left top;
    background-size: 38%;
  }
}

.dashboard-anim {
  min-height: 95dvh;
  overflow: hidden;

  @media (min-width: 2200px) {
    min-height: 85dvh;
  }

  @media (max-height: 760px),
  (max-width: 1450px) {
    min-height: 100dvh;
  }

  &__inner {
    width: 100%;
    max-width: 1600px;
    margin-inline: auto;
    color: white;
    position: relative;
    padding: 2rem 2.5rem;
    z-index: 4;
    // padding: 2rem min(8vw, 7rem) 10vh;
    @include flexBox(flex, column nowrap, null, center);

    @media (max-width: 1700px) {
      max-width: 95%;
    }
  }

  &--greenBg {
    .dashboard-anim__inner {
      .anim-image {
        top: 40%;
        right: 5vw;

        @media (min-width: 2200px) {
          top: 43.5%;
        }

        @media (max-height: 760px),
        (max-width: 1450px) {
          top: 42.5%;
          right: 8vw;
        }

        img,
        svg {
          width: 94%;

          @media (max-width: 1700px) {
            max-height: 80dvh;
          }
        }
      }
    }
  }

  &--blueBg {
    .dashboard-anim__inner {
      .anim-image {
        top: 45%;

        img,
        svg {
          width: 94%;
        }

        @media (min-width: 2200px) {
          top: 46.5%;

          img,
          svg {
            width: 100%;
          }
        }

        @media (max-width: 1700px) {
          top: 42%;

          img,
          svg {
            width: 88%;
          }
        }

        @media (max-height: 760px),
        (max-width: 1450px) {
          top: 42%;

          img,
          svg {
            width: 100%;
          }
        }
      }
    }
  }

  .anim-image {
    transform: translateY(-50%);
    top: 42.5%;
    right: 0;

    @media (min-width: 2200px) {
      top: 48.5%;
    }

    @media (max-width: 1700px) {
      top: 40%;
    }

    @media (max-height: 760px),
    (max-width: 1450px) {
      top: 42.5%;
    }

    img,
    svg {
      margin-left: auto;
      width: 90%;
      max-height: 90dvh;

      @media (min-width: 2200px) {
        width: 100%;
        max-height: 100dvh;
      }

      @media (max-height: 760px),
      (max-width: 1450px) {
        max-width: 100%;
        max-height: 80dvh;
      }

      @media (max-width: 1280px) {
        max-width: 74%;
      }
    }
  }

  h2 {
    line-height: 1.15;
    letter-spacing: -2.1px;
    margin-bottom: min(1.2vh, 0.8rem);

    span {
      letter-spacing: -1.8px;
    }
  }

  p {
    margin-bottom: 0;
    min-height: 8rem;
    max-width: min(38.5vw, 43rem);
    padding-bottom: min(3.5vh, 2.25rem);

    @media (max-height: 760px),
    (max-width: 1450px) {
      min-height: 7rem;
      padding-bottom: 0.5rem;
    }
  }

  ul {
    @include flexBox(flex, column nowrap, null, null);
    gap: 1.1rem;
    padding-left: 1.5rem;
    margin-bottom: min(3vh, 1.8rem);

    .pointer {
      @include flexBox(flex, null, center, null);
      gap: 0.6rem;

      .img-icon {
        width: 1.375rem;
        height: 1.375rem;
        flex-shrink: 0;
        border-radius: 10px 0 10px 0;
        background: url(./../images/icons/icon-li-leaf-dark.svg) no-repeat top right;
        background-color: var(--white);

        @media (max-height: 760px),
        (max-width: 1450px) {
          width: 1.25rem;
          height: 1.25rem;
          border-radius: 0.575rem 0 0.575rem 0;
        }
      }
    }
  }

  .dark-cta-row {
    @include flexBox(inline-flex, null, center, null);
    padding-left: 1.5rem;

    .btn-cta--outlined {
      min-width: 9.25rem;
      height: 2.8125rem;

      &>div {
        background-color: white;
        min-width: 9.15rem;
        height: 100%;
        padding-top: 1px;
      }

      &:hover,
      &:focus,
      &:active {
        color: var(--text-dark);
      }
    }
  }

  &--purpleBg {

    // background: linear-gradient(
    //   250deg,
    //   #e0cff9 20%,
    //   rgba(255, 255, 255, 0) 100%
    // );
    &::after {
      // opacity: 0.21;
    }
  }

  &--greenBg {

    // background: linear-gradient(
    //   250deg,
    //   #d2f2db 0%,
    //   rgba(255, 255, 255, 0) 100%
    // );
    &::after {
      // opacity: 0.22;
    }
  }

  &--blueBg {

    // background: linear-gradient(
    //   250deg,
    //   #d0e1ee 0%,
    //   rgba(255, 255, 255, 0) 100%
    // );
    &::after {
      // opacity: 0.22;
    }
  }
}

.m-section {
  .m-dashboard-anim {
    overflow: hidden;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    &--purpleBg {
      background: linear-gradient(250deg, #f8edf4 10%, rgba(255, 255, 255, 0) 100%);
    }

    &--greenBg {
      background: linear-gradient(250deg, #f3fcf5 10%, rgba(255, 255, 255, 0) 100%);

      .anim-image {
        bottom: 2rem;
      }
    }

    &--blueBg {
      background: linear-gradient(250deg, #d0e1ee 0%, rgba(255, 255, 255, 0) 100%);

      &::after {
        opacity: 0.22;
      }

      .anim-image {
        bottom: 1.32rem;
      }
    }

    &__inner {
      padding: 3.6rem 1rem 3.25rem;
      @include flexBox(flex, null, center, space-between);
      background: url(./../images/m-waves-dashboard-anim.svg) no-repeat top left;

      @media (max-width: 375px) {
        padding: 3rem 0.85rem 2.5rem;
      }

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: url(./../images/m-waves02-dashboard-anim.svg) no-repeat bottom right;
      }
    }

    ul {
      @include flexBox(flex, column nowrap, null, null);
      gap: 0.5rem;
      max-width: 85%;
      margin-bottom: min(3vh, 1.8rem);

      .pointer {
        @include flexBox(flex, null, null, null);
        gap: 0.15rem;
        line-height: 1.2;

        .img-icon {
          margin-top: 2px;
          width: 0.5725rem;
          height: 0.57719rem;
          flex-shrink: 0;
          border-radius: 4px 0 4px 0;
          background: url(./../images/icons/icon-li-leaf.svg) no-repeat top right;
          background-color: var(--text-dark);
          background-size: 10px;
        }
      }
    }

    .anim-image {
      right: 0;
      max-width: 40vw;

      @media (max-width: 375px) {
        max-width: 36.5vw;
      }
    }

    .content-wrap {
      max-width: 70%;
      flex: 0 0 70%;

      .btn-cta--outlined {
        font-size: 12px;

        &>div {
          height: 2.1875rem;
          border-radius: 0.375rem;
        }
      }
    }
  }
}

.detail-cards {
  margin: 0 -0.85rem -0.85rem;
  padding: 0 0.85rem 0.85rem;
  margin-top: 1rem;
  max-height: min(100vh, 50rem);
  overflow-y: auto;
  width: 100%;
  @include flexBox(flex, column nowrap, null, null);
  gap: 0.56rem;
  // Hide scrollbar
  // For mozilla firefox
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  @media (max-width: 767.98px) {
    max-height: unset;
  }

  .card {
    .card-inner {
      padding: 0.65rem 0.75rem;
      position: relative;
      // min-height: 11rem;
      z-index: 1;

      @include flexBox(flex, null, null, space-between);
      gap: min(1vmin, 1rem);

      @media (max-width: 567.98px) {
        padding: 0.5rem;
        position: relative;
      }
    }

    &__text {
      @include flexBox(inline-flex, column nowrap, null, center);
      gap: 0.75rem;

      @media (max-width: 567.98px) {
        gap: 0.5rem;
      }

      .text-pair {
        @include flexBox(inline-flex, null, center, null);
        line-height: 1.35;
        gap: 0.35rem;

        @media (max-width: 567.98px) {
          gap: 0;
          @include flexBox(inline-flex, column nowrap, flex-start, null);

          &:has(.brnd_btn_black) {
            display: none;
          }
        }

        .label {
          max-width: 5.2rem;
          flex: 0 0 5.2rem;

          @media (max-width: 567.98px) {
            max-width: unset;
            flex: unset;
          }
        }

        .value {
          @include flexBox(inline-flex, null, center, null);
          white-space: nowrap;
          gap: 0.6rem;

          @media (max-width: 567.98px) {
            font-size: 10px;

            &:has(.btn-solid--dark) {
              .btn-solid--dark {
                position: absolute;
                right: 0.5rem;
                bottom: 0.4rem;
              }
            }
          }

          .brnd_btn_black {
            @include flexBox(inline-flex, null, center, center);
          }

          .trusted_sheild {
            margin-left: 0;
          }

          .td-status {
            min-width: 3rem;
            height: 1.5rem;

            &::after {
              border-radius: 0.4375rem;
            }

            span {
              border-radius: 0.3125rem;

              &::after {
                border-radius: 0.3125rem;
              }
            }
          }
        }
      }
    }

    &__activate {
      padding-top: 2rem;
      max-width: 8rem;
      flex: 0 0 8rem;
      background-image: url(./../images/small-block-top-waves-gradient.svg);
      padding-inline: 10px 5px;
      @include flexBox(flex, column nowrap, null, center);
      min-height: 7.75rem;

      @media (max-width: 567.98px) {
        max-width: 6rem;
        flex: 0 0 6rem;
        min-height: unset;
        text-align: center;
        padding-top: 1.75rem;
        background-position: 58% -20%;
        background-size: 120%;

        h2,
        a {
          font-size: 10px;
        }
      }

      a {
        padding: 0.35rem 0;
      }
    }

    &__barcode,
    &__rfid {
      @include flexBox(inline-flex, null, flex-start, flex-end);
      max-width: 14rem;
      flex: 0 0 14rem;
      gap: 0.5rem;

      @media (max-width: 767.98px) {
        max-width: unset;
        flex: 0 0 8rem;
      }

      @media (max-width: 567.98px) {
        flex: 0 0 6rem;
      }

      .bar-qr-cover {
        @include flexBox(flex, column nowrap, null, null);
        gap: 0.35rem;
        height: 100%;
      }

      .bar_code {
        padding: 0.45rem 0.45rem 0.5rem;
        background: url(./../images/barcode-rastered-bg.svg) no-repeat center center;
        min-height: 8.5rem;
        @include flexBox(flex, column nowrap, center, center);

        @media (max-width: 567.98px) {
          min-height: unset;
          padding: 0.5rem 0.45rem 0.65rem;

          .font-secondary {
            font-size: 12.5px;
          }
        }

        &>div {
          max-height: 80%;

          img {
            margin-inline: auto;
            max-width: 98%;
          }
        }

        span {
          margin-bottom: -0.55rem;
          letter-spacing: 0.1125rem;
          margin-top: 7px;
        }
      }
    }

    &__rfid {
      align-items: center;

      @media (max-width: 567.98px) {
        align-items: flex-start;
      }

      .rfid {
        padding: 0;
        margin: 0;
        background: var(--text-dark);
        min-height: 8.5rem;

        @media (max-width: 567.98px) {
          min-height: unset;
        }

        &>div {
          img {
            margin-inline: auto;
          }
        }
      }
    }
  }
}

.showAll {
  a.btn {
    padding: 0;
    width: 2.6875rem;
    height: 2.6875rem;

    img {
      max-width: 55%;
    }
  }
}

.noData-detail-cards,
.noData-chart_bar {
  min-height: 24rem;
  padding: 1.25rem 1.25rem 1rem;
  @include flexBox(flex, column nowrap, center, center);
  gap: 2.125rem;

  h2 {
    line-height: 1.3;
    letter-spacing: -0.04688rem;
  }
}

.noData-chart_bar {
  min-height: unset;
  padding: 1rem 1.25rem 0;
  gap: 0;

  h2 {
    margin-bottom: -2.5rem;
  }
}

.level-tag {
  height: 1.5rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background-color: var(--text-dark);
  box-shadow: 3px 5px 12px 0px #c0c0c0;
  @include flexBox(flex, null, center, center);
}

.activity-report-widget {
  height: 100%;
  @include flexBox(flex, column nowrap, null, null);
  gap: 0.85rem;

  .top-row {
    gap: 1rem;
    @include flexBox(flex, row wrap, center, space-between);

    h3 {
      letter-spacing: -0.02625rem;
    }
  }

  .plain-card {
    gap: 0.125rem;
    padding: 0.75rem 0.65rem 0.65rem;
    @include flexBox(inline-flex, column nowrap, null, null);
    background-color: rgba($color: #fff, $alpha: 0.6);

    .dotted,
    .small-tag {
      height: 1.6rem;
      border-radius: 7px;
      padding-inline: 9px;
      border: 1px dashed var(--text-dark);
      @include flexBox(inline-flex, null, center, center);
    }

    .small-tag {
      border: 0;
      padding-top: 1px;
      margin-top: 0.25rem;
    }

    .blue-tag {
      background: var(--pastel-blue);
    }

    .pink-tag {
      background: var(--pastel-pink);
    }

    .last-row {
      letter-spacing: -0.42px;
      @include flexBox(flex, null, center, space-between);
      gap: 1rem;

      .left {
        letter-spacing: -0.42px;
      }
    }
  }

  .dark-card {
    min-height: 6.55rem;
    background-image: url(./../images/spiral2.svg);
    @include flexBox(flex, column nowrap, null, center);

    @media (max-width: 567.98px) {
      min-height: 4.5rem;
      gap: 0.35rem;
      padding: 0.8rem 1.25rem 0.6rem;
      background-position: 35vw 0%;
      @include flexBox(flex, column-reverse nowrap, null, null);

      .text-uppercase {
        font-size: 10px;
        font-weight: 400;
        -webkit-text-fill-color: white;
      }

      .title h3 {
        font-size: 14px;
      }
    }

    p {
      padding-right: 13rem;
      margin-bottom: 0.25rem;
    }

    &__left {
      background-image: url(./../images/spiral2__left.svg);
      background-position: bottom right;
      justify-content: space-between;

      p {
        padding-right: 10rem;
      }
    }

    .title {
      @include flexBox(flex, null, center, null);
      gap: 0.5rem;
      margin-bottom: 0.4rem;

      .fz24 {
        letter-spacing: -0.045rem;
      }
    }

  }
}

.other-add-links {
  width: 100%;
  height: 100%;
  padding: 1rem 0 0;
  @include flexBox(flex, null, center, space-between);
  gap: 1rem;

  @media (max-width: 767.98px) {
    flex-flow: row wrap;
    gap: 0.938rem;

    &>div:first-child,
    &>a {
      min-width: calc(50% - 0.55rem);
    }
  }

  @media (max-width: 567.98px) {
    padding-top: 0.85rem;
  }

  .add-layer-block {
    @media (max-width: 567.98px) {
      p {
        display: none;
      }
    }
  }

  &__left {
    @include flexBox(inline-flex, column nowrap, null, null);
    flex: 1;
    max-width: 14.875rem;
    min-height: 13rem;

    &.listing-block {
      min-height: unset;
    }

    .clickBtn {
      padding: 1rem 1.5rem 1rem 2.2rem;
      @include flexBox(flex, column nowrap, flex-start, center);
      // background-image: url(./../images/waves3-dark.svg);
      background-image: url(./../images/waves4-dark.svg);
      background-position: left bottom;

      @media (max-height: 760px),
      (max-width: 1450px) {
        padding: 0.5rem 1.5rem 1rem 1.75rem;
      }

      @media (max-width: 767.98px) {
        min-height: 7.85rem;
        padding-right: 1rem;
        background-position: 0 50%;
        background-size: 50%;
      }

      @media (max-width: 567.98px) {
        padding: 0.5rem 1rem;
        background-size: 9rem;
        background-position: 0 200%;
      }

      &::after {
        z-index: -2;
      }

      &>div {
        gap: 0.65rem;
        @include flexBox(inline-flex, null, center, flex-start);
      }
    }
  }

  &__middle {
    max-width: 14.875rem;
    flex: 0 0 32%;

    @media (max-height: 760px),
    (max-width: 1450px) {
      flex: 0 0 31%;
    }

    &>div {
      padding: 1rem 0.65rem 1rem 1.85rem;
      background: url(./../images/waves.svg) no-repeat top right;
      background-color: var(--text-dark);
      @include flexBox(flex, column nowrap, null, center);
      gap: 0.5rem;

      @media (max-width: 767.98px) {
        min-height: 7.5rem;
        padding: 2.12rem 1.2rem 2rem 1.5rem;
        background-image: url(./../images/small-block-top-waves-gradient.svg);
      }

      @media (max-width: 567.98px) {
        min-height: 7.75rem;
        padding: 2.2rem 1.2rem 2rem 1rem;

        p {
          display: none;
        }

        h3 {
          font-size: 0.75rem;
        }
      }

      .top {
        gap: 0.25rem;
        letter-spacing: -0.51px;
        @include flexBox(flex, row wrap, center, null);
      }
    }
  }

  &__right {
    @include flexBox(flex, column nowrap, null, center);
    position: relative;
    min-width: 40%;
    flex: 0 0 40%;
    height: 100%;
    padding-left: 0.5rem;

    p {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.65rem;
      width: 100%;
      margin-block: 0.35rem 3rem;
    }

    @media (max-width: 1450px),
    (max-height: 760px) {
      min-width: 35%;
      flex: 0 0 35%;
    }

    @media (max-width: 767.98px) {
      min-height: 9.8rem;
      flex: 0 0 90%;

      h2,
      h4,
      p {
        width: 35vw;
        margin-inline: auto;
      }
    }

    @media (max-width: 567.98px) {

      // min-height: 9rem;
      h2,
      h4,
      p {
        width: 55vw;
      }
    }

    @media (max-width: 449.98px) {

      h2,
      h4,
      p {
        margin-left: 0;
        padding-left: 0.5rem;
      }
    }

    .computer-img {
      position: absolute;
      right: 0.85rem;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width: 1450px),
      (max-height: 760px) {
        right: 0;
        max-width: 82%;
      }

      @media (max-width: 767.98px) {
        max-width: 37.5dvw;
        right: calc(100% - 80dvw);
      }

      @media (max-width: 567.98px) {
        max-width: 45dvw;
        right: calc(100% - 85dvw);
      }

      @media (max-width: 449.98px) {
        max-width: 58dvw;
      }
    }
  }
}

.listing-block {
  @include flexBox(flex, row wrap, null, space-between);
  gap: 0.5rem;
  width: 100%;
  max-width: 100%;

  li {
    flex: 0 0 32%;
    max-width: 210px;

    @media (max-width: 567.98px) {
      flex: 0 0 calc(33.33% - 0.5rem);
    }
  }

  .clickBtn {
    z-index: 2;
    width: 100%;
    min-height: 12rem;
    box-shadow: none;
    color: var(--text-dark);
    padding: 0.5rem 1.2rem 1.5rem 1.75rem;

    @media (max-width: 567.98px) {
      min-height: 8rem;
      padding: 0.5rem 1rem 1rem;
      text-align: center;

      &>div {
        margin-inline: auto;
      }
    }

    .fw500 {
      margin-top: 2px;
      letter-spacing: -0.02625rem;

      @media (max-width: 567.98px) {
        font-size: 12px;
      }
    }

    p {
      color: #656565;
    }

    img {
      position: absolute;
      right: 0.65rem;
      bottom: 0.65rem;
    }

    &::after {
      z-index: 1;
    }
  }
}

// No data found styling
.noData-detail-cards,
.noData-chart_bar,
.noDataFound {
  min-height: 24rem;
  padding: 1.25rem 1.25rem 1rem;
  @include flexBox(flex, column nowrap, center, center);
  gap: 2.125rem;

  h2 {
    line-height: 8.0;
    letter-spacing: -0.04688rem;
  }
}

.noData-chart_bar {
  min-height: unset;
  padding: 1rem 1.25rem 0;
  gap: 0;

  h2 {
    margin-bottom: -2.5rem;
  }
}

// ========================

// RC missing styles
.success-card {
  z-index: 1;

  .bar_code {
    max-width: 45%;
    max-height: 90%;
    margin-right: 1rem;
    border-radius: 7px;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .sec {
    gap: 0.5rem
  }
}

.content--successCSV {
  @include flexBox(flex, column nowrap, center, center);
  min-height: calc(100vh - 10rem);
  padding-bottom: 9vh;

  .success_bg {
    position: static;
    margin-top: -5rem;

    @media (max-height: 760px),
    (max-width: 1450px) {
      margin-top: -8rem;
    }
  }
}

.gradient-widget1 {
  .left-sec .upload_card {
    padding: 0;
    display: flex;

    .outerText {
      justify-content: center;
      margin: auto;
    }
  }
}

// .product-screen__graphics {
//   .let_arrow {
//     @media (max-width: 1600px) {
//       top: 2.8rem;
//       left: 3rem;
//     }
//     @media (max-width: 1200px) {
//       top: 1.6rem;
//       left: 1.4rem;
//     }
//   }
// }

.chart-section--products {
  .noData-detail-cards {
    gap: 0;
    padding: 0;
    min-height: unset;
    height: 81%;

    img {
      margin-top: -2rem;
    }
  }

  .block-wavy-add-product {
    padding-left: 1.2rem;
  }

  @media (max-width: 767.98px) {
    .description-box {
      height: auto;
    }

    .category-group {
      height: 3rem;
      border-radius: 0.6rem;
      @include ts(0.3s);

      .icon-wrap {
        width: 3rem;
        height: 3rem;
        margin-left: -2px;
        @include ts(0.3s);
        border-radius: 0.5rem;
      }

      .category-head {
        font-size: 12px;
      }

      .category-number {
        padding-top: 1.5px;
      }
    }
  }

  @media (max-width: 567.98px) {
    .block-wavy-add-product {
      overflow: hidden;
      padding-left: 1rem;
      padding-top: 0.5rem;
      min-height: 10rem;
      background-position: -35px -17px;
    }

    .right-product {
      max-width: 65vw;
    }
  }

  @media (max-width: 449.98px) {
    .left-product {
      max-width: 180px;

      h3 {
        font-size: 12px;
      }

      p {
        font-size: 10px;
      }
    }

    .category-group {
      height: 2.5rem;
      border-radius: 0.45rem;

      .icon-wrap {
        width: 2.5rem;
        height: 2.45rem;
        // box-shadow: none;
        border-radius: 0.4rem;
      }
    }
  }
}

.form-nav {
  position: relative;
  z-index: 2;
}

.loader-screen {
  min-height: 85dvh;
  max-width: 42rem;
  margin-inline: auto;
  @include flexBox(flex, column nowrap, center, center);
  gap: 2rem;
  padding-bottom: 18dvh;

  @media (max-height: 760px),
  (max-width: 1450px) {
    max-width: 36rem;
    gap: 1.2rem;
  }

  .form-nav {
    @include flexBox(flex, null, center, null);
    min-height: 6.0625rem;

    h3 {
      span {
        padding-top: 1px;
      }
    }

    @media (max-height: 760px),
    (max-width: 1450px) {
      min-height: 5rem;
    }
  }

  .loader {
    @include flexBox(flex, null, center, center);
    position: relative;
    z-index: 2;
  }

  &--internal {
    min-height: 65dvh;
  }
}

.contact-para {
  display: flex;
  gap: 0.25rem;
}

// top dark header block
.header-block {
  overflow: hidden;
  max-width: 100%;
  position: relative;
  background-image: url(./../images/waves6-gradient-small.svg);
  padding: 0;

  @media (max-width: 767.98px) {
    margin: 0 !important;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: url(./../images/waves5-gradient-small.svg) no-repeat bottom left;
  }

  .a-content {
    @include flexBox(flex, null, center, center);
    gap: 0.35rem;

    h2 {
      padding-bottom: 0.35rem;
    }
  }

  &__inner {
    padding: min(4vh, 3rem);

    @media (max-width: 767.98px) {
      padding: 1.2rem 1rem 2rem;

      .a-content {
        img {
          max-width: 3rem;
        }
      }

      .right-content {
        margin-top: 0.8rem;
        margin-bottom: -0.8rem;
      }
    }

    &>img {
      position: absolute;
      right: 1.88rem;
      bottom: 1.15rem;
    }
  }
}

.block-widget--activities {
  overflow-y: auto;
  height: calc(100% - 7rem);
}

// Modal styling
.modal {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(7px);
}

@keyframes animateGradientBorder {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 1000% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.add_product_modal {
  .holograf-form {
    padding: 1.5rem;
    gap: 1rem;

    .form-group {
      @include flexBox(flex, null, center, space-between);

      .label {
        margin-bottom: 0;
        flex-basis: 21rem;
        white-space: nowrap;
        letter-spacing: -0.02625rem;
      }

      .form-control {
        border: 2px solid #EFECEC;
        background: rgba(255, 255, 255, 0.60);
      }
    }
  }

  .add-product-modal-content {
    .cta-row .btn {
      height: 2.8125rem;
    }

    &--inventory {
      .cta-row .btn {
        border-radius: 0.6rem;
        height: 2.8125rem;
      }
    }
  }

  .modal-divider {
    opacity: 1;
    border-color: var(--text-dark);
  }

  .modal-footer {
    .left-footer--inventory {
      @include flexBox(flex, column nowrap, flex-start, null);
    }

    .modal-footer-dark--prView {
      .cta-row {
        gap: 0.4rem;

        .btn {
          border-radius: 0.6rem;
          height: 2.8125rem;
          min-width: 8.5rem;
        }
      }
    }
  }
}

.m-modal {
  backdrop-filter: blur(2px);
  background: rgba(34, 34, 34, 0.8);

  @media (max-width: 767.98px) {
    .modal-content {
      background-color: transparent;
    }

    .modal_wrapper {
      border: 0;
      box-shadow: none;
      position: relative;
      max-width: 90%;
      margin: auto;

      &::after {
        content: '';
        position: absolute;
        inset: -3px;
        // inset: 0;
        border-radius: 0.75rem;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        background: var(--holo-grad);
        background-size: 300%;
        opacity: 1;
        animation: animateGradientBorder 100s linear infinite;
        border: 11px dashed rgba($color: #000, $alpha: 0.925);
      }

      h3 {
        font-size: 2.5rem;
      }

      p {
        font-size: 2rem;
      }

      .btn-row {
        gap: 10px;
        margin-top: 1.25rem;

        .btn {
          width: 2.6875rem;
          height: 2.6875rem;
          border-radius: 0;
          background-color: transparent;

          span {
            display: none;
          }

          &.black-default {
            background: url(./../images/btn-no.svg) no-repeat center;
            background-color: transparent;
          }

          &.modal-confirm-btn {
            background: url(./../images/btn-yes.svg) no-repeat center;
            background-color: transparent;
          }
        }
      }

      .main-block {
        border-radius: 10px;
        background-image: url(./../images/m-modal_waves.svg);
        background-color: rgba($color: #000, $alpha: 0.925);

        .inner-text {
          color: white;
        }

        .content-inner {
          margin: 0;

          &>img {
            display: none;
          }
        }
      }
    }

    .modal-dialog {
      min-width: unset;
    }
  }

  @media (max-width: 567.98px) {
    .modal_wrapper {
      max-width: 88%;

      .btn-row {
        margin-bottom: 0.8rem;
      }
    }
  }

  @media (max-width: 449.98px) {
    .modal_wrapper {
      max-width: 95%;
    }
  }
}

.custom-widget-inventory {
  // height: calc(100vh - 19rem);
  height: 100%;
  overflow-y: auto;
  @include flexBox(flex, null, center, center);

  @media (max-width: 767.98px) {
    border: 0;
    box-shadow: none;
    padding: 1rem 0.5rem 0.5rem;
    background-color: transparent;
  }

  // Hide scrollbar
  // For mozilla firefox
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  &>div {
    padding: 2rem 1rem;
    @include flexBox(flex, column nowrap, center, center);

    @media (max-height: 760px),
    (max-width: 1450px) {
      padding: 1rem;
    }
  }
}

// Datatable scrolling
@media (max-width: 1450px) {

  .dataTables_scrollHead,
  .dataTables_scrollBody {
    overflow: visible !important;
  }
}

.content-layout.content-layout-m-auth {
  padding-bottom: 0;
}

// Stepper Pills in mobile
.m-stepper {
  .nav-pills {
    @include flexBox(flex, row nowrap, center, space-between);
    gap: 5px;

    .show>.nav-link {
      gap: 0.125rem;
      color: white;
      padding-inline: 0.5rem;
      background: var(--text-dark02);

      img {
        transform: translateY(-0.25rem) scale(0.9);
      }

      h3 {
        max-height: 100%;
        white-space: nowrap;
      }
    }
  }

  .nav-link {
    flex: 1;
    height: 80px;
    @include ts(0.3s);
    padding-inline: 0.25rem;
    @include flexBox(flex, column nowrap, center, center);
    background-color: var(--text-dark);

    img {
      @include ts(0.2s);
      transform: translateY(0);
    }

    h3 {
      max-height: 0;
      color: white;
      overflow: hidden;
    }

    &.active {
      gap: 0.125rem;
      color: white;
      padding-inline: 0.5rem;
      background: var(--text-dark02);

      img {
        @include ts(0.3s);
        transform: translateY(-0.25rem) scale(0.9);
      }

      h3 {
        max-height: 100%;
        white-space: nowrap;
      }
    }
  }

  .tab-content {
    padding: 1rem;
    background: rgba($color: #fff, $alpha: 0.38);
    filter: drop-shadow(3px 4px 4px rgba(151, 134, 154, 0.13));

    p {
      color: #656565;
    }
  }

  .step-links {
    @include flexBox(flex, column nowrap, null, null);
    gap: 1rem;
  }

  .list-access {
    @include flexBox(flex, null, center, space-between);
    text-decoration: none;
    filter: invert(30%);
    @include ts(0.3s);

    &.active {
      filter: invert(0%);
    }

    &__left {
      @include flexBox(flex, null, center, center);
      gap: 5px;

      .square {
        height: 6px;
        width: 6px;
        border-radius: 1px;
        background-color: var(--text-dark);
      }

      span {
        line-height: 1;
        color: var(--text-dark);
      }
    }
  }

  .pills-navigation-active {
    color: white !important;
  }

  .status__completion {
    width: 33px;
    height: 33px;
  }
}

.prod-title-row {
  @include flexBox(flex, null, center, space-between);
  gap: 1rem;
  margin-bottom: 1rem;

  &__left {
    flex: 1;
  }

  &__right {
    .btn {
      width: 9.25rem;
      height: 2.8125rem;

      &:hover,
      &:focus,
      &:active {
        background-color: var(--primary-dark);
      }
    }
  }
}

.inside-list {
  .content-inside {
    &>p {
      min-height: 1.6rem;
      @include flexBox(inline-flex, null, center, null);

    }

    &--date {
      @include flexBox(inline-flex, row nowrap, center, flex-start);

      .btn-fab {
        padding: 0;
        @include flexBox(inline-flex, null, center, center);
        gap: 0.35rem;
        background: transparent;
        width: auto;

        &--pink {
          @include flexBox(inline-flex, null, center, center);
          width: 3.125rem;
          height: 3.25rem;
          border-radius: 0.625rem;
          background: var(--pastel-pink);

          @media (max-height: 760px),
          (max-width: 1450px) {
            width: 3rem;
            height: 3rem;
            border-radius: 0.575rem;
          }

          @media (max-width: 991.98px) {
            width: 2.8rem;
            height: 2.8rem;
            flex-shrink: 0;
            border-radius: 0.475rem;
          }

          @media (max-width: 767.98px) {
            width: 2.6875rem;
            height: 2.6875rem;
          }


          img {
            @include ts(0.3s);
            max-width: 48%;
          }

          &:hover,
          &:focus,
          &:active {
            img {
              transform: scale(1.07);
            }

            .bg {
              background: #ffc1da;
            }
          }
        }
      }
    }

    &--withBtn {
      &:last-of-type {
        flex-basis: 35%;
      }

      p,
      &>div {
        margin-bottom: 0;
        min-height: 1.6rem;
        @include flexBox(inline-flex, null, center, null);

        .btn {
          padding: 0;
          width: 1.5625rem;
          height: 1.625rem;
          border-radius: 0.625rem;
          background: var(--text-dark);
          @include flexBox(inline-flex, null, center, center);
          border-radius: 0.325rem;
          margin-left: 0.65rem;

          &:hover,
          &:focus,
          &:active {
            img {
              transform: scale(1.07);
            }

            .bg {
              background: var(--primary-dark);
            }
          }
        }
      }
    }
  }
}

.product-chart.description-box {
  padding: 1rem 0.8rem;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

// Support screen 
.issue-cat {
  @include flexBox(flex, column nowrap, null, null);
  padding: 0.25rem 0;

  a {
    display: flex;
    padding: 0 1.25rem;
    color: var(--text-dark);
  }
}

.support__right {
  max-height: calc(100vh - 7.65rem);
}

.chat-block {
  @include flexBox(flex, column nowrap, null, null);

  &__bar {
    height: 3.5rem;
    padding-inline: 1.5rem;
    border-radius: 10px 10px 0 0;
    background: var(--text-dark);
    @include flexBox(flex, null, center, null);

    @media (max-width: 1450px) {
      height: 3.15rem;
    }
  }

  &__input-box {
    padding: 0.31rem;
    border-radius: 8px;
    border: 1px solid #DFDFDF;
    margin: auto 0.65rem 0.65rem;
    @include flexBox(flex, null, center, null);

    @media (max-width: 1450px) {
      height: 3.5rem;
    }

    .btn {
      &-fab {
        &--fullsize {
          border-radius: 0.4rem;
          background: var(--text-dark);

          img {
            width: 100%;
            max-width: unset;
          }
        }

        &--transparent {
          width: 2.25rem;

          @media (max-width: 1450px) {
            width: 2rem;
          }

          img {
            max-width: 70%;

            @media (max-width: 1450px) {
              max-height: 1.32rem;
            }
          }
        }
      }

      &-solid--dark {
        gap: 0.6rem;
        height: 100%;
        margin-left: 1rem;
        padding-inline: 1.35rem;

        @media (max-width: 1450px) {
          margin-left: 0.8rem;
        }

        img {
          width: 1.25rem;

          @media (max-width: 1450px) {
            width: 1.125rem;
          }
        }
      }
    }

    .form-control {
      flex: 1;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      background: transparent;
      padding-inline: 1rem 0.35rem;

      &::placeholder {
        font-weight: 400;
        font-style: normal;
        font-size: 1.125rem;

        @media (max-width: 1450px) {
          font-size: 1rem;
        }
      }
    }
  }

  &__area {
    // max-height: calc(100% - 24vh);

    .text-msg {
      width: 100%;
      @include flexBox(flex, column nowrap, null, null);
      padding: 1.75rem 1.35rem;
      gap: 0.5rem;
      // overflow-y: auto;

      .imgName {
        @include flexBox(flex, null, center, center);
        background: var(--text-dark);
        font-size: 1.4375rem;
        width: 3.125rem;
        height: 3.125rem;
        padding: 3px;

        @media (max-width: 1450px) {
          font-size: 1.275rem;
          width: 2.85rem;
          height: 2.85rem;
        }
      }

      .time {
        color: #A0A0A0;
      }

      .msg {
        @include flexBox(flex, column nowrap, null, null);
        gap: 0.6rem;

        @media (max-width: 1450px) {
          gap: 0.4rem;
        }
      }

      &__sender {
        @include flexBox(flex, row-reverse nowrap, null, flex-end);
        margin-left: auto;
        max-width: 80%;
        gap: 0.85rem;

        .msg {
          &__top {
            @include flexBox(flex, row-reverse nowrap, center, space-between);
            gap: 1rem;
          }

          &__bottom {
            padding: 0.88rem 1.5rem;
            border: 2px solid var(--text-dark);
            border-radius: 0.875rem 0rem 0.875rem 0.875rem;

            @media (max-width: 1450px) {
              border-radius: 0.675rem 0rem 0.675rem 0.675rem;
              padding: 0.65rem 1rem;
            }
          }
        }
      }

      &__receiver {
        @include flexBox(flex, row nowrap, null, null);
        max-width: 80%;
        gap: 0.85rem;

        .msg {
          &__top {
            @include flexBox(flex, null, center, null);
            gap: 2.5rem;
          }

          &__bottom {
            padding: 0.938rem 1.5rem;
            border-radius: 0rem 0.875rem 0.875rem 0.875rem;
            box-shadow: 1px 1px 6px rgba(151, 134, 154, 0.165);
            border: 1px solid #E7E7E7;
            background: #FFF;

            @media (max-width: 1450px) {
              border-radius: 0rem 0.675rem 0.675rem 0.675rem;
              padding: 0.65rem 1rem;
            }
          }
        }
      }
    }
  }
}

.add_product_modal {
  .inside-list {
    &--stack {
      @include flexBox(flex, column nowrap, flex-start, flex-start);
      gap: 0.875rem 0;

      .content-inside {
        width: 100%;
        align-items: center;
        flex-flow: row nowrap;

        h3 {
          flex: 0 0 8.5rem;
          max-width: 8.5rem;
        }

        p {
          width: 100%;
          @include flexBox(flex, null, center, space-between);

          .form-control {
            @include form-control;
            height: 1.725rem;
            flex: 1;
            padding: 0 0.5rem;
            border-radius: 0.325rem;
            border: 1px solid var(--neutral-light);
            background: rgba($color: #fff, $alpha: 0.75);

            &:read-only {
              border: 0;
              padding: 0;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

.wrapper_incentives {
  padding: 1.6rem 1.35rem 0.5rem 1.5rem;

  .fix-height {
    max-height: 32vh;
    overflow-y: auto;
    padding-right: 0.35rem;
  }
}

.add_product_modal .holograf-form {
  .form-group--btnGroup {
    @include flexBox(flex, null, center, flex-start);
    margin-bottom: 1rem;

    .btn-set {
      @include flexBox(flex, row wrap, center, null);
      gap: 0.5rem;
    }

    .label {
      min-width: 9.75rem;
      flex-basis: unset !important;
    }
  }

  .form-group--warranty {
    .label {
      min-width: 9.75rem;
      flex-basis: unset !important;
    }

    .form-control {
      height: 2.6rem;
    }
  }
}

.form-group--highlight {
  max-height: 6rem;
  @include flexBox(flex, column nowrap, null, center);
  background: var(--neutral-light);
  padding: 1.12rem 0.65rem 0.75rem;
  margin: 0;
  gap: 0.5rem;
  position: relative;
  overflow: hidden;
  padding-inline: 2.2rem 1.5rem;

  .cross {
    position: absolute;
    height: 100%;
    width: 2rem;
    left: 0;
    top: 0;
    @include ts(0.3s);
    padding: 0.5rem 0.4rem;
    background-color: #e0e0e0;

    @include flexBox(inline-flex, column nowrap, center, center);

    img {
      max-width: 100%;
      opacity: 0.7;
      @include ts(0.3s);
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #d7d7d7;

      img {
        opacity: 1;
      }
    }
  }

  .label {
    flex-basis: unset !important;
  }
}

.modal-footer-dark--incentives {
  .cta-row {
    padding-inline: 0.4rem;
    @include flexBox(flex, null, center, flex-end);

    .btn {
      min-width: 8rem;
    }
  }
}

//opacity in productDetailsPage
// .modal-backdrop.show {
//   opacity: 0;
//   z-index: 0;
// }
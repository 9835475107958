.api-integrationBg {
    background: url(./../images/api-Gradientbackground.svg) no-repeat right top !important;
    background-color: black !important;

    p {
        width: 85%;
    }
}

.api-doc-screen {
    gap: 0.675rem;
    max-width: 100%;
    justify-content: space-between;

    .api-document {
        max-width: calc(100% - min(24%, 21rem) - 1.5rem);
        flex: 1;
    }
}

.api-doc-head {
    background-color: #FFFFFF;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    box-shadow: 3px 4px 4px 0px #97869A21;
    border: 2px solid #FFFFFF
}

.fz28 {
    font-size: 28px;
}

.fz22 {
    font-size: 22px;
}

.fz16 {
    font-size: 16px;
}

.documentation {
    padding: 1.5rem 2rem;
}

.doc-divider {
    border: 1px solid #656565;
}

.api-cards-header {
    background-color: black;
    display: flex;
    gap: 10px;
    padding: 1.12rem;
    align-items: center;
    border-radius: 8px 8px 0 0;

    button {
        text-decoration: none;
    }
}

.api-cards-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-color: transparent;
}

.document-title {
    max-height: 100%;
    height: 100%;
    max-width: min(24%, 21rem);

    .accordion {
        padding: 0.5rem 1rem;
    }

    .accordion-button {
        border: none;
        position: relative;

        &::after {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 1.3rem;
            height: 0.775rem;
            background-image: url(./../images/icons/icon-dropdown-arrow.svg);
            scale: 0.625;
        }

        &:not(.collapsed) {
            background: transparent;
        }
    }

    .accordion-body {
        padding: 0.5rem 1rem 1rem 5rem;
        color: var(--text-dark);
        background: transparent;

        ul.doc-links {
            a {
                position: relative;
                color: var(--text-dark);

                &::before {
                    content: "";
                    position: absolute;
                    width: 0.8rem;
                    left: -1.25rem;
                    top: 50%;
                    transform: translateY(-50%);
                    border-bottom: 1px dashed var(--text-dark);
                }

                &.active {
                    font-weight: 700;

                    &::before {
                        left: -1.3rem;
                        width: 0.85rem;
                        border-bottom: 2px solid var(--text-dark);
                    }
                }
            }
        }

        .vertical-line {
            position: absolute;
            height: 100%;
            left: -1.35rem;
            top: -0.6rem;
            border-left: 1px dashed #000;
        }
    }
}


.documentation>p::first-letter {
    font-size: 22px;
    font-weight: 600;
    color: var(--text-dark);
}

.api-endpoints-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-color: transparent;
}

.endpoints-wrapper {
    padding: 1rem;

    .api-doc-head {
        background: url(./../images/endpoints-blackBG.svg) no-repeat right top;
        background-color: #FFFFFF;
        padding: 1rem;
    }

    .api-cards-header {
        background: url(./../images/endpoints-gradientBg.svg) no-repeat right top;
        padding: 1rem;
        background-color: #000;

        // EXTRA ADDED
        border-radius: 0.625rem;
    }
}

.endpoint-card-wrapper {
    display: flex;
    align-items: center;
    gap: 3rem;

    .left {
        min-width: 3.398rem;
    }
}

.endpoints-Insidewrapper .box {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0.9rem;
    background-color: #000;
    border-radius: 4px;
    height: 35px;
    width: 36px;
}

.endpoints-Insidewrapper .right {
    display: flex;
    align-items: center;
}

.content-box {
    border-radius: 4px;
    height: 28px;
    background-color: var(--text-dark);
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.black-box-inside {
    background-color: #000;
    padding: 0.3rem;
    border-radius: 6px;
}

ul.linksURL {
    @include flexBox(flex, row wrap, center, null);
    gap: min(3vw, 2rem);
    margin-bottom: 0;

    .box {
        flex-shrink: 0;
    }
}

.white-box {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0.9rem;
    background-color: #ffffff;
    border-radius: 4px;
    height: 28px;
    width: 27px;
}

.endpoints-insideCardBtn>div {
    background-color: #000;
    border-radius: 4px;
}

.endpoints-inside-text {
    padding: 1rem;
}

.white-box {
    height: 38px;
    width: 37px;

    img {
        width: 1rem;
    }
}

.accordion--api {
    @include flexBox(flex, column nowrap, null, null);
    gap: 1rem;

    .accordion-button {
        background: transparent;

        &::after {
            display: none;
        }

        &:focus {
            outline: 0;
            box-shadow: none;
        }

        .linebefore {
            @include flexBox(inline-flex, row nowrap, center, center);
            position: relative;
            background-color: #000;

            span {
                width: 17px;
                height: 2px;
                background-color: var(--text-dark);

                &:last-child {
                    position: absolute;
                    width: 2px;
                    height: 17px;
                }
            }
        }

        &[aria-expanded="true"] {
            .linebefore {
                span {
                    &:first-child {
                        background: white;
                    }

                    &:last-child {
                        background: transparent;
                    }
                }
            }
        }

        &[aria-expanded="false"],
        &.collapsed {
            border: 1px solid var(--neutral-100);

            .linebefore {
                span {
                    &:first-child {
                        background: var(--text-dark);
                    }

                    &:last-child {
                        background: var(--text-dark);
                    }
                }
            }

            .api-cards-header {
                background-color: rgba(255, 255, 255, 1);
                background-image: none;
                border-radius: 8px;

                .gradientText--radial {
                    background: none;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: black;

                }
            }
        }
    }

    .accordian-widget {
        background: transparent;
        border: 2px solid white;
        // border-bottom: 0;
        border-radius: 9px;

        .api-cards-header {
            border-radius: 8px 8px 0 0;
            padding-inline: 1.05rem;
            gap: 1rem;
        }

        .api-cards-body {
            padding: 1.05rem;
            background-color: rgba(255, 255, 255, 0.31);
        }

    }
}

.array-values {
    padding-inline: 2.5rem;

    .orange-text {
        color: #FFC59C;
        word-wrap: break-word;
    }
    .pink-text{
        color: 	#FF1493;
    }
    .yellow-text{
        color: 	yellow;
    }
}

.other-content {
    @include flexBox(flex, row wrap, center, null);
    gap: 0.65rem;

    img {
        width: 1.5rem;
    }
}
$holo-grad: linear-gradient(36deg,
    #ffb6d5 0%,
    #ffe3c0 10%,
    #c7fbdb 29%,
    #c7cdf4 43%,
    #ffbcd4 54%,
    #ffe0c3 66%,
    #b0f1d4 76%,
    #c9ceec 84%,
    #ffc1da 91%,
    #bdffe3 100%);

.name {
  height: 2.688rem;
  width: 13.438rem;
  border: 1px solid rgb(121, 115, 115);

  h1 {
    color: var(--text-dark);
    font-family: Jost;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.email {
  width: 15.188rem;
  height: 1.813rem;
  border: 1px solid rgb(121, 115, 115);

  h1 {
    color: #787878;
    font-family: Jost;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.pointsDiv {
  width: 6.5rem;
  height: 2.25rem;
  border-radius: 0.625rem;
  border: 1px solid var(--text-dark);
  opacity: 0.23999999463558197;
  background: var(--holo-grad);

  /* new */
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
}

.points-count {
  @extend %gridprop;
  width: 2.6875rem;
  height: 2.22238rem;
  border-radius: 0.625rem;
  background: var(--text-dark);

  /* new */
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);

  span {
    font-family: Bebas Neue;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: var(--holo-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.point-text {
  color: var(--text-dark);
  font-family: Jost;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.035rem;
  text-transform: uppercase;
}

// success Page

.outer-container {
  width: 100%;
  max-height: 100%;
  border: 0px solid black;
  position: relative;
  @extend %gridprop;
}

.dashboard_header {
  @include fw(400);
  position: absolute;
  top: 0;
  left: 1.25rem;
  color: var(--text-dark);
  font-family: Josefin Sans;
  font-size: 1.25rem;

  img {
    margin-top: -5px;
  }
}

.content {
  border: 0px solid blue;
  width: 58.433rem;
  position: relative;
  @extend %gridprop;
}

.success-heading1,
.success-heading2 {
  color: var(--text-dark);
  text-align: center;
  line-height: 3.5625rem;
}

.success-heading1 {
  @include fw(300);
  font-size: 3.75rem;
  letter-spacing: -0.1125rem;
  border: 0px solid rgb(223, 17, 188);
}

.success-heading2 {
  @include fw(600);
  font-size: 2.875rem;
  letter-spacing: -0.08625rem;
  border: 0px solid red;
  margin-bottom: 1.56rem;
}

.success-card {
  z-index: 1;
  width: 35.4375rem;
  height: 20.5rem;
  border-radius: 0.625rem;
  background: var(--text-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("../images/waves.svg");
  background-repeat: no-repeat;
  background-position: top right;
  padding-left: 0.8rem;

  .fir {
    height: 78%;
    margin: 0.56rem 0.81rem 1.24rem 0.31rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .sec {
    height: auto;
    border: 0px solid rgb(24, 13, 168);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;

    div {
      height: 3rem;
      border: 0px solid white;
    }

    div:not(:nth-child(3)) {
      margin-bottom: 0.7rem;
    }
  }
}

.BarCodeImage {
  width: 6.3rem;
}

.sc_title,
.sc_value {
  color: var(--whitw);
  font-size: 1rem;
  letter-spacing: -0.03rem;
  display: block;
}

.sc_title {
  @include fw(400);
}

.sc_value {
  @include fw(600);
}

.brnd_btn {
  @include fw(600);
  padding-inline: 0.5rem;
  height: 1.5rem;
  border-radius: 0.4375rem;
  background: var(--holo-grad);
  color: var(--text-dark);
  font-size: 0.875rem;
  letter-spacing: -0.02625rem;
  border: 0px solid rgb(223, 16, 16);
}

.trusted_sheild {
  display: inline;
  height: 1.36rem;
  width: 1.1875rem;
  border: 0px solid white;
  margin-left: 0.2rem;
}

.dataTables_wrapper {
  .trusted_sheild {
    @media (max-width: 767.98px) {
      margin-left: 0;
    }
  }
}

.dwnld_btn {
  position: absolute;
  bottom: 1.12rem;
  right: 0.63rem;
  background: var(--holo-grad);
  display: grid;
  place-items: center;

  img {
    width: 1.125rem;
    height: 1.125rem;
  }
}

.success_bg {
  position: absolute;
  width: 58.433rem;
  top: 12.75rem;
  right: 0;
}

.download-btn {
  width: 3.125rem;
  height: 3.25rem;
  border-radius: 0.625rem;
}

// Select Product Page

.outer_main_div {
  width: 100%;
  max-height: 100%;
  //   width: 95.625rem;
  // height: 95.625rem;
  border: 0px solid rgb(223, 13, 13);
  display: flex;
}

.sp_left_box {
  //width: 44.5625rem;
  height: 49.4375rem;
  margin: 0rem 2rem 0rem 0rem;
  flex: 1;
  fill: rgba(255, 255, 255, 0.38);
  filter: drop-shadow(3px 4px 4px rgba(151, 134, 154, 0.13));
  border-radius: 0.625rem;
}

.card_heading {
  @include fw(600);
  color: var(--text-dark);
  font-size: 1.875rem;
  letter-spacing: -0.05625rem;
  height: 2.6875rem;
  width: 23.1875rem;
  margin: auto;
  margin-top: 2.63rem;
}

.card_des {
  @include fw(400);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-dark);
  font-size: 0.75rem;
  height: 1.0625rem;
  width: 20.6875rem;
  margin: auto;
  margin-bottom: 1.69rem;
}

.sp_right_box {
  flex: 1;
  height: 37.436rem;
  width: 41.75rem;
  margin: 8.69rem 0rem 3.25rem 0rem;
  border: 0px solid green;

  img {
    width: 100%;
    height: 100%;
  }
}

.card_content_wrap {
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: column;
}

.sp_div {
  width: 36.6875rem;
  height: 12.0625rem;
  margin: auto;
  margin-bottom: 0.94rem;
  position: relative;
  border-radius: 0.625rem;
  background-image: none;
  fill: rgba(255, 255, 255, 0.38);
  filter: drop-shadow(3px 4px 4px rgba(151, 134, 154, 0.13));

  //background: var(--holo-grad);
  &:hover {
    background-color: var(--primary-dark);

    .sp_p_category {
      color: rgb(236, 233, 233);
    }

    .bgcard-img {
      filter: invert(100%) brightness(100%);
    }
  }

  .bgcard-img {
    top: 0;
    right: 0;
  }
}

.sp_div1 {
  display: grid;
  border: 0px solid rgb(6, 12, 66);
  grid-template-columns: 3.125rem auto;
  height: fit-content;
  align-items: center;
  margin: 3.06rem 1.813rem auto 3.31rem;
  width: 28.43rem;

  div {
    border: 0px solid rgb(16, 33, 190);
  }
}

.sp_p_category {
  color: var(--text-dark);
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: -0.0375rem;
  margin-left: 0.56rem;
}

.product_detail {
  border: 0px solid red;
  margin: 0.62rem 4.88rem 3.69rem 3.38rem;
  width: 28.437rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--neutral);
}

.dark_r_arrow {
  color: var(--text-dark);
  width: 3rem;
}

.light_r_arrow {
  position: absolute;
  width: 2.125rem;
  top: 10.5rem;
  bottom: 1.56rem;
  right: 1.63rem;
  left: 32.94rem;
  color: var(--neutral);
}

.logo_btn {
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
}

// Create-Batch S-2 Page

.main-content-body {
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding: 1.4rem;
}

.table-top-bar {
  >div {
    flex: 1;
    height: 4.7rem;
    border: 0px solid red;
  }
}

.last-col {
  position: relative;
}

.flex-content-col1 {
  @include fw(500);
  color: var(--text-dark);
  font-size: 0.875rem;
  letter-spacing: -0.02625rem;
}

.bw-icon {
  position: relative;
  width: 2.1875rem;
  height: 2.3125rem;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  background: var(--whitw);
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
}

.title-01 {
  @include fw(400);
  color: var(--text-dark);
  font-size: 0.75rem;
}

.title-02 {
  color: var(--text-light);
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 300;
  margin-top: -15px;
}

.title_div {
  padding-top: 1.25rem;
}

.plus-icon {
  position: absolute;
  top: 60%;
  left: 30%;
}

.logobtn_position {
  position: absolute;
  top: 0.7rem;
  right: 1.12rem;
}

.product-list-table {
  th {
    background: var(--whitw);
    padding: 0.81rem 1.2rem;
  }

  th:first-child {
    border-radius: 0.625rem 0 0 0.625rem;
    border: none;
  }

  th:last-child {
    border-radius: 0 0.625rem 0.625rem 0;
    border: none;
  }

  td {
    @include fw(400);
    padding-left: 1.2rem;
    color: var(--text-dark);
    font-size: 0.75rem;
    letter-spacing: -0.0225rem;
  }

  tr {
    vertical-align: middle;
  }
}

.t-head-row {
  height: 2.3rem;
  border-radius: 0.625rem;
  background-color: #fff;

  th {
    @include fw(500);
    color: var(--text-dark);
    font-size: 0.875rem;
    letter-spacing: -0.02625rem;
  }
}

.brnd_btn_black {
  @include fw(600);
  height: 1.5rem;
  border-radius: 0.4375rem;
  font-size: 0.875rem;
  letter-spacing: -0.02625rem;
  background-color: var(--text-dark);
  padding-inline: 0.3rem;

  span {
    background: var(--holo-grad-radial);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 767.98px) {
    border-radius: 0.325rem;
    font-size: 0.75rem;
    height: 1.25rem;
  }
}

// CSV - S1 - Page

.left-sec {
  flex: 1;

  .dashed-box {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 25.7rem;
    border: 3px dashed var(--text-light, #787878);
    text-align: center;

    @media (max-width: 767.98px) {
      text-align: left;
    }

    @media (max-width: 567.98px) {
      height: 23rem;
      border-width: 2px;
      border-radius: 6px;
    }
  }

  .block-widget.block-widget-customised {
    padding: 1.75rem 1.15rem;

    @media (max-width: 567.98px) {
      padding: 1rem 0.85rem;
    }
  }

  .block-widget-customised {
    .url-input {
      height: 3.125rem;
      color: var(--text-light);
      background: rgba($color: #fff, $alpha: 0.6);
      @include ts(0.3s);

      &::placeholder {
        font-style: italic;
      }

      &:focus {
        outline: 0;
        border: 2px solid white;
        box-shadow: var(--main-shadow);
        background: rgba($color: #fff, $alpha: 0.95);
      }

      @media (max-width: 1450px),
      (max-height: 760px) {
        height: 3rem;
      }

      @media (max-width: 991.98px) {
        height: 2.8rem;
      }

      @media (max-width: 767.98px) {
        height: 2.6875rem;
      }
    }
  }

  .upload-img-wrapper {
    width: 5.43rem;
    height: 6.65rem;
    margin-bottom: 1rem;
  }

  .upload-top-sec {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      @media (max-width: 767.98px) {
        font-size: 1.125rem;
      }

      @media (max-width: 567.98px) {
        font-size: 1rem;
      }
    }
  }

  .btn-fab.upload-btn-grad {
    width: 8.25rem;
    @include ts(0.3s);

    @media (max-width: 567.98px) {
      width: 6rem;
    }

    span {
      background: var(--holo-grad-radial);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 767.98px) {
        font-size: 13px;
      }

      @media (max-width: 567.98px) {
        font-size: 12px;
      }
    }
  }

  .uploaded-items-bottom {
    height: 5rem;
    padding-inline: 1rem;

    @media (max-width: 767.98px) {
      height: 4.3rem;
    }

    .up-item {
      width: 27.6rem;
      height: 3.43rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      position: relative;
      background: var(--whitw, #fff);

      @media (max-width: 767.98px) {
        max-width: 13.5rem;
        color: #787878;

        span {
          font-size: 10.5px;
        }
      }
    }

    .up-icon-div {
      width: 1.61425rem;
      height: 2.12781rem;
    }

    .cut-btn {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}

.upload-level {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -0.02625rem;
  margin-top: 1.8rem;
}

.gradient-widget1 {
  width: 39.5rem;
  height: 15.9rem;
  border-radius: 0.625rem;
  border: 2px solid #fff;
  background-color: rgba($color: #fff, $alpha: 0.6);
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0.4;
    border-radius: 0.625rem;
    background: var(--holo-grad);
  }
}

.let_arrow {
  position: absolute;
  top: -1rem;
  left: 0;
}

.csv-bg {
  width: 21.4rem;
  height: 22.12rem;
  margin-bottom: 4px !important;
}

// CSV-02 Page

.dropdown-menu-holograf.drop-down-style {
  .dropdown-item {
    display: flex;
    align-items: center;
    height: 50px;
  }
}

.form-control_updated {
  &::placeholder {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }
}

// Reports Page

.top-bar-sec {
  background-color: var(--text-dark);
  border-radius: 0.63rem;
}

.btn_brdr {
  border: 1px solid rgb(214, 209, 209);
}

.chart-div {
  height: 26.5rem;
}

// .nav-upper-sec {
//         height: 2.8rem;
//         background-color: rgb(197, 245, 245);
//         margin: 1rem 0.5rem;
//       }
//       .ver-logo{
//         height: 2.5rem;
//         width: 10%;
//         position: relative;
//         background-image: url('./assets/images/vertical-dark-logo.svg');
//       }

// Tracking Page

.map-div {
  min-height: 70vh;
  border-radius: 0.625rem;
  position: relative;
  @include flexBox(flex, column nowrap, null, null);

  .top-bar-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-radius: 10px 10px 0 0;
  }

  .map-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  .map-wrapper {
    padding-top: 5.5rem;
    position: absolute;
    inset: 0;
  }

  .map-navigation-bar {
    // bottom: 0.75rem;
    // left: 0.75rem;
    margin-top: auto;
    padding: 0.75rem;
    position: relative;
    z-index: 3;
    overflow-x: auto;
    max-width: 100%;
    // Hide scrollbar
    // For mozilla firefox
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
    }

    &__wrapper {
      gap: 0.81rem;
      display: flex;
    }

    .inner-btns {
      position: relative;
      min-width: 14.5rem;
      text-align: left;
      height: 4.125rem;
      border-radius: 0.625rem;
      background: rgba(52, 52, 52, 0.31);
      backdrop-filter: blur(6px);
      padding: 0.8rem 1rem;

      div {
        line-height: 1.25rem;
      }
    }
  }
}

.arrow-img {
  // display: inline-block;
  // vertical-align: middle;
  margin-top: 0.3rem;
}

.info-card {
  background: rgba($color: #fff, $alpha: 0.38);
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  height: fit-content;
  border-radius: 0.625rem;
  //background: var(--holo-grad);
  //opacity: 0.1299;
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
  margin-top: 0.88rem;
  padding: 0.52rem 0.75rem 2.5rem;

  @media (max-width: 1450px),
  (max-height: 760px) {
    padding-bottom: 1.25rem;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0.15;
    z-index: -999;
    border-radius: 0.625rem;
    background: var(--holo-grad-radial);
  }

  nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;

    div {
      max-width: unset;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: start;
    }
  }

  .description {
    margin-top: 1rem;
    padding-right: 2.5rem;
  }
}

.info-card-footer {
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-between;
}

.date_btn {
  width: 6.875rem;
  height: 2.125rem;
  border-radius: 0.3125rem;
  background: #d0e1ee;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
}

.location_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  width: 9.625rem;
  height: 2.125rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.02625rem;
  border-radius: 0.4375rem;
  background: var(--text-dark);
  box-shadow: 3px 5px 12px 0px #c0c0c0;

  span {
    background: var(--holo-grad-radial);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.trusted_text {
  color: #268d3f;
  font-size: 0.75rem;
  font-weight: 500;
  position: absolute;
  bottom: -0.5rem;
  left: 0px;
}

.page-header__user-info.round_pf_pic {
  align-items: start;

  .user-img {
    width: 4.06rem;
    height: 4.06rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tracking__left {
  flex: 1;
}

.card__text.bg_holo {
  max-height: 10rem;
}

.trac-right-sec {
  height: 100%;
  max-width: 40%;
  max-height: calc(100vh - 9rem);
  position: sticky;
  top: 1rem;
  @include flexBox(flex, column nowrap, null, null);

  @media (max-width: 1450px),
  (max-height: 760px) {
    max-height: calc(100vh - 7.5rem);
  }
}

.top-bar-bg {
  position: sticky;
  top: 0;
  left: 0;
  background-image: url("../images/tracking-top-bar-bg.svg");
  background-position: right top;
  background-repeat: no-repeat;
}

.scroll-div-right {
  max-width: 32.25rem;
  margin-top: 0.75rem;
  padding: 0.8125rem 0.54725rem 0rem 0.5625rem;
  @include flexBox(flex, column nowrap, center, center);
  border-radius: 0.375rem;
  max-height: calc(100vh - 18rem);
  overflow-y: auto;
  background-color: rgba($color: #fff, $alpha: 0.6);

  @media (max-width: 1450px),
  (max-height: 760px) {
    max-height: calc(100vh - 15.5rem);
  }

  // Hide scrollbar
  // For mozilla firefox
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  .detail-cards {
    margin-block: 0;

    .card__text {
      height: auto;
      border-radius: 14px;
    }

    // .card__barcode .bar_code>div img {
    //   max-width: 85%;
    // }
  }

  .show-ownership-div {
    letter-spacing: -0.02625rem;
  }
}

.show-ownership-div {
  width: 100%;
  height: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background-color: var(--primary-dark);
  position: relative;
  background-image: url("../images/show-own_waves-bg.svg");
  background-position: right top;
  background-repeat: no-repeat;

  span {
    background: var(--holo-grad-radial);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.product-detail-div {
  width: 100%;
  height: 2.75rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #fff;
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);

  a {
    color: inherit;
    text-decoration: none;
    letter-spacing: -0.42px;
  }
}

.product-details-anchor {
  a {
    color: inherit;
    text-decoration: none;
    letter-spacing: -0.42px;
  }
}

.detail-cards .custom_card_barcode {
  flex: 0 0 8rem;
}

.bg_holo {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.8rem;
  }
}

.upload_sec {
  background: rgba(255, 255, 255, 0.6);
  padding: 0.3rem;
  width: 100%;
  height: 10.5rem;
  display: flex;
  gap: 0.5rem;

  &>div {
    text-align: center;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.625rem;
  }
}

.card_bg1,
.card_bg2 {
  background-color: rgba(255, 255, 255, 0.6);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0.2;
    border-radius: 0.625rem;
    background: var(--holo-grad);
  }
}

.card_wrapper-1 {
  height: 100%;
  padding-top: 20px;
  position: relative;
  background-image: url("../images/card_waves1.svg");
  background-position: left top;
  background-repeat: no-repeat;
  border-radius: inherit;
  z-index: 1;
}

.card_wrapper-2 {
  height: 100%;
  padding-top: 20px;
  position: relative;
  background-image: url("../images/card_wave2.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  border-radius: inherit;
  z-index: 1;
}

.card_bg3 {
  p {
    color: white;
  }

  button {
    background-color: white;
    color: #0e0d0d;
  }

  background: rgba($color: var(--text-dark), $alpha: 0.38);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 1;
    border-radius: 0.625rem;
    background: var(--text-dark);
  }
}

.card_wrapper-3 {
  height: 100%;
  padding-top: 20px;
  position: relative;
  background-image: url("../images/card_waves3.svg");
  background-position: right top;
  background-repeat: no-repeat;
  border-radius: inherit;
  z-index: 1;
}

.btn-fab--dark.white-btn {
  background-color: white;

  &:hover {
    background-color: white;
  }
}

.card.new_card {
  background: none;
  border: none;
}

.dwnld_qr_btn {
  position: absolute;
  right: 0;
  top: -0.35rem;
}

.location_btn.dwnld_qr_btn {
  width: 8rem;
  height: 1.65rem;
  z-index: 9;

  &--view {
    font-size: 12.6px;
    top: 2rem;
    // bottom: 0;
    font-weight: 500;
    // letter-spacing: 0px;
  }
}

.pac-container {
  z-index: 1151;
  display: block;
}

.favicon-box img {
  margin-left: 50px;
  cursor: pointer;
  width: 26%;
  height: auto;
}

.favicon-box-logistics img {
  margin-left: 60px;
  cursor: pointer;
  width: 23%;
  height: auto;
}

.wrap_other-op {
  position: sticky;
  top: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.38);
}

.new_card {
  .card__text {
    .text-pair {
      .label {
        max-width: 7.2rem;
        flex: 0 0 7.2rem;
      }
    }
  }
}

.arr_fix {
  position: absolute;
  top: 3rem;
  right: 0.6rem;
}

// Tracking Null Page
.map-div.custom_mapdiv {
  padding-top: 5.6rem;
}

.headlines_div {
  text-align: center;
  width: auto;
  max-width: 80%;

  p {
    margin: 0;
    font-size: 1.5625rem;
    font-weight: 300;
    letter-spacing: -0.04688rem;
  }

  h2 {
    font-size: 1.5625rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: min(3.8vh, 2.8rem);
    letter-spacing: -0.04688rem;
  }
}

.right-img-wrapper {
  max-width: 18.7rem;
  max-height: 26.12rem;
  border: 0px solid red;

  img {
    width: 100%;
    height: 100%;
  }
}

.block-widget.trac-right-sec-null {
  min-width: 40%;
  @include flexBox(flex, column nowrap, null, null);

  // .scroll-div-right.null-sc-div {}
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  background: #343434;
  color: white;
}

.gm-style .gm-style-iw-tc::after {
  background: #343434;
}

//Log-In Page

.logo-bar {
  // border: 1px solid blue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5.37rem;

  .logo {
    width: 7rem;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .globe-icon {
    // position: relative;
    width: 2.1255rem;
    height: 1.9375rem;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.login-form {
  max-width: 41.87rem;
  width: 41.87rem;
  height: 27.5rem;
  margin: auto;
  margin-top: 4.01rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border: 0px solid green;
  position: relative;
  // z-index: 1;

  .form-nav {
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
  }

  .form-content {
    width: 100%;
    height: 19.75rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding-inline: 5.8rem;

    h2 {
      letter-spacing: -0.045rem;
      margin-bottom: 0.65rem;
      z-index: 2;
    }

    p {
      margin-bottom: 1.8rem;
      z-index: 2;
    }
  }
}

.block-wavy-dark.form-nav {
  background: url("../images/login-topbar-wave.svg") right top no-repeat;
  background-color: var(--text-dark);
}

.input-div {
  z-index: 9;
  width: 100%;

  .form-control {
    height: 3.25rem;
    width: 100%;
    font-style: italic;
    padding-right: 3.75rem;
    border: none;

    @media (max-width: 1450px),
    (max-height: 760px) {
      height: 3rem;
    }
  }
}

.container-wrapper {
  position: relative;
  // border: 0px solid rgb(7, 46, 131);
}

.group-right1,
.group-left1 {
  border: 0px solid red;
  position: absolute;
  width: fit-content;
  height: fit-content;
}

.group-right1 {
  top: 17rem;
  left: 26rem;
  bottom: 0;
}

.group-left1 {
  top: 21rem;
  left: 8.5rem;
  bottom: 0;
}

//OTP page

.otp-box-holder {
  display: flex;
  gap: 2.62rem;
  z-index: 1;

  input {
    font-size: 0.875rem;
    font-weight: 700;
  }
}

// Activities page

.illustration.illustration_customised {
  height: 65%;

  img {
    width: 100%;
    height: 100%;
  }
}

.activity-topbar {
  height: 6rem;
}

.date-time {
  text-align: center;
  border-radius: 0.4375rem;
  border: 1px dashed var(--text-light, #787878);
}

.cards_1 {
  height: 100px;
}

// Preview page

.btn-dark-outlined.black-default {
  background-color: var(--text-dark);
  color: white;

  @media (max-width: 767.98px) {
    min-width: unset;
    width: 2.5rem;
    border-radius: 0.35rem;
  }
}

.holograf-form-1 {
  .btn-dark-outlined.black-default {
    @media (max-width: 991.98px) {
      min-width: 50%;
    }

    @media (max-width: 767.98px) {
      width: auto;
    }

    @media (max-width: 567.98px) {
      width: 100%;
    }
  }
}

.left-topbar-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3%;
  max-width: 30%;
}

.right-topbar-sec {
  margin-top: 2.5rem;
}

// Mapping Page
.csv-fields,
.select-field {
  height: 3rem;
  display: grid;
  justify-content: center;
  align-items: center;
}

.csv-fields {
  background-color: var(--text-dark);
}

.select-field {
  border: 2px solid var(--text-dark);
  position: relative;

  .upload-click.add-click {
    bottom: auto;

    .btn-fab.btn-add {
      width: 3rem;
      height: 3rem;
    }
  }
}

.middle-arrow {
  position: relative;
  margin-left: -10px;

  .verfied-div {
    position: absolute;
    width: 2.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.9375rem;
    border-radius: 0.25rem;
    background: var(--green, #d2f2db);
  }

  .error-div {
    position: absolute;
    width: 2.0625rem;
    height: 0.9375rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;
    background: var(--pink, #efcfe4);
  }
}

.side-bordered-div {
  position: relative;
  margin-left: 10px;
  //

  &::before {
    content: "";
    position: absolute;
    border-left: 1px dashed var(--text-dark);
    border-bottom: 1px dashed var(--text-dark);
    border-radius: 0 0 0 10px;
    height: calc(100% - 0.6rem);
    width: 1.8rem;
    left: 0;
    top: -1rem;
  }
}

.horizontal-line {
  border-top: 1px dashed var(--text-dark);
  width: 1.8rem;
  height: 0rem;
}

.number-tag {
  background-color: #222;
  border-radius: 0.375rem;
  position: absolute;
  left: -0.55rem;
  height: 1.06rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-card-dot-starting {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: var(--text-dark);
  border-radius: 50%;
  top: 49%;
  left: 1.28rem;
}

.map-form {
  .left-col {
    padding-right: 10px;
  }
}

.map-form .row:last-child {
  .horizontal-line {
    border-top: none;
  }
}

// Inventory PL-01

.heading-top-div {
  text-align: center;
  padding-bottom: min(5vh, 4rem);

  @media (max-width: 767.98px) {
    padding-bottom: 1.2rem;

    h6 {
      text-align: left;
    }
  }

  p {
    max-width: 42.8rem;
    margin-bottom: 0;

    @media (max-width: 767.98px) {
      font-size: 10px;
      text-align: left;
    }
  }
}

.plcards-wrapper-div {
  z-index: 1;
  max-width: 47.5rem;
  display: flex;
  gap: min(2vw, 3rem);
  justify-content: space-between;

  .pl-01-cards {
    cursor: pointer;
    width: 22.56938rem;
    height: 26.875rem;
    position: relative;
    //border: 1px solid rgb(20, 20, 20);
    display: flex;
    flex-direction: column;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      opacity: 0.28;
      z-index: -999;
      background: var(--holo-grad-radial);
    }

    .content-inner {
      margin-top: 3rem;

      .card-details-wapper {
        max-width: 19.4375rem;
      }
    }

    .down-card,
    .up-card {
      flex: 1;
    }

    .up-card {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .card-tite {
        letter-spacing: -0.045rem;
        display: flex;
        justify-content: center;
      }

      .card-details {
        margin-bottom: calc(1.13rem - 8px);
      }
    }

    .down-card1 {
      overflow: hidden;
      flex: 1;
      position: relative;

      img {
        z-index: -1;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .down-card2 {
      overflow: hidden;
      flex: 1;
      position: relative;

      img {
        z-index: -1;
        position: absolute;
        bottom: -3.3rem;
        right: -3.5rem;
      }
    }
  }
}

.locotion-img {
  width: 15px;
  height: 18px;
}

.inventory-qr-image {
  width: 65px;
  height: 65px;
}

// Inventory PL-02 -s2

.layer-wapper {
  width: 100%;
  height: 13.6rem;
  border: 0px solid red;
  display: flex;
  justify-content: space-between;
  z-index: 1;

  .layer-steps {
    min-height: 13.6rem;
    max-width: 14.2rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: url(../images/inventory-blurr-background-card.svg) no-repeat center;
    background-size: contain;
    border-radius: 0.625rem;
    margin: -0.35rem -0.35rem 0 0;

    div {
      max-width: 65%;
      text-align: center;
    }
  }

  .add-layer {
    height: 93.4%;
    width: 6.3rem;
    border: 2px dashed var(--text-dark);

    .down-poining-arrow {
      transform: rotate(90deg);
    }

    .clickBtn.clickBtn-modified {
      flex-direction: column;
      padding-inline: 1rem;
      gap: 1.7rem;
      border: 1px dashed var(--text-dark);

      &[aria-expanded="true"] {
        background-color: white;
      }

      // justify-content: space-evenly;
    }

    .layer-pair.layer-pair-modified {
      justify-content: center;
    }

    .collapse.show {
      background-color: white;
    }
  }

  .number-icon {
    background: var(--pastel-pink);
    padding-top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 3px;
    right: 3px;
  }

  .clickBtn-modified {
    &::after {
      display: none;
    }

    .text-center {
      white-space: normal;
    }
  }

  .layer-description.layer-description-modified {
    top: 102%;
    border-radius: 10px;
    max-width: 6.4rem;
    min-width: unset;

    .layer-pair {
      padding: 0.75rem 1.5rem;

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: #faf4f4;
      }
    }
  }

  .layer-step-gradbg {
    height: 93.4%;
    width: 14.5rem;
    padding: 0 0.4rem 1.4rem 1rem;
    @include flexBox(flex, column nowrap, center, center);
    position: relative;
    background: url("../images/inventory-pl02-s2-card.svg") left bottom no-repeat;

    @media (max-height: 760px),
    (max-width: 1450px) {
      width: 13.5rem;
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      opacity: 0.28;
      z-index: -999;
      background: var(--holo-grad-radial);
    }

    .ineer-content-box {
      max-width: 11.9rem;
      margin-bottom: 0.5rem;
    }

    .level-tag-card {
      font-weight: 500;
      font-size: 0.875rem;
      letter-spacing: -0.02625rem;
    }

    .layer-modified {
      align-items: center;
      gap: 0.5rem;
    }

    .details-pl {
      max-width: 80%;
    }

    .batch-number-pl {
      background: #efcfe4;
      padding: 3.6px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 3px;
      right: 3px;
    }

    // .card-top-checkbox{
    //   border: 1px solid black;
    //   display: flex;
    //   align-items: center;
    //   gap: 0.4rem;

    // }
    /* Hide the default checkbox */
    .card-top-checkbox {
      gap: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0.5rem;
      right: 0.6rem;
    }

    .card-top-checkbox input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }

    /* Create a custom-styled checkbox */
    .card-top-checkbox .checkbox-icon {
      width: 2rem;
      height: 2rem;
      border: 1px solid #000;
      background-image: none;
      /* Replace 'unchecked-image-url.png' with the image URL you want to display when not checked */
      border-radius: 5px;
      content: "";
      /* Add an empty content attribute */
    }

    /* Set the background image for the checked state using the content property */
    .card-top-checkbox input:checked+.checkbox-icon::before {
      content: url("../images/icons/icon-black-checkbox.svg");
      /* Replace 'checked-image-url.png' with the image URL you want to display when checked */
      /* Add any additional styling for the checked state */
      background-size: cover;
      /* Fit the background image inside the checkbox */
      background-repeat: no-repeat;
      background-position: center;
      border: none;
    }
  }
}

// Inventory PL-03

.table-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.7rem 0;
}

// Inventory Sucess Page
.success-card.success-card-overidden {
  background-image: url(../images/inventory-pl-success-bg-wave.svg);
  background-repeat: no-repeat;
  background-position: top left;
}

.dwnld_btn.dwnld_btn-modified {
  top: 0.81rem;
}

.adjust-btn {
  bottom: 0.81rem;
  right: 0.63rem;
}

// Inventory Step02- Screen1- Logistics &&  Inventory Step02- Screen1- Warehouse

.plcards-wrapper-div {
  .down-card1.down-card1-overidden {
    overflow: visible;
  }

  .down-card2.down-card2-overidden {
    overflow: visible;

    img {
      bottom: 0;
      right: 0;
    }
  }
}

// Inventory step02 - s02- Transportation
.upper-card-row {
  @include flexBox(flex, row nowrap, space-between, center);
  gap: 0.75rem;

  .card-item {
    position: relative;
    //border:2px solid rgb(235, 13, 31);
    flex: 1;
    height: 8.93rem;
    flex-shrink: 0;
    border-radius: 10px;
    @include flexBox(flex, column nowrap, null, center);

    padding-left: 1.25rem;

    .in-content {
      max-width: 73%;
      text-align: left;
    }
  }

  .bg-img1 {
    background: url("../images/inventory-step2-sc2-transpotaion-card1.svg") right bottom no-repeat;
    background-color: var(--text-dark);
  }

  .bg-img2 {
    background: url("../images/inventory-step2-sc2-transpotaion-card2.svg") right bottom no-repeat;
    background-color: var(--text-dark);
  }

  .bg-img3 {
    background: url("../images/inventory-step2-sc2-transpotaion-card3.svg") right bottom no-repeat;
    background-color: var(--text-dark);
  }
}

/* Hide the default checkbox */
.card-top-checkbox-bl {
  gap: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.5rem;
  right: 0.6rem;
}

.card-top-checkbox-bl input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom-styled checkbox */
.card-top-checkbox-bl .checkbox-icon-bl {
  content: "";
  width: 2rem;
  height: 2rem;
  border: 1px solid #fff;
  background-image: none;
  /* Replace 'unchecked-image-url.png' with the image URL you want to display when not checked */
  border-radius: 5px;
  /* Add an empty content attribute */
  cursor: pointer;
}

/* Set the background image for the checked state using the content property */
.card-top-checkbox-bl input:checked+.checkbox-icon-bl::before {
  content: url("../images/icons/white-checkbox.png");
  /* Replace 'checked-image-url.png' with the image URL you want to display when checked */
  /* Add any additional styling for the checked state */
  background-size: cover;
  /* Fit the background image inside the checkbox */
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  margin-left: -1px;
}

.dashed-Route-dtails-div {
  height: 3.8rem;
  border-radius: 0.625rem;
  border: 2px dashed var(--text-dark);
  margin-top: 1.35rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;

  .right-sec {
    letter-spacing: -0.02625rem;
    display: flex;
    align-items: center;
    gap: 0.65rem;
    margin-right: 0.3rem;

    .icon-wrap {
      cursor: pointer;
      background-color: #fff;

      &:hover,
      &:focus,
      &:active {
        background-color: #f3f3f3;

        img {
          transform: scale(1.04);
        }
      }
    }
  }
}

.side-bordered-dashed-div {
  margin-top: 2.3rem;
  position: relative;
  margin-left: 0.5rem;

  &::before {
    content: "";
    position: absolute;
    border-left: 1px dashed var(--text-dark);
    border-bottom: 1px dashed var(--text-dark);
    border-radius: 0 0 0 10px;
    height: calc(100% - 3.5rem);
    width: 4.3rem;
    left: 0;
    top: -2.1rem;

    @media (max-width: 1450px),
    (max-height: 760px) {
      height: calc(100% - 3.25rem);
    }
  }

  .routes-cover {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .st-end-sec {
    position: relative;
    //border:1px solid red;

    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 1.5rem;
    }

    .point-tag {
      letter-spacing: -0.02625rem;
      position: absolute;
      top: -1.8rem;
      left: 0.5rem;
    }

    // .arrow-to-box.arrow-to-box-last{

    // }

    .arrow-to-box {
      width: 4.3rem;
      height: 0rem;
      border-top: 1px dashed var(--text-dark);
    }

    // The last div will not have the bordered-top div
    .arrow-to-box.arrow-to-box-last {
      border-top: none;
    }

    .time-fields {
      position: relative;
      // border:1px solid red;
      height: 100%;
      flex: 1;
      padding: 0.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.5rem;

      .arrow-tip {
        &::before {
          content: "";
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          top: 50.0434%;
          left: -0.7rem;
          transform: translateY(-50%) rotate(-45deg);
          border-right: 1px solid #333;
          border-bottom: 1px solid #333;
        }
      }
    }
  }
}

// Stepper inventory

.li-bottom {
  flex-wrap: wrap;
  max-width: 100%;
  margin-top: 0.75rem;
  display: flex;
  gap: 0.5rem;
  //max-width: 90%;
  padding-left: 5.25rem;

  .li-bottom-child {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 0.2rem;

    img {
      z-index: 1;
    }
  }

  .li-bottom-child:first-child {
    position: relative;

    img {
      position: absolute;
      top: -0.7rem;
      left: -2rem;
    }
  }
}

.steps.steps-modified {
  .status {
    img {
      display: block !important;
    }
  }
}

.overflow-auto {
  overflow-y: auto;
  // Hide scrollbar
  // For mozilla firefox
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
}

.hide-me {
  display: none;
}

.right-stepper.right-stepper-modified {
  // margin-top: 2.3rem;
  position: relative;

  // margin-left: 0.5rem;
  &::before {
    content: "";
    position: absolute;
    border-left: 1px dashed white;
    border-bottom: 1px dashed white;
    border-radius: 0 0 0 10px;
    height: calc(100% - 2.85rem);
    width: 2.5rem;
    left: 0;
    top: -1rem;
  }
}

.form-nav {
  .right {
    @include flexBox(inline-flex, null, center, center);
  }
}

.notification-content {
  position: relative;
}

.notification-panel {
  position: absolute;
  text-align: center;
  top: 50px;
  right: 0;
  z-index: 99;
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: #dcdbe0;
  border: 1px solid black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.NotificationcloseButton {
  margin-left: 320px;
  margin-bottom: 20px;
  background-color: red;
  font-weight: bold;
  width: 80px;
  height: 30px;
}

.NotificatiionTable {
  margin: auto;
  width: 90%;
  border: 1px solid grey;
  text-align: center;

  th {
    border: 1px solid white;
    padding: 0.5rem;
  }

  td {
    border: 1px solid white;
    padding: 0.5rem;
  }
}

.approvebtn {
  background-color: green;
  padding: 3px;
}

.rejectbtn {
  background-color: red;
  padding: 3px;
}

.logout img:hover {
  background-color: red;
}

.cat_div_scroll {
  max-height: 18rem;
  overflow-y: auto;
}

.custom-datepicker-container {
  top: 2.8rem;
  right: 0;
  z-index: 999
}

// ***************** All media-queries *********************

@media (max-height: 760px),
(max-width: 1450px) {
  .logo-bar {
    margin-top: 1.5rem;
  }

  .login-form {
    max-width: 39rem;
    width: 39rem;
    height: 23rem;
    margin-top: 1rem;

    .form-nav {
      height: 4.5rem;
    }

    .form-content {
      height: 17.5rem;
    }
  }

  .group-right1 {
    top: 10rem;
    left: 30rem;
    bottom: 0;
    right: 0;
    //  img{
    //   height: 24rem;
    // }
  }

  .group-left1 {
    top: 14rem;
    left: 1.5rem;
    bottom: 0;
  }

  // Tracking Page02

  ul.preview-tags .p-tag {
    padding-inline: 1.125rem;
  }

  .tracking-downline {
    justify-content: flex-start;
  }
}

// margin-media-box

// Tracking-01- box

@media only screen and (max-width: 1280px) {
  .margin-media-box {
    margin-top: 0rem !important;
  }
}

@media (max-width: 767.98px) {
  .logo-bar {
    margin: 2.5rem 1.65rem 10vh;

    .globe-icon {
      width: 1.7rem;
      height: auto;
    }
  }

  .otp-box-holder {
    width: 100%;
    gap: 0.5rem;
    max-width: 85%;
    justify-content: space-between;

    input {
      text-align: center;
    }
  }

  .login-form,
  .loader-screen {
    max-width: 80%;
    width: auto;
    height: 22rem;
    overflow: hidden;

    .form-nav {
      height: 4rem;
      min-height: 4rem;
      justify-content: center;

      h3 {
        span {
          line-height: 1.3;
        }
      }
    }

    .form-content {
      padding-inline: 10vw;
      height: 17rem;

      p {
        font-size: 14.8px;
        text-align: center;
      }
    }
  }

  .group-left1 {
    top: 50vh;
    left: 0.5rem;
    bottom: unset;

    img {
      max-width: 42.5vw;
    }
  }

  .group-right1 {
    top: 45vh;
    right: -25vw;
    bottom: unset;
    left: unset;

    img {
      max-width: 100vw;
    }
  }

  .input-div {
    .form-control {
      height: 2.935rem;
      border: 2px solid white;
      background: rgba($color: #fff, $alpha: 0.66);
      box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
    }

    .upload-click {
      .btn-fab {
        width: 2.9375rem;
        height: 2.9375rem;
      }
    }
  }

  .loader {
    max-width: 4.5rem;
  }

  .loader-screen {
    margin-top: -6.5vh;
    padding-bottom: 18vh;

    .block-wavy-dark.form-nav {
      padding-inline: 0.75rem 5rem;
      padding-block: 0.25rem;

      img {
        max-height: 1.2rem;
      }

      h3 {
        span {
          font-size: 0.775rem;
          line-height: 1.5;
        }
      }
    }
  }
}

@media (max-width: 567.98px) {
  .logo-bar {
    margin-inline: 0.5rem;

    .globe-icon {
      width: 1.6rem;
    }
  }

  .block-wavy-dark.form-nav {
    background-position: 100%;
    background-size: contain;
  }

  .login-form,
  .loader-screen {
    height: 19.35rem;

    .form-nav {
      height: 3.6rem;
      min-height: 3.6rem;

      h3 {
        span {
          font-size: 1rem;
          line-height: 1.45;
        }
      }
    }

    .form-content {
      padding-inline: 5vw;
      height: 15rem;

      h2 {
        line-height: 1;
      }

      p {
        font-size: 14px;
        margin-bottom: 1.35rem;
      }
    }
  }

  .otp-box-holder {
    max-width: 100%;
    padding-inline: 2vw;

    .bdrs10.btn-fab--dark {
      border-radius: 0.475rem;
    }
  }
}

@media (max-width: 449.98px) {
  .container-wrapper {
    height: 100%;
  }

  .login-form,
  .loader-screen {
    max-width: 96%;
    height: 19rem;

    .form-nav {
      height: 3.4rem;
      min-height: 3.4rem;
    }

    .form-content {
      padding-inline: 4vw;
      height: 15rem;
    }
  }

  .group-left1 {
    top: unset;
    left: 0;
    bottom: 10vh;
    z-index: 10;

    img {
      max-width: 28vh;
    }
  }

  .group-right1 {
    top: unset;
    right: -55vw;
    bottom: 2rem;
    left: unset;

    img {
      rotate: -6deg;
      max-width: 140vw;
    }
  }
}

@media (max-width: 375px) {
  .otp-box-holder {
    padding-inline: 0.5vw;
  }
}

.search_div_dashboard {
  min-width: unset !important;
  width: auto !important;
}

.icon-wrap-costomise {
  width: 3rem !important;
  box-shadow: none !important;
}

.form-control-customised {
  padding-inline: 3rem 1rem !important;

  &:focus {
    box-shadow: var(--main-shadow);
  }
}

.warranty-btn {
  height: 2.85rem;

  &>div {
    height: 100% !important;
  }
}

.react-datepicker-popper {
  z-index: 99;
}

.custom-dropdow-div {
  max-height: 120px;
  overflow-y: scroll;
}

.custom-width {
  width: 17% !important;
}

.custom-width2 {
  width: 20% !important;
}
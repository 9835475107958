.holograf-form {
  @include flexBox(flex, column nowrap, null, null);
  gap: 1.5rem;

  .form {
    &-group {
      .label {
        padding-left: 0.25rem;
        margin-bottom: 0.55rem;
        letter-spacing: -0.02625rem;
      }
    }

    &-control {
      @include form-control;

      &--textarea {
        padding-block: 0.8rem;
        height: 8rem;
        min-height: 8rem;
      }
    }
  }
}

.date-div {
  &:hover {
    .btn-fab {
      &--pink {
        background: #ffc1da;
      }
    }
  }

  .btn-fab {
    top: 0;
    right: 0;
    pointer-events: none;

  }
}

.date-time-group {
  @include flexBox(flex, null, center, null);

  .custom-datepicker-container {
    position: absolute;
    right: 0;
    top: 0;

    .black-datepicker {
      border: 2px solid white;
    }
  }

  input:not([type="time"]) {
    padding-right: 2.5rem;
  }

  input[type="time"] {
    padding-right: 0;

    &::-webkit-calendar-picker-indicator {
      width: 3.25rem;
      height: 3.25rem;
      background: url(./../images/icons/icon-clock-tick.svg) no-repeat;
      background-size: 3rem;
      background-position: 100%;
      cursor: pointer;

      @media (max-width: 1450px),
      (max-height: 760px) {
        background-size: 2.8rem;
      }
    }
  }
}

.selected-color {
  flex: 1;
  height: 100%;
  color: var(--neutral);
  @include flexBox(inline-flex, null, center, null);
  gap: 0.85rem;

  &.color-cover {
    color: var(--text-dark);
    gap: 0.65rem;

    .text {
      padding-top: 2px;
    }
  }
}

.select-div {
  @include flexBox(flex, null, center, space-between);
  padding: 0;
  cursor: default;
  pointer-events: none;

  .btn-fab {
    right: -1px;
    top: -1px;
    bottom: -1px;
    height: auto;
    padding-top: 2px;
    cursor: pointer;
    pointer-events: auto;

    img {
      @include ts(0.3s);
    }
  }

  &[aria-expanded="true"] {
    .btn-fab {
      img {
        transform: rotate(180deg);
      }
    }
  }
}

.dropdown-menu-holograf {
  border-radius: 0.625rem;
  background: rgba($color: #fff, $alpha: 1);
  box-shadow: var(--main-shadow);

  &--position {
    inset: unset !important;
    top: 3.5rem !important;
    transform: none !important;
    z-index: 10;
  }
}

// Checkboxes //
// Add/Remove text checkbox
.checkbox-wrapper {
  display: inline-block;

  @media (max-width: 767.98px) {
    line-height: 0 !important;
  }

  input[type="checkbox"],
  input[type="radio"] {
    height: 0;
    width: 0;
  }

  input[type="checkbox"]+label,
  input[type="radio"]+label {
    position: relative;
    @include flexBox(inline-flex, null, center, null);

    // b {
    //   margin-left: 0.65rem;
    // }
    &>span {
      padding: 0;
      @include flexBox(inline-flex, null, center, center);
      margin-right: 0.65rem;
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 0.25rem;
      border: 1px solid #efecec;
      background-color: white;
      cursor: pointer;
      @include ts(0.3s);

      @media (max-width: 767.98px) {
        width: 0.9375rem;
        height: 0.9375rem;
        border-radius: 0.125rem;
        margin-right: 0.35rem;
      }

      &::before {
        content: "";
        position: absolute;
        background: url(./../images/icons/icon-green-tick.svg) no-repeat center;
        border-radius: 0.25rem;
        background-size: 0.38rem;
        width: 1.375rem;
        height: 1.375rem;
        left: 0rem;
        top: 0.025rem;
        opacity: 0;
        @include ts(0.3s);
        transform-origin: 0% 100%;

        @media (max-width: 767.98px) {
          border-radius: 0.125rem;
          width: 0.9375rem;
          height: 0.9375rem;
        }
      }
    }

    &>div {
      padding-top: 1px;
      @include flexBox(inline-flex, null, center, null);
    }

    .text_remove {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {

    &+label:hover>span,
    &:focus+label>span {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12) inset;
      border-color: var(--text-dark);
    }
  }

  input[type="checkbox"]:checked+label {
    &>span {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      border-color: var(--text-dark);

      &:before {
        background-size: 0.938rem;
        opacity: 1;

        @media (max-width: 767.98px) {
          background-size: 0.625rem;
        }
      }
    }

    .text_add {
      width: 0;
      height: 0;
      opacity: 0;
    }

    .text_remove {
      width: 3rem;
      height: 0.75rem;
      opacity: 1;
    }
  }
}

// Number field arrow styling
.form-group--number {
  position: relative;

  input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &-nav {
    @include flexBox(inline-flex, column nowrap, null, null);
    position: absolute;
    right: 0rem;
    border-radius: 0.625rem;
    background: #d2f2db;
    width: 3.25rem;
    height: 3.15rem;
    top: 1.6rem;

    @media (max-height: 760px),
    (max-width: 1450px) {
      border-radius: 0.575rem;
      height: 2.9rem;
      top: 1.475rem;
      width: 3rem;
    }
  }

  &-button {
    width: 100%;
    position: relative;
    cursor: pointer;
    border: none;
    color: var(--text-dark);
    font-size: 1.25rem;
    position: absolute;
    height: 50%;
    right: -3.25rem;
    @include ts(0.3s);
    border-radius: 0.625rem;
    @include flexBox(inline-flex, null, center, center);
    background: transparent;
    font-family: "FontAwesome" !important;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    @media (max-height: 760px),
    (max-width: 1450px) {
      border-radius: 0.575rem;
      font-size: 1.125rem;
      right: -3rem;
    }

    &:hover,
    &:focus,
    &:active {
      background: #f0fff4;
    }

    &.form-group--number-up {
      top: 0;
      padding-bottom: 1px;
    }

    &.form-group--number-down {
      bottom: 0;
      padding-top: 1px;
    }
  }
}

.add-product-modal-content {
  .counter-wrapper {
    flex: 0 0 min(45%, 16rem);
    max-width: min(45%, 16rem);

    .form-group {
      flex: 1;

      .form-control {
        @include form-control;
        border: 1px solid var(--neutral-light);
      }
    }
  }

  .form-control {
    @include form-control;
    border: 1px solid var(--neutral-light);
    height: 2.8rem;
    border-radius: 0.5rem;
  }

  .btn-fab--green {
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 2.65rem;
    padding-block: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--pastel-green);
    border-radius: 0.45rem;
  }
}

.countryNumDD {
  display: flex;
  align-items: center;
  @include form-control;
  padding: 0;

  .dropdown {
    width: 5rem;
    flex: 0 0 5rem;
    height: 1.8rem;

    .select-div {
      height: 1.8rem;
    }
  }

  .dropdown-menu {
    top: 12px !important;
  }

  .btn-fab {
    position: static;
    background-color: transparent;
    justify-content: space-around;
    padding-inline: 0.4rem 0.12rem;
    gap: 0.5rem;
    min-width: 5rem;
    border-radius: 0;
    border-right: 2px solid var(--white);
    box-shadow: 1px 0 0.25px rgba($color: #000000, $alpha: 0.125);

    img {
      max-width: 0.55rem;
    }

    &:hover,
    &:focus,
    &:active {
      background: none;
    }
  }

  .form-control {
    height: 100%;
    border: 0;
    background: none;
    box-shadow: none;
    padding-left: 0.65rem;
  }
}
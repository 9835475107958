// Counterfiet screen styling
.cf-card {
  position: relative;
  padding: 1rem;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0.13;
    // background: var(--holo-grad);
    background: linear-gradient(69deg, #FFB6D5 1.86%, #FFE3C0 11.11%, #C7FBDB 28.69%, #C7CDF4 41.64%, #FFBCD4 51.82%, #FFE0C3 62.92%, #B0F1D4 72.17%, #C9CEEC 79.58%, #FFC1DA 86.05%, #BDFFE3 94.38%);
  }

  &__content {
    position: relative;
    @include flexBox(flex, column nowrap, null, null);
    z-index: 1;
    gap: 0.85rem;

    .other-row {
      @include flexBox(flex, null, center, space-between);

      &>div {
        @include flexBox(inline-flex, null, center, center);
      }

      .left {
        gap: 0.375rem;

        .btn {
          img {
            width: 32%;
          }
        }
      }

      .right {
        gap: 0.45rem;
      }
    }

    .cf-collapsible {
      @include flexBox(flex, column nowrap, null, null);
    }

    .btn-cf-deets {
      padding: 0;
      height: 2.75rem;
      border-radius: 0.375rem;
      background: var(--white);
      @include flexBox(flex, null, center, space-between);

      strong {
        flex: 1;
      }

      span {
        height: 100%;
        width: 2.75rem;
        border-radius: 0.375rem;
        @include flexBox(inline-flex, null, center, center);
        background: var(--text-dark);

        img {
          transform: rotateX(180deg);
          margin-top: 3px;
        }
      }

      &[aria-expanded="true"] {
        span {
          img {
            transform: rotateX(0deg);
            margin-top: 0;
          }
        }
      }
    }
  }

  .cf-inner-cards {
    --bdHeight: 3.125rem;

    @media (max-width: 1450px),
    (max-height: 760px) {
      --bdHeight: 2.5rem;
    }

    margin: 0;
    padding: 1.6rem 0.5rem 0.5rem;
    @include flexBox(flex, column nowrap, null, null);

    li {
      padding-bottom: var(--bdHeight);
      position: relative;


      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }

      &::before {
        border-top: 5.8px solid #000;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }

      &::after {
        height: var(--bdHeight);
        border-left: 1px dashed #000;
      }

      &:last-child {
        padding-bottom: 0;

        &::before,
        &::after {
          position: static;
          border: 0;
        }
      }
    }
  }
}

.collapse-td-cards {
  --bdWidth: 5.75rem;

  @media (max-width: 1450px),
  (max-height: 760px) {
    --bdWidth: 4rem;
  }

  margin: 0;
  // margin-right: calc(var(--bdWidth) * -1);
  gap: 1rem 0;
  padding: 1rem 1rem;
  @include flexBox(flex, row wrap, center, null);

  li {
    min-width: 30%;
    padding-right: var(--bdWidth);
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &::before {
      border-left: 5.8px solid #000;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }

    &::after {
      width: var(--bdWidth);
      border-top: 1px dashed #000;
      transform: translateY(calc(-50% + 0.15px));
    }

    &:last-child {
      // padding-right: 0;

      &::before,
      &::after {
        position: static;
        border: 0;
      }
    }
  }
}

.cf-user {
  &--blue {
    background: #E9F5FF;
  }

  &--red {
    background: #FEE;
  }

  .p-label {
    right: 0.5px;
    top: 0.5px;
    height: 1.8125rem;
    min-width: 1.8125rem;
    border-radius: 0.375rem;
    background: var(--text-dark);
    @include flexBox(inline-flex, null, center, center);
  }

  &__top-bar {
    padding: 10px 5px;
    @include flexBox(flex, null, center, null);
    gap: 0.5rem;

    .user-img {
      width: 3rem;
      height: 3rem;
      padding: 3px;
      background: var(--holo-grad);

      @media (max-width: 1450px),
      (max-height: 760px) {
        width: 2.725rem;
        height: 2.725rem;
      }

      span {
        width: 100%;
        height: 100%;
        background: var(--text-dark);
        @include flexBox(inline-flex, null, center, center);
      }
    }
  }

  &__bottom-bar {
    padding: 0.225rem;
    border-radius: 8px;
    min-height: 2.4375rem;
    @include flexBox(flex, null, center, space-between);
    gap: 1rem;

    &>div {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.5rem;

      .icon {
        width: 1.9375rem;
        height: 2.015rem;
        @include flexBox(inline-flex, null, center, center);
        background: var(--text-dark);
        border-radius: 7px;

        img {
          width: 34%;
        }
      }
    }

    .left {
      .icon {
        img {
          width: 34%;
        }
      }
    }

    .right {
      min-width: min(40%, 8.5rem);
    }
  }
}

.cf-pair {
  @include flexBox(flex, null, center, space-between);

  .value {
    @include flexBox(inline-flex, null, center, flex-end);
    gap: 0.5rem;

    .tagHOB {
      padding: 0.125rem 0.275rem;
      border-radius: 0.3275rem;
      letter-spacing: -0.02625rem;
      background: var(--holo-grad);
    }

    .tagLocation {
      color: #EFECEC;
      padding: 0.25rem 0.5rem;
      border-radius: 0.3275rem;
      background: var(--text-dark);
    }
  }

  &.marker-coords {
    @include flexBox(inline-flex, column nowrap, flex-start, center);
    gap: 0.5rem;
  }
}

.product-screen-s3 {
  .scroll-div-right {
    .detail-cards {
      margin-inline: 0;
      padding-inline: 0.5rem;
    }

    @media (max-width: 1450px),
    (max-height: 760px) {
      max-height: calc(100vh - 13.95rem);
    }
  }
}

.cf-datatable {
  margin-top: 1rem;

  .holograf-table {
    tr {

      th,
      td {
        &:nth-child(2) {
          width: 24%;
        }
      }
    }
  }

  .listing-cards {
    .top-row {
      gap: min(8vw, 5rem);
    }
  }
}

.ownership-flow {
  border-radius: 0.375rem;
  height: 1.61725rem;
  @include flexBox(inline-flex, null, center, null);
  gap: 0.1rem;
  background: var(--holo-grad);



  .left {
    border-radius: 0.375rem;
    @include flexBox(inline-flex, null, center, center);
    background: var(--text-dark);
    padding: 0 0.5rem;
    height: 100%;
    color: var(--neutral-light);
    pointer-events: none;
  }

  .right {
    @include flexBox(inline-flex, null, center, null);
    height: 100%;
    padding: 1px;
    max-width: 1.58219rem;

    .hide-show {
      width: 0;
      flex: 0;
      opacity: 0;
    }

    .arrow {
      @include flexBox(inline-flex, null, center, center);
      border-radius: 0.375rem;
      background: var(--text-dark);
      height: 95%;
      width: 1.38219rem;
      flex: 0 0 1.38219rem;

      img {
        transform: rotate(180deg);
        margin-top: 3px;
      }
    }
  }

  &[aria-expanded="true"] {
    .right {
      max-width: 100%;

      .hide-show {
        width: 4.5rem;
        flex: 4.5rem;
        opacity: 1;
        padding-top: 1.2px;
        padding-inline: 0.15rem 0.8rem;
      }

      .arrow {
        img {
          transform: rotate(0deg);
          margin-top: 0;
        }
      }
    }
  }
}

.collapse-td-cards {
  border-top: 2px solid white;
}

.text-red {
  color: #FF6647;
}

.text-green {
  color: #55A55E;
}
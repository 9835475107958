.Toastify__toast-container--top-center{
    width: auto;
}

.Toastify__toast{
    margin-bottom: 0px;
    padding: 0px;
    border-radius: 0.625rem;
}

.Toastify__toast-body{
    padding: 0px;
}
.Toastify__close-button {
display: none;
}
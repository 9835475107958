/* whyUsSec */
.whyUsSec{
    padding-block: calc(50px * var(--resp)) calc(50px * var(--resp));
    overflow: hidden;
    min-height: calc(100vh - (90px * var(--resp)));
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__head{
        max-width: 65%;
    }

    &__img{
        width: 100%;
        max-width: 100%;
        margin-top: calc(-175px * var(--resp));
        height: auto;
        position: relative;
        transform-origin: left top;
        transform: scale(1.1);
        margin-bottom: 100px;

        @media (min-width: 2200px) {
            transform: scale(1.45);
            margin-bottom: 425px;
        }
        @media (max-width: 1800px) {
            transform: scale(0.85);
        }
        @media (max-width: 1400px) {
            transform: scale(0.75) translate3d(0, -50px, 0);
            margin-bottom: -250px;
        }
        @media (max-width: 1300px) {
            transform: scale(0.75) translate3d(0, -50px, 0);
            margin-bottom: -275px;
        }
    }

    &__layer0{
        position: relative;
        width: auto;
        max-width: none;
        height: auto;
    }
    &__layer1{
        position: absolute;
        width: auto;
        max-width: none;
        inset: 0;
        height: auto;
    }


    /* trackDotLayer CSS */
    @keyframes whyDotsMovingAnim {
        from {
            offset-distance: 0%;
        }
        to {
            offset-distance: 100%;
        }
    }
    
    .whyDotsMoving{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .whyDotsMoving div{
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--holo-grad);
        offset-distance: 0%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        border:1px solid #222;
    }
    .whyDotsMoving.active div{
        opacity: 1;
        animation: whyDotsMovingAnim 3.5s linear forwards infinite;
    }
    .whydotmove1{
        offset-path: path('M322.5 582.5H242.5');
    }
    .whydotmove2{
        offset-path: path('M550.5 583V544.5H387.5V457');
    }
    .whydotmove3{
        offset-path: path('M322 582V873H242.5');
    }
    .whydotmove4{
        offset-path: path('M626.5 581.5V750M580.5 750V789.5');
    }
    .whydotmove5{
        offset-path: path('M694 582.5V634.5H783.5V728.5');
    }
    .whydotmove6{
        offset-path: path('M751 582.5V443.5H799');
    }
    .whydotmove7{
        offset-path: path('M980 582.5H1134.5');
    }
    .whydotmove8{
        offset-path: path('M982 583V653H1252');
    }


    &__desc{
        &-row{
            display: flex;
            align-items: top;
            margin-bottom: 30px;
            gap: 10px;
        }
        &-col{
            flex: 1;
        }
        &-single{
            display: flex;
            align-items: center;

            img{
                flex: 0;
                width: 55px;
                min-width: 55px;
            }
            div{
                flex: 1;
                padding-left: 10px;
                font-weight: bold;
            }
        }
    }

    @media (max-width: 991.98px) {
        .whyUsSec__head{
            max-width: 100%;
        }
        
    }
}

@media (max-width: 991.98px) {
    .whyUsSec{
        min-height: auto;
        padding-bottom: 15px;
    }
    .whyUsSec__desc-single div {
        line-height: 1.3;
    }
}
.dataTables_scroll {
  //   border: 2px solid #fff;
  border-radius: 0.725rem;
}

table.dataTable {
  margin-block: 0 !important;
}

.dataTables_scrollBody {
  min-height: 28rem;
  max-height: calc(100vh - 27rem) !important;

  @media (max-width: 767.98px) {
    max-height: unset !important;
  }
}

.user-table {
  .dataTables_scrollBody {
    min-height: unset;
    overflow: inherit !important;
  }

  .holograf-table {
    @media (max-width: 567.98px) {
      thead>tr>th {
        &:first-child {
          span {
            padding-left: 0.6rem;
          }
        }

        &.sorting {
          padding-inline: 0;
        }
      }
    }

    td {
      @media (max-width: 567.98px) {
        font-size: 0.75rem;
      }

      .btn-fab--transparent {
        height: 2rem;
        width: 2rem;
        border-radius: 0.3rem;

        &:hover,
        &:focus,
        &:active {
          outline: 0;
          background: var(--neutral-light);
        }
      }
    }
  }
}

table.dataTable thead>tr>th.sorting {
  padding-inline: 0.25rem;
}

.holograf-table,
.holograf-table-2 {

  th,
  td {
    border: 0;
    border-radius: 0;
  }

  tbody {
    tr {
      &:first-child {
        // border-top: 2px solid #fff;
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }

      &:hover {
        box-shadow: var(--main-shadow);

        td {
          background: rgba($color: #fff, $alpha: 0.75) !important;
        }
      }
    }
  }

  th {
    border: 0;
    padding: 0;
    padding-inline: 5px;
    vertical-align: middle;
    background-color: white;
    height: 2.875rem;

    span {
      //padding: 15px;
      @include flexBox(flex, null, center, null);

      @media (max-width: 767.98px) {
        padding-inline: 7px;
        font-size: 12px;
      }

      &.new {
        color: #03952c;
        margin-left: 10px;
        background-color: #d2f2db;
        font-size: 12px;
        line-height: 1;
        padding: 5px;
        border-radius: 2px;
      }
    }

    &.sorting:not(:first-child) {

      &::before,
      &::after {
        display: none;
      }
    }

    &.sorting:first-child {
      padding: 0 !important;
      padding-right: 5px !important;
    }



    &:first-child {
      border-radius: 0.625rem 0 0 0.625rem;

      @media (max-width: 767.98px) {
        min-width: unset;

        .checkbox-wrapper {
          margin-top: -4px;
        }
      }

      span {
        padding-left: 2rem;

        @media (max-width: 767.98px) {
          padding-left: 0.88rem;
        }

        @media (max-width: 1450px) {
          padding-left: 1rem;
        }
      }
    }

    &:last-child {
      border-radius: 0 0.625rem 0.625rem 0;
      white-space: nowrap;
    }
  }

  td {
    @include ts(0.3s);
    font-size: 12px;
    padding: 10px 5px;
    vertical-align: middle;
    border-top: 2px solid #fff;
    background: var(--translucentBg) !important;

    @media (max-width: 767.98px) {
      font-size: 11px;
      min-width: 3.85rem;
      padding-inline: 7px;
    }

    @media (max-width: 567.98px) {
      font-size: 10px;
    }

    &:first-child {
      padding-left: 2.25rem;

      @media (max-width: 1450px) {
        padding-left: 1rem;
      }

      @media (max-width: 767.98px) {
        min-width: unset;
        padding-left: 0.6rem;
      }
    }
  }
}

.holograf-table-2 {
  th {
    span {
      padding-inline: 0;
    }
  }

  tbody {
    tr {
      &:hover {
        box-shadow: none;

        td {
          background: transparent !important;
        }
      }
    }
  }

  .holograf-table {
    th {
      background-color: #222;
      color: #fff;
      border-radius: 0 !important;
    }

    tbody {
      tr {
        &:hover {
          box-shadow: var(--main-shadow);

          td {
            background: rgba($color: #fff, $alpha: 0.75) !important;
          }
        }
      }
    }
  }
}

.dataTables_wrapper {
  .bottom {
    padding: 0 1rem;
    margin-top: 1.125rem;
    @include flexBox(flex, row nowrap, center, space-between);
  }

  .dt-buttons,
  .dataTables_filter {
    display: none;
  }

  .dataTables {
    &_info {
      padding-top: 0 !important;
      font-weight: 400;
      font-size: 14px;
    }

    &_length {
      label {
        font-size: 14px;
        font-weight: 700;
        line-height: 15px;
      }

      select {
        min-width: 4.5rem;
        max-width: 5rem;
        height: 42px;
        padding-inline: 0.8rem 1.25rem;
        margin: 0 0.25rem;
        border-radius: 50px;
        background: white;
        border: 1px solid #cccccc;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        // background-image: url(../images/icons/Chevron-down.svg);
        background-repeat: no-repeat;
        background-position: 90%;
        background-size: 16px;

        &:focus {
          outline: 0;
          border: 1px solid var(--text-dark);
          box-shadow: 0px 10px 25px rgba(255, 221, 221, 0.25);
        }
      }
    }

    &_paginate {
      @include flexBox(inline-flex, row nowrap, center, center);
      gap: 5px;

      &>span {
        @include flexBox(inline-flex, row nowrap, center, center);
        gap: 5px;
      }

      .paginate_button {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: white;
        font-size: 13px;
        color: var(--text-dark);
        border: 1px solid #fff;
        @include flexBox(inline-flex, null, center, center);
        @include ts(0.3s);
        @include fw(500);

        &:hover {
          border-color: var(--text-dark);
          background-color: var(--neutral-light);
        }

        &.current {
          color: white;
          border-color: var(--text-dark);
          background-color: var(--primary-dark);
        }
      }

      .first {
        background: url(../images/icons/First.svg) no-repeat 45%;
      }

      .previous {
        background: url(../images/icons/Prev.svg) no-repeat 35%;
      }

      .next {
        background: url(../images/icons/Next.svg) no-repeat center;
      }

      .last {
        background: url(../images/icons/Last.svg) no-repeat center;
      }

      .first,
      .previous,
      .next,
      .last {
        background-size: 1.25rem;
        background-color: white;
        @include ts(0.3s);

        &.disabled {
          opacity: 0.4;
          background-color: var(--white);
          //   pointer-events: none;
          cursor: not-allowed;
          border-color: transparent;
        }
      }
    }
  }
}

.custom-data-table {
  position: relative;
  z-index: 2;

  &.block-widget {
    padding: 0;
  }

  .top-row {
    padding: 0.75rem 0.69rem 0.75rem 1.35rem;
    @include flexBox(flex, null, center, space-between);
    gap: min(2vw, 2rem);

    @media (max-width: 1450px) {
      padding-left: 1rem;
    }

    @media (max-width: 767.98px) {
      padding-left: 0.75rem;
    }

    h2 {
      letter-spacing: -0.02625rem;
    }
  }

  .form {
    &-group {
      position: relative;

      .search-icon {
        left: 0;
        top: 0;
      }

      img {
        max-width: 60%;
        @include ts(0.3s);
      }

      .icon-wrap {
        @media (max-width: 991.98px) {
          width: 2.8rem;
          height: 2.8rem;
        }
      }
    }

    &-control {
      @include form-control;
      @include ts(0.3s);
      padding-left: 4rem;
      min-width: 22rem;

      @media (max-width: 991.98px) {
        height: 2.8rem;
        padding-left: 3.4rem;
      }

      &:focus {
        background: white;

        &~img {
          transform: scale(1.08);
        }
      }

      &::placeholder {
        font-weight: 300;
        font-size: 14px;
      }
    }
  }

  .brnd_btn_black {
    @include flexBox(inline-flex, null, center, center);
  }

  .bottom {
    display: none;
  }
}

.id_cell {
  &--activated {
    cursor: pointer;
    padding-top: 2px;
    font-size: 12px;
    font-weight: 600;
    color: var(--text-dark);
    @include flexBox(inline-flex, column nowrap, flex-end, center);

    &:hover,
    &:focus,
    &:active {
      color: var(--primary-dark);
    }
  }
}

.owner {
  @include flexBox(inline-flex, null, center, center);
  gap: 0.5rem;

  &__btn.btn-fab {
    width: 1.625rem;
    height: 1.625rem;
    border-radius: 0.325rem;

    img {
      max-width: 60%;
    }
  }

  &__tooltip {
    --bs-tooltip-bg: white;
    --bs-tooltip-font-size: 0.875rem;
    --bs-tooltip-color: var(--text-dark);
    filter: drop-shadow(3px 5px 12px #C0C0C0);
  }
}

.btn-arrow {
  padding: 0;
  background: transparent;
}

.prod_desc {
  max-width: 15rem;
  @include multipleLinesClamp(3);

  @media (max-width: 1600px) {
    max-width: 13.5rem;
    background: #ebfdf5;
  }
}

.td-tag-list {
  max-width: 15rem;
  gap: 0.25rem;
  @include flexBox(flex, row wrap, center, null);
  min-width: 100%;

  @media (max-width: 1600px) {
    max-width: 13.5rem;
  }

  .td-tag {
    color: #efecec;
    height: 1.625rem;
    border-radius: 0.45rem;
    padding-inline: 0.44rem;
    background: var(--text-dark);
    min-width: 49px;

    &.td-tag-light {
      background: #eaeaea;
      color: var(--text-dark);
    }

    @include flexBox(inline-flex, null, center, center);
    white-space: nowrap;

    @media (max-width: 767.98px) {
      border-radius: 0.375rem;
      height: 1.5rem;
    }
  }

  @media (max-width: 767.98px) {
    &.m-tag-list {
      gap: 0;

      .td-tag {
        padding: 0;
        height: 1rem;
        background: none;
        color: var(--text-dark);
      }
    }
  }
}

.table-pair {
  @include flexBox(inline-flex, null, center, null);
  gap: 0.5rem;

  .icon-box {
    width: 2.1875rem;
    height: 2.3125rem;
    @include flexBox(inline-flex, null, center, center);
    flex-shrink: 0;

    img {
      width: 40%;
    }
  }

  .text {
    @include flexBox(inline-flex, column nowrap, null, null);
    gap: 0.35rem;
  }
}

.btn-top-group {
  @include flexBox(inline-flex, null, center, flex-end);
  gap: 0.75rem;

  @media (max-width: 767.98px) {
    gap: 0.4rem;
  }

  .btn-create-css {
    height: 3.25rem;
    border-radius: 0.625rem;
    padding-inline: 0.69rem;

    @media (max-width: 1450px),
    (max-height: 760px) {
      border-radius: 0.475rem;
      height: 3rem;
    }
  }
}

.td-status {
  min-width: 3.0625rem;
  height: 1.5rem;
  border-radius: 0.4375rem;
  @include flexBox(inline-flex, null, center, center);
  position: relative;

  @media (max-width: 1600px) {
    min-width: 2.95rem;
    height: 1.35rem;
  }

  &::after {
    content: "";
    position: absolute;
    inset: -2px;
    border-radius: 0.4375rem;
    background: var(--holo-grad-radial);
    z-index: -1;
  }

  span {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 0.3125rem;
    border: 1px solid var(--text-dark, #222);
    @include flexBox(inline-flex, null, center, center);

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 0.3125rem;
      z-index: -1;
      background: var(--holo-grad-radial);
    }
  }

  &--active {
    span {}
  }

  &--draft {
    &::after {
      opacity: 0.45;
    }

    span {
      background-color: rgba($color: #fff, $alpha: 0.35);

      &::after {
        opacity: 0.1;
      }
    }
  }
}

.td-incentive {
  border-radius: 6px;
  background-color: #eaeaea;
  box-shadow: 3px 5px 12px 0px #C0C0C0;
  padding: 5px 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;

  &--bonus {
    background: #EFCFE4;
  }

  &--rewards {
    background: #D2F2DB;
  }

  &--discount {
    background: #D0E1EE;
  }
}

.td-warranty {
  position: relative;
  display: inline-flex;
  gap: 5px;

  &::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #eaeaea;
  }

  &--in {
    color: #55A55E;

    &::before {
      background-color: #55A55E;
    }
  }

  &--out {
    color: #FF6647;

    &::before {
      background-color: #FF6647;
    }
  }

  &--replaced {
    color: #3678AB;

    &::before {
      background-color: #3678AB;
    }
  }

  &--returned {
    color: #B94BB6;

    &::before {
      background-color: #B94BB6;
    }
  }
}

.n-status {
  border-radius: 0.25rem;
  min-width: 5.0625rem;
  height: 2.125rem;
  border: 1px solid var(--text-dark);
  @include flexBox(inline-flex, null, center, center);

  &--pending {
    background-color: var(--text-dark);
  }

  &--responded {
    background-color: transparent;
  }
}

.td-action {
  @include flexBox(inline-flex, null, center, center);
  gap: 0.45rem;
  text-align: center;

  &>button {
    @include flexBox(inline-flex, null, center, center);
    border-radius: 0.4375rem;
    min-width: 6.0625rem;
    height: 2.125rem;
    flex-shrink: 0;
    @include ts(0.3s);

    span {
      @include ts(0.3s);
      letter-spacing: -0.02625rem;
    }
  }

  &--accept {
    border: 1px solid var(--text-dark);
    background: var(--text-dark);

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      background: var(--primary-dark);
      border: 1px solid var(--text-dark);

      span {
        transform: scale(1.05);
      }
    }
  }

  &--reject {
    background: transparent;
    border: 1px solid var(--text-dark);

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      background: transparent;
      box-shadow: var(--main-shadow);
      border: 1px solid var(--text-dark);

      span {
        transform: scale(1.05);
      }
    }
  }

  &--accepted {
    color: #55a55e;
    background: #d2f2db;
    pointer-events: none;
  }

  &--rejected {
    color: #c92200;
    background: #efcfe4;
    pointer-events: none;
  }
}

.select-all {
  margin-left: -0.125rem;
}

.btn-fab {
  &::after {
    display: none;
  }
}

.dropdown-menu-holograf {
  .filter-list {
    @include flexBox(flex, column nowrap, null, null);
  }

  .table-pair {
    color: var(--text-dark);
    padding: 0.8rem 1.2rem 0.68rem;
    @include flexBox(flex, null, flex-start, null);
    gap: 0.5rem;

    img {
      margin-top: 1px;
    }

    .text {
      gap: 0.12rem;
    }
  }
}

.content-layout {
  .btn-fab {
    &.btn-fab--incomplete {
      min-width: 10.65rem;
      height: 2.4375rem;
      padding-inline: 0.6rem;
      position: relative;

      @media (max-width: 1600px) {
        padding-inline: 0.125rem;
        min-width: 9.6rem;
        border-radius: 0.425rem;
      }

      @media (max-width: 1450px) {
        height: 2.2rem;
        border-radius: 0.325rem;
      }

      @media (max-width: 767.98px) {
        height: 1.6rem;
        min-width: 8.5rem;
        border-radius: 0.2rem;
        font-size: 10px;
      }

      img {
        position: absolute;
        right: 0.7rem;
        bottom: 0.35rem;
        transform-origin: 0 50%;

        @media (max-width: 1600px) {
          right: 0.6rem;
        }

        @media (max-width: 767.98px) {
          bottom: 0rem;
          right: 0.45rem;
          max-width: 14%;
        }
      }

      &:hover,
      &:focus,
      &:active {
        img {
          transform: scaleX(1.4);

          @media (max-width: 1600px) {
            transform: scaleX(1.165);
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {

  table.dataTable thead>tr>th.sorting::before,
  table.dataTable thead>tr>th.sorting::after {
    content: "";
  }
}
.logistics-screen-graphics {
    background: url(./../images/logistics-waveGradient.svg) no-repeat right top;
    background-color: black;
    border-radius: 0.625rem;
    height: 123px;
    gap: 12px;
    padding: 2rem 2rem;

    h3 {
        font-size: 18px;
        display: flex;
        align-items: center;
        gap: 10px;
    }

}

.Counterfeit-screen-graphics {
    background: url(./../images/counterfeit-darkWaves.svg) no-repeat right top;
    background-color: rgba(255, 255, 255, 0.38);
    border-radius: 0.625rem;
    gap: 12px;
    padding: 2rem 1.5rem;

    h3 {
        font-size: 18px;
    }
}

.sales-growth-group,
.sales-down-group {
    display: flex;
    align-items: center;
    margin-inline: auto;
    border-radius: 10px;
    height: 24px;
    gap: 5px;
    width: 10.5rem;

    .icon-wrap {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
    }
}

.sales-growth-group {
    background: #D2F2DB;

    .icon-wrap {
        background: #13771E;
    }
}


.sales-down-group {
    background: #FFEFFA;

    .icon-wrap {
        background: #FF4747;
    }
}

.btn-datatable {
    width: 136px;
    height: 52px;
}

.product-stats .stat--greenLine .line {
    background: #B1F1D5;
}



////////////////   MODAL SCSS     ///////////////
.modal.show .modal-dialog.dtableModal-wrapper {

    @media (max-width: 1450px),
    (max-height: 760px) {
        min-height: 90vh;
        max-height: calc(100% - (0.75rem * 2));
        transform: translate(0, 25px);
        margin: 0.75rem auto;
    }

}

.dtableModal-wrapper {
    width: 90%;
    max-width: 100%;

    @media (max-width: 1450px),
    (max-height: 760px) {
        width: 97%;
    }

    .hover.modal-data-table {
        th {
            color: white;
            background: var(--text-dark);
        }
    }

    .custom-data-table,
    .holograf-table-2 {

        .top-row,
        td {
            background: rgba($color: #EFEEEC, $alpha: 0.33);
        }
    }
}

.hob-tag {
    @include flexBox(flex, null, center, center);
}

.modal-content .full-width-wrapper::after {
    background: none;
    position: static;
}

.data-table-modal .modal-content {
    padding: 15px;
}

.custom-modal-table {
    border: 2px solid #D0E1EE;
    border-radius: 5px;
}

.modal-data-table {
    thead th {
        vertical-align: middle;
        background-color: #DADADA;
        border-radius: 0;
    }

    th {
        &:first-child {
            border-radius: 0;
        }

        &:last-child {
            border-radius: 0;
        }
    }
}

.status-cover {
    border: 1px solid var(--neutral-light);
    max-width: 13rem;
    background: #FFF;
    border-radius: 0.375rem;
    @include flexBox(flex, column nowrap, null, null);

    @media (max-width:1450px),
    (max-height: 760px) {
        max-width: 12rem;

    }

    .td-order-status {
        @include flexBox(flex, null, center, space-between);
        height: 1.61725rem;
        border-radius: 0.375rem;
        background: var(--holo-grad);
        position: relative;
        z-index: 1;

        .status-current {
            @include flexBox(flex, null, center, center);
            height: 100%;
            padding: 0 0.5rem;
            min-width: 5.625rem;
            border-radius: 0.375rem;
            color: var(--neutral-light);
            background-color: var(--text-dark);

            @media (max-width:1450px),
            (max-height: 760px) {
                min-width: 5rem;
                letter-spacing: 0.2px;
            }
        }

        .btn-details {
            padding: 0;
            height: 100%;
            @include flexBox(inline-flex, null, center, space-between);
            flex: 1;

            span {
                flex: 1;
            }

            b {
                height: 100%;
                width: 1.5rem;
                border-radius: 0.375rem;
                @include flexBox(inline-flex, null, center, center);
                background: var(--text-dark);

                img {
                    transform: rotateX(180deg);
                    margin-top: 2px;
                }
            }

            &:hover,
            &:focus,
            &:active {
                outline: 0;
                box-shadow: none;
            }

            &[aria-expanded="true"] {
                b {
                    img {
                        transform: rotateX(0deg);
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .status-list {
        @include flexBox(flex, row wrap, center, space-around);
        gap: 0.35rem;
        padding: 0.35rem 0.35rem;


        &__each {
            @include flexBox(inline-flex, null, center, center);
            min-width: 5.6875rem;
            height: 1.625rem;
            border-radius: 0.375rem;
            background: #55A55E;
            color: var(--neutral-light);
            padding: 0 0.4rem;

            @media (max-width:1450px),
            (max-height: 760px) {
                padding: 0 0.3rem;
                min-width: 5.4rem;
                letter-spacing: 0.2px;
            }

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
                color: white;
            }
        }
    }
}




//////  TOASTER   //////
.toaster-position {
    position: relative;
    top: 0;
    right: 0;
    max-width: 100%;

    .add-product-modal-content {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.toaster-success {
    flex-shrink: 0;
    width: 650px;

    h3 {
        gap: 1rem !important;
    }
}

.success-message {
    color: #55A55E;
}


.toaster-rejected .modal-header h3 {
    background: url(./../images/toaster-blackBG.svg) no-repeat right top;
    background-color: #EFCFE4;
    gap: 1rem;
}


.rejected-message {
    color: #E10C19 !important;
}

.alert-img {
    width: 39.392px;
    height: 34.047px;
}

.success-img {
    width: 36px;
    height: 36.022px;
}

.btn-cta--purple {
    border-radius: 10px;
    cursor: pointer;
}

.btn-cta--purple {
    min-width: 134px;

    .primaryText {
        background: #D0E1EE !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.625rem;
    }

    input {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

.dropdown-modal-form {
    position: relative;
    z-index: 2;
}

.holograf-form .modal-poup-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    // z-index: 3;
    gap: 0.25rem;

    .label {
        flex-basis: 0 !important;
    }

    .form-control {
        &::placeholder {
            font-size: clamp(0.775rem, 0.5rem + 0.325vw, 0.875rem);
        }
    }

    .inputSelect {
        width: 100%;

        &>input {
            // position: absolute;
            // left: 0;
            // bottom: 0;
            // z-index: 4;
            width: 100%;
            @include form-control;
            border: 2px solid #EFECEC;
            padding-right: 3.6rem;

            &:focus {
                outline: 0;
            }
        }

        .btn-fab {
            top: unset;
            bottom: 0;
            right: 0;

            &:focus,
            &:focus-within,
            &:focus-visible,
            &:active {
                border: 0;
                outline: 0;
                box-shadow: none;
            }
        }

        .dropdown-menu {
            transform: translate3d(0, 2px, 0px) !important;
        }
    }
}

.typr-pair:hover {
    text-decoration: none;
}

.discount-btn-wrapper {
    display: flex;
    gap: 7px;
}

.discount-btn {
    width: auto;
    height: 52px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-inline: 1rem;
    min-width: 4rem;
}

.discount-wrapper {
    background: #DADADA;
    display: flex;
    width: 100%;
    height: 52px;
    padding: 0.35rem;
    padding-left: 1.2rem;
    border: 2px;
    border-radius: 10px;
    box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.1294117647);

    .discount-placeholder {
        background: white;
        /* width: 267px; */
        height: 100%;
        border-radius: 8px;
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 1rem;
    }
}

.modal-footer-light {
    background: white;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    gap: 7px;
    padding: 15px;
    border-radius: 10px;

    a {
        height: 46px !important;
        width: 148px !important;
    }
}

.btn-modal-gradient>div {
    height: 45px;
}

.modal-btn-outlined {
    height: 45px !important;
    width: 148px !important;
}

.modal-divider {
    color: black;
    margin: 0;
}

.discount-wrapper-black {
    background-color: black;
}

.discount-gradient-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Styles */
.product-screen--style1{
  .select-field{
    margin-left: -10px;
  }
  .holograf-form .form-control:read-only{
    cursor: default;
  }
}


/* selectLevelWrapMob */
.selectLevelWrapMob{
  background-color: #222;
  border-radius: 8px;
  box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);

  &__head{
    padding: 20px 15px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px dashed #D0E1EE;
  }

  &__body{
    padding: 15px;
    color: #fff;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  &__level{
    position: relative;
    border: 1px solid #fff;
    border-radius: 10px;
    white-space: nowrap;
    width: 30%;
    min-width: 100px;
    max-width: 150px;
    height: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding-left: 44px;
    overflow: hidden;

    &::before{
      content: '+';
      display: flex;
      width: 44px;
      height: 44px;
      position: absolute;
      left: 0;
      top: 0;
      font-weight: 800;
      font-size: 25px;
      line-height: 1;
      color: #222;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 0 10px 10px 0;
    }


    &.locked::after{
      content: '';
      position: absolute;
      inset: 0px;
      border-radius: 10px;
      background: url('../images/lock-overlay.svg') no-repeat center center;
      background-size: cover;
      backdrop-filter: blur(2px);
    }
  }

  &__addLevel{
    .dropdown-toggle::after{
      display: none;
    }
    width: 30%;
    min-width: 100px;
    max-width: 150px;
    height: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    margin-left: 0!important;
    
    box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
    font-weight: 500;

    &-inner{
      background: var(--rdadai, radial-gradient(187.91% 214.86% at 84.72% 23.47%, #FFB6D5 0%, #FFE3C0 10.00%, #C7FBDB 29.00%, #C7CDF4 43.00%, #FFBCD4 54.00%, #FFE0C3 66.00%, #B0F1D4 76.00%, #C9CEEC 84.00%, #FFC1DA 91.00%, #BDFFE3 100%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border: 2px dashed #fff;
      border-radius: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      cursor: pointer;
    }
  }

  .dropdown-menu-style1{
    background-color: #222;
    color: #fff;
    max-width: 100%;
    min-width: 100%;
    border: 1px solid #fff;
    
    li{
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      &:last-child{
        border-bottom: 0;
      }
    }
    a{
      color: #fff;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-block: 10px;

      &:hover{
        background-color: #808080;
        color: #fff;
      }

      span{
        background: var(--pastel-pink);
        padding-top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: -15px;
        color: #222;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  
}






@media (max-width: 991.98px) {
  .product-screen--style1{
    
    .dotted-arrow{
      display: none;
    }

    .number-tag {
      top: calc(100% + 25px);
      transform: translateY(-50%);
      border-radius: 2.5px;
    }
    .user-card-dot-starting{
      top: calc(100% + 25px);
      transform: translateY(calc(-50% - 1px));
      left: 23px;
    }
    .horizontal-line{
      align-self: end;
      margin-bottom: -25px;
    }
    .map-form .left-col{
      padding-right: 0;
    }
    .dropdown{
      margin-left: 1.8rem;
    }
    .middle-arrow .verfied-div,
    .middle-arrow .error-div{
      right: -10px;
      top: -30px;
    }
    [data-bs-toggle="dropdown"]{
      font-style: italic;
    }

    .btn111{
      position: relative;
      
      .select-field{
        position: absolute;
        inset: 0;
        z-index: 1;
      }
    }

    .csv-fields{
      position: relative;
      cursor: pointer;
      padding-right: 43px;
    }
    .icon-plus{
      font-size: 50px;
      position: absolute;
      right: 3px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #000;
      width: 43px;
      height: 43px;
      background:url('../images/icons/icon-plus1.svg') no-repeat center center;
    }
    .label111{
      height: 46px;
      text-align: left;
      flex: 1;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      align-items: center;
      color: #222;
    }

    .block-widget{
      padding: 0;
    }

    .holograf-form > .row:last-child .number-tag{
      transform: translateY(-100%);
    }
    .holograf-form > .row:last-child .user-card-dot-starting{
      transform: translateY(calc(-100% - 4px));
    }
    
    
  }

  /* Profile settings */
  .profile-tabs > li{
    position: relative;
  }
  .profile-tabs > li .profile-tabs-content{
    background: url(./../images/settings-deco1.svg) no-repeat right top;
  }
  .profile-tabs > li .profile-tabs-content .profile-tab-arrow {
    position: absolute;
    left: auto;
    right: 0px;
    height: 5px;
    bottom: 20px;
  }
  .profile-tabs > li .profile-tabs-content {
    padding: 25px 15px;
    height: auto;
  }
  .profile-tabs > li .profile-tabs-content p{
    width: calc(100% - 50px);
    font-size: 10px;
  }
  .profile-tabs > li .profile-tabs-content h3{
    font-size: 12px;
  }
  .profile-tabs .btn.btn-fab.btn-fab--dark{
    position: absolute;
    bottom: -15px;
    margin-bottom: 0;
    padding-bottom: 0;
    right: 10px!important;
  }

  .profile-wrapper .profile-tabs .border-white-2.mob-style1{
    border-left: 0;
    border-right: 0;
    border-top: 0;
  }

  .profile-wrapper .row>* {
    margin-top: 15px;
  }
  .profile-tabs > li .profile-tabs-content{
    background: linear-gradient(36deg, #fdf1f6 0%, #f8f2ea 10.00%, #eefcf3 29.00%, #f4f5fd 43.00%, #fdeef4 54.00%, #fdf6ee 66.00%, #eefcf6 76.00%, #f3f4fa 84.00%, #faebf1 91.00%, #eaf8f3 100%);
   
  }
  .profile-tabs--s1{
    border: none;
  }
  .profile-wrapper.mob-style1{
    border: 0;
    background: none;
  }
  .product-screen--style1 .holograf-form-1{
    overflow: hidden;
  }

  .product-screen--style2{
    flex-direction: column-reverse;
    gap: 0;
  }

  .activity-topbar.activity-topbar-style1{
    background-image: url(./../images/settings-deco2.svg);
    background-position: right bottom;
  }

  .profile-block-content-style1{
    width: 100%;
    max-width: 100%;
    background: var(--text-dark);
    max-height: auto;
    min-height: auto;

    .profile-right-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: start;
      text-align: left;
      gap: 10px;

      .user-name{
        text-align: left;
        justify-content: start;
        display: block;
        font-weight: 600;

        .grayText {
          font-size: 14px;
        }
      }
    }

    .user-img-upload{
      margin-inline: 0;
    }

    .dark-block-content .user-name h3{
      font-size: 16px!important;
    }
    .profile-right-wrapper{
      position: relative;
    }
    .profile-right-wrapper .user-img-upload .user-dp {
      width: 80px;
      height: 80px;
      padding: 5px;
    }
    .profile-right-wrapper .user-img-upload .user-dp__inner img {
      border-width: 5px;
    }
    .profile-right-wrapper .user-img-upload {
      height: 75px;
      border-bottom: 2px dashed #cecece;
    }
    .profile-right-wrapper .user-img-upload{
      position: static;
    }
    .profile-right-wrapper .user-img-upload .btn{
      right: -30px!important;
      left: auto;
      top: -10px;
    }
    .dark-block-content h3{
      font-size: 12px;
    }
    .dark-block-content p{
      font-size: 10px;
    }
    .profile-info{

    }
    .dark-block-content{
      padding-inline: 20px;
      padding-top: 0;
    }
    .profile-right-wrapper{
      padding-top: 30px;
      padding-bottom: 30px;
      &::after{
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0;
        border-bottom: 1px dashed #D0E1EE;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
      }
    }
  }
}


.px-mob-3{
  @media (max-width:991.98px) {
    padding-right: 1rem!important;
    padding-left: 1rem!important;
  }
}



/* Tracking pages */
.tracking__left.product-screen__form{
  flex: 1;
  max-width: none;
}
.tracking__nav{
  display: none;
}

@media (max-width: 991.98px) {
  .map-div .top-bar-bg{
    position: relative;
    margin: 0;
  }
  .map-div .map-wrapper{
    padding-top: 0;
  }
  .tracking__nav{
    display: flex;
    gap: 10px;
    
    > a{
      flex: 1;
      background-color: #000;
    }
    &__link1{

    }
  }
  .paye-layout-1{
    .product-screen.product-screen-s2{
      .product-show-hide{
        order: 1;
      }
      .product-screen__form{
        order: 3;
      }
      .trac-right-sec{
        order: 2;
        padding: 0px!important;
      }
    }
    .tracker-screen-s2{
      flex-direction: column-reverse;
  
      > div{
        width:100%
      }
    }
    .trac-right-sec{
      max-height: none;
    }
    .scroll-div-right{
      overflow: visible;
      max-height: none;
    }
    .tracker-product-details, .tracking-status{
      overflow: visible;
      max-height: none;
    }
    .tracker-product-details{
      padding: 10px 10px 10px 20px;
    }
    .map-div{
      min-height: auto;
    }
    .cards{
     padding: 0px; 
    }
    .tracker-info{
      padding-left: 0;
    }
    .tracker-info-wrapper{
      padding-left: 20px;
    }
    .tracker-user-card-wrapper::before {
      left: -20px;
      width: 20px;
    }
    .tracker-info-wrapper::before{
      left: -6px;
    }
    .tracker-info-wrapper::after{
      left: -4px;
    }
    .tracker-info-wrapper-dot{
      left: -6px;
    }
    .block-widget {
      padding: 0px!important;
      background: transparent;
      border: none;
    }
    .user-card-dot {
      left: -4.5px;
    }
    .info-card {
      background: rgba(255, 255, 255, 1);
    }
    .page-header__user-info.round_pf_pic .user-img{
      width: 34px;
      height: 34px;
    }
    .page-header__user-info .user-img span{
      padding: 1px;
    }
    .page-header__user-info.round_pf_pic {
      padding-top: 14px;
    }
    .fz16{
      font-size: 12px;
    }
    .fw700.fz16, 
    .fw600.fz16{
      font-size: 14px;
    }
    .top-bar-bg{
      background-image: url(../images/tracking-deco1.svg);
      background-position: right bottom;

      .fz24{
        font-size: 16px;
      }
      img{
        height: 16px;
      }
      .arrow-img{
        display: none;
      }
    }
    .map-div .map-img{
      border-radius: 0 0 10px 10px;
    }
  }
  

  


  
}


/* Map section */
.map-div .top-bar-bg{
  position: relative;
}
.map-div .map-wrapper{
  padding-top: 0;
}
.map-div .map-img{
  border-radius: 0 0 10px 10px;
}



/* .trac-right-sec */
.trac-right-sec{
  min-width: 30%;
}
.scroll-div-right{
  display: block;
}
.detail-cards {
  padding: 0px 0.85rem 0.85rem;
}


/* cs1 */
.cs1{

}

.tracking-downline > li::after {
  width: 50%;
  right: -53%;
  transform: translateY(-50%);
}

/* CS1 Tabs */
.cs1-tabs{
  display: none;
  gap: 7.5px;
  margin-bottom: 10px;
  
  @media (max-width: 991.98px) {
    display: flex;
  }
  
    .cs1-tab{
      flex: 1;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 85px;
      border-radius: 10px;
      box-shadow: 3px 4px 4px 0px rgba(151, 134, 154, 0.13);
      position: relative;
      border: 2px solid #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      color: #222;
      gap: 3px;
      flex-flow: wrap;
      text-align: center;
      padding: 0 20px;
      position: relative;
      z-index: 1;

      &::before{
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(36deg, #fdeff5 0%, #fcf6ef 10.00%, #f2fcf5 29.00%, #f4f5fd 43.00%, #fdf1f5 54.00%, #fff8f1 66.00%, #f4fffa 76.00%, #eff1fd 84.00%, #fcf0f5 91.00%, #edfdf6 100%);
        border-radius: 10px;
      }
      
      > *{
        position: relative;
        z-index: 1;
      }

      &-activetext{
        display: none;
      }
      &-inactivetext{
        display: active;
      }

      &.active{
        height: 100px;
        margin-bottom: -13px;
        border-radius: 10px 10px 0 0;
        border-bottom: 0;
        box-shadow: none;

        &::before{
          bottom: -2px!important;
          border-radius: 10px 10px 0 0!important;
        }

        .cs1-tab-activetext{
          display: inline;
        }
        .cs1-tab-inactivetext{
          display: none;
        }
      }

      &-arrow{
        position: absolute;
        right: 7.5px;
        top: 35px;
        transform: rotate(180deg);
      }
    }

    .cs1-tab-details{
      &::after{
        content: '';
        position: absolute;
        inset: -2px;
        border-radius: 10px;
        background-image: url('../images/cs1-tab-deco3.svg');
        background-repeat: no-repeat;
        background-position: bottom left;
      }


      .cs1-tab-arrow{

      }

      &.active{
        &::after{
          background-image: url('../images/cs1-tab-deco2.svg');
          background-repeat: no-repeat;
          background-position: top left;
        }

        .cs1-tab-arrow{
          transform: rotate(0);
        }
      }
    }

    .cs1-tab-ownership{
      background-color: #222;
      color: #fff;
      border: 2px solid transparent;

      &::before{
        display: none;
      }
      &::after{
        content: '';
        position: absolute;
        inset: -2px;
        border-radius: 10px;
        background-image: url('../images/cs1-tab-deco4.svg');
        background-repeat: no-repeat;
        background-position: top left;
      }

      .cs1-tab-arrow{
        filter: invert(1);
      }

      &.active{
        background-color: #fff;
        color: #222;
        border: 2px solid #fff;
        
        &::before{
          display: block;
        }
        &::after{
          content: '';
          position: absolute;
          inset: -2px;
          border-radius: 10px;
          background-image: url('../images/cs1-tab-deco1.svg');
          background-repeat: no-repeat;
          background-position: top left;
        }

        .cs1-tab-arrow{
          transform: rotate(0);
          filter: invert(0);
        }
      }
    }
}

@media (max-width: 991.98px) {
  .cs1{
    background: linear-gradient(36deg, #fdeff5 0%, #fcf6ef 10.00%, #f2fcf5 29.00%, #f4f5fd 43.00%, #fdf1f5 54.00%, #fff8f1 66.00%, #f4fffa 76.00%, #eff1fd 84.00%, #fcf0f5 91.00%, #edfdf6 100%);
  }

  .cs1-product-screen{
    gap: 0;
  }

  .cs1{
    display: none;
    margin-bottom: 15px;
   
    

    .card_wrapper-1, .card_wrapper-2, .card_wrapper-3 {
      background-size: 90px auto;
    }

    &.active{
      display: block;
      margin-top: 0;
    }
    
    .detail-cards .card__text .text-pair {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .label{
        min-width: 50%;
      }
    }

    .detail-cards .card__text {
      height: 155px;
    }

    .location_btn.dwnld_qr_btn {
      margin-top: -35px;
    }

    &-title{
      font-size: 16px;
    }
  }
  // .cs1-details{
  //   display: none;
  
  //   &.active{
  //     display: block;
  //   }
  // }
  .cs1-details.active{
    display: block;
  }
  .cs1-textarea{
    padding: 10px 10px 0 !important;
  }

  .cs1-tab-details{

  }
  .cs1-tab-ownership{

  }

  .cs1-tab-details.active{
    
    .tracking-btn-arrow{
      transform: rotate(270deg);
    }
  }
  .cs1-tab-ownership.active{
    .tracking-btn-arrow{
      transform: rotate(270deg);
    }
  }
}


/* cs2 */
.cs2-card-top .trusted_text{
  position: static;
}
@media (max-width: 991.98px) {
  .cs2-card-top{
    margin-left: 40px;
  }
 
  .cs2-user-img{
    margin-right: -40px;
  }
}


/* cs3 */
.cs3{
  ul.preview-tags .p-tag{
    height: 32px;
  }

  
}
@media (max-width: 991.98px) {
  .cs3{
    padding: 0;
    border: 0;
    .tracker-wrapper{
      background:none;
    }

    .tracker-product-details{
      margin-top: 0;
      margin-bottom: 20px;
    }
    .user-img{
      width:53px!important;
      height: 53px!important;
    }
    ul.preview-tags {
      flex-flow: row nowrap;
      overflow-x: auto;
      gap: 25px;
    }
    ul.preview-tags > li{
      width: 1200px;
    }
    ul.preview-tags .p-tag{
      padding-inline: 5px;

    }
    .page-header__user-info.round_pf_pic{
      padding-top: 0;
      align-self: start;
    }
    .tracking-date-tag span{
      font-size: 10px;
    }

    .product-stats li{
      width: 30%;
      text-align: center;
    }

    .tracker-user-card-wrapper{
      background: rgba(255, 255, 255, 1);
    }

    .row > div > .agreement-wrapper{
      margin-bottom: 15px;
    }
    .row > div:last-child > .agreement-wrapper{
      margin-bottom: 0px;
    }

    .tagBefore {
      left: -45px;
      top: calc(50% - 0px);
      height: 22px;
      width: 22px;

      .fz14{
        font-size: 10px;
      }
    }
  }

  .cs3-border-bottom{
    position: relative;

    &::after{
      position: absolute;
      bottom: 0;
      left: -10px;
      right: -10px;
      content: '';
      border-bottom: 1px dashed #D0E1EE;
    }
    
  }
  .cs3-separator{
    position: relative;
    padding-bottom: 10px;
    &::after{
      position: absolute;
      top: 0;
      left: -10px;
      right: -10px;
      content: '';
      border-bottom: 1px dashed #D0E1EE;
    }
  }

  .tracker-user-card-wrapper{
    border: 1px solid #eaeaea;
  }
}


/* cs4 */
.cs4{
  
}

@media (max-width: 991.98px) {
  .cs4{
    > .col-5{
      padding-block: 0;
    }

    

    .tracker-wrapper{
      background:none;
      border:0;

      .tracker-bg {
        border-radius: 10px;
        overflow: hidden;
      }
    }
    .fz24{
      font-size: 16px;
    }
    .gradientText--radial img {
      height: 16px;
    }
    .product-stats .stat .num {
      font-size: 24px;
    }
  }
}


/* cs4 */
.cs5{
 
}

@media (max-width: 991.98px) {
  .cs5{
    width: 100%;
    .detail-cards{
      padding: 0 0 30px!important;
    }

    .detail-cards .card__text .text-pair:has(.brnd_btn_black) {
      display: flex;
    }

    .list-unstyled.detail-cards{
      margin-top: 0;
    }

  }
  .cs5-top-left{

  }
  .cs5-top-right{
    max-width: 100px;
  }
  .cs5-detail-cards{
    .card__text{
      width: 100%;

      .text-pair{
        flex-direction: row;

        .label{
          width: 40%;
        }
      }
    }
  }
  
}


/* cs6 */
.cs6{
  display: none;
  position: relative;
  border-radius: 10px;
  background-color: #222;
  color: #fff;
  padding: 10px;
  background-image: url('../images/c6-deco1.svg');
  background-repeat: no-repeat;
  background-position: right bottom;


  &--ml{
    margin-left: -10px;
  }

  &__title{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    background: var(--holo-grad-radial);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: calc(100% - 80px);

    img{
      display: inline-block;
      align-items: bottom;
      margin-top: -5px;
    }
  }
  &__desc{
    font-size: 10px;
    width: calc(100% - 80px);
  }

  @media (max-width: 991px) {
    display: block;
  }
}


/* detail-cards */
.detail-cards{
  &-s2{

  }
  &--links{
    max-height: 400px;
    overflow-y: auto;

    li{
      cursor: pointer;
      position: relative;
      background-color: #fff;
      border-radius: 0.625rem;

      &::after{
        position: absolute;
        content: '';
        width: 41px;
        height: 8px;
        background:url('../images/arrow-right.svg');
        top: 20px;
        right: 20px;
      }
    }
  }

  &--warranty{
    max-height: 400px;
    overflow-y: auto;

    li{
      position: relative;
      background-color: #fff;
      border-radius: 0.625rem;

      &::after{
        position: absolute;
        content: '';
        width: 7px;
        height: 12px;
        background:url('../images/six-dots.svg');
        top: 20px;
        right: 20px;
      }
    }
  }
}

/* Warranty chart wrapper */

.chart-container-warranty{
  

  @media (min-width: 1200px) {
    display: flex;
    
    .chart-container{
      width: 70%;
      min-width: 70%;
    }

    .product-stats{
      flex-direction: column;
      width: 30%;
      min-width: 30%;
      align-items: start;
      padding-block: 20px;
      padding-left: 50px;
    }
  }
}
